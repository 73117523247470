import qs from 'qs';
import HOSTS from 'tradera/constants/hosts';
import initData from 'tradera/legacy/static/packages/init-data';
import { formatNextPathnameInfo } from 'next/dist/shared/lib/router/utils/format-next-pathname-info';
import { getNextPathnameInfo } from 'next/dist/shared/lib/router/utils/get-next-pathname-info';

import { logger } from 'packages/logger';
import {
    URL_LANGUAGES,
    DEFAULT_LANGUAGE,
    SUPPORTED_LANGUAGES,
    SUPPORT_SITE_LANGUAGE_CODE_MAP,
    SUPPORT_SITE_DEFAULT_LANGUAGE
} from 'tradera/lang/constants.mjs';
import { removePropertiesWithoutValue } from 'tradera/utils/object';

const RELATIVE_DOMAIN = 'https://example.com';

// List of SPA paths for the regex match, without initial slash
const SPA_REGEX_PATHS = [
    'search',
    'campaign',
    'charity',
    'categories',
    'profile\\/items',
    'brand\\/.+',
    // Category
    '[a-z]+[\\w\\-]+[\\-|_]\\d+'
];
const SPA_PATH_REGEX = new RegExp(
    `^(/(${URL_LANGUAGES.join('|')}))?/(${SPA_REGEX_PATHS.join('|')})`
);

/**
 * Removes priceRange from query params if fromPrice or toPrice is present.
 */
const prunePriceRange = (queryParams: Record<string, unknown>) => {
    if (queryParams.fromPrice || queryParams.toPrice) {
        const { priceRange, ...result } = queryParams;
        return result;
    }
    return queryParams;
};

type FormatQueryParamsQueryParams = Record<string, unknown> & {
    category?: string;
    categoryId?: string;
};
/**
 * Format query params parsed from query string
 */
export const formatQueryParams = (
    queryParams: FormatQueryParamsQueryParams
) => {
    const {
        category: queryCategory,
        categoryId: queryCategoryId,
        ...rest
    } = removePropertiesWithoutValue(queryParams);
    const formattedQueryParams = prunePriceRange(rest);
    const categoryId = parseInt(queryCategory || queryCategoryId || '0', 10);
    if (categoryId > 0) {
        formattedQueryParams.categoryId = categoryId;
    }
    return formattedQueryParams;
};

/**
 * Check if url is absolute
 */
export const isAbsoluteUrl = (url: string): boolean =>
    new RegExp('^(?:[a-z]+:)?//', 'i').test(url);

/**
 * Tests if a given url or path is an SPA link or not
 */
export function isSpaLink(urlOrPath: string): boolean {
    let pathname;
    if (/(^http)s?:\/\//i.test(urlOrPath)) {
        try {
            pathname = new URL(urlOrPath).pathname;
        } catch {
            return false;
        }
    } else {
        pathname = urlOrPath;
    }
    return SPA_PATH_REGEX.test(pathname);
}

/**
 * Takes an object and returns a new object without route parameters
 */
export const stripRouteParametersFromQueryParameters = (
    queryParams: Record<string, unknown>,
    params: Record<string, unknown> | undefined
) => {
    if (!params) return queryParams;

    const excludeKeys = new Set(Object.keys(params));

    const filteredPairs = Object.entries(queryParams).filter(
        ([key]) => !excludeKeys.has(key)
    );
    return Object.fromEntries(filteredPairs);
};

/**
 * Takes an object and returns a new object without pagination properties
 */
export function stripCategoryIdFromQueryParameters<
    T extends Record<string, unknown>
>(queryParams: T): Omit<T, 'categoryId'> {
    // Using object spread to exclude listed variables from 'rest'
    const { categoryId, ...rest } = queryParams;
    return rest;
}

/**
 * Removes tradera base from a url, leaving path and query
 */
export const stripHost = (url: string): string =>
    typeof url === 'string' && url.startsWith('http')
        ? url.replace(HOSTS.TRADERA, '')
        : url;

/**
 * Takes an object and returns a new object without itm properties
 * Itm tags should no longer be used, so this method will then be obsolete
 */
export function stripItmFromQueryParameters<T extends Record<string, unknown>>(
    queryParams: T
): Omit<T, 'itm_source' | 'itm_medium'> {
    // Using object spread to exclude listed variables from 'rest'
    const { itm_source, itm_medium, ...rest } = queryParams;
    return rest;
}

/**
 * Takes an object and returns a new object without pagination properties
 */
export function stripPaginationFromQueryParameters<
    T extends Record<string, unknown>
>(queryParams: T): Omit<T, 'spage' | 'paging' | 'page' | 'fe'> {
    // Using object spread to exclude listed variables from 'rest'
    // The parameters "spage", "page" and possible "fe" are deprecated and should no longer be used.
    // However, it may still be useful to remove them from the query parameters here,
    // in case they are used in old links.
    const { spage, paging, page, fe, ...rest } = queryParams;
    return rest;
}

/**
 * Takes an object and returns a new object without savedQueryName properties
 */
export function stripSavedQueryNameFromQueryParameters<
    T extends Record<string, unknown>
>(queryParams: T): Omit<T, 'savedQueryName'> {
    // Using object spread to exclude listed variables from 'rest'
    const { savedQueryName, ...rest } = queryParams;
    return rest;
}

/**
 * Takes an object and returns a new object without saveSearch properties
 */
export function stripSaveSearchNameFromQueryParameters<
    T extends Record<string, unknown>
>(queryParams: T): Omit<T, 'saveSearch'> {
    // Using object spread to exclude listed variables from 'rest'
    const { saveSearch, ...rest } = queryParams;
    return rest;
}

/**
 * Takes an url and adds tradera base url if it is not already an absolute url
 */
export const toAbsoluteUrl = (relativeUrl: string): string =>
    relativeUrl.startsWith('http')
        ? relativeUrl
        : `${HOSTS.TRADERA}${
              relativeUrl.startsWith('/') ? relativeUrl : '/' + relativeUrl
          }`;

// Help TS understand that the parameter is a string
const isString = (str: unknown): str is string => {
    if (str === null || str === undefined || str === '' || str === false)
        return false;

    return true;
};

/**
 * From URL to URL with language prefix
 */
export function toLocalizedUrl<T>(
    url: T,
    languageCodeIso2?: string
): T | string {
    if (!isString(url)) {
        return url;
    }

    const ignoredUrls = [initData.webApiUrl, '/api/webapi/'];
    const isIgnoredUrl = ignoredUrls.find(
        (domain) => url.indexOf(domain) !== -1
    );
    if (isIgnoredUrl) {
        return url;
    }

    if (!languageCodeIso2) {
        logger(`Missing language code for URL ${url}`);
        return url;
    }

    if (url.startsWith('//')) {
        throw new Error(
            `Attempt to localize protocol relative url: ${url}. This is not supported at the moment`
        );
    }

    const urlObject = new URL(url, RELATIVE_DOMAIN);

    if (url.startsWith('/support/')) {
        return toLocalizedSupportSiteUrl(urlObject, languageCodeIso2);
    }

    // eslint-disable-next-line better-mutation/no-mutation
    urlObject.pathname = setLanguagePrefix(
        urlObject.pathname,
        languageCodeIso2
    );
    return urlObject.toString().replace(RELATIVE_DOMAIN, '');
}

const toLocalizedSupportSiteUrl = (
    urlObject: URL,
    languageCodeIso2: string
) => {
    const languagePart = urlObject.pathname.split('/')[2];
    const isAlreadyLocalized = Object.values(
        SUPPORT_SITE_LANGUAGE_CODE_MAP
    ).includes(languagePart || '');

    if (isAlreadyLocalized) {
        return urlObject.toString().replace(RELATIVE_DOMAIN, '');
    }

    const supportLanguageCode =
        SUPPORT_SITE_LANGUAGE_CODE_MAP[
            languageCodeIso2 as keyof typeof SUPPORT_SITE_LANGUAGE_CODE_MAP
        ] || SUPPORT_SITE_DEFAULT_LANGUAGE;

    // eslint-disable-next-line better-mutation/no-mutation
    urlObject.pathname = urlObject.pathname.replace(
        '/support/',
        `/support/${supportLanguageCode}/`
    );

    return urlObject.toString().replace(RELATIVE_DOMAIN, '');
};

/**
 * Creates query parameters object from search string
 */
export const toQueryParameters = (search: string) =>
    qs.parse(search, { ignoreQueryPrefix: true });

type ToSearchStringOptions = {
    addQueryPrefix?: boolean;
    encode?: boolean;
    skipNulls?: boolean;
    arrayFormat?: 'indices' | 'brackets' | 'repeat' | 'comma';
};

/**
 * Creates search string from all properties in object
 */
export const toSearchString = (
    queryParams: object,
    {
        addQueryPrefix = true,
        encode = true,
        skipNulls = true,
        arrayFormat = 'repeat' //qs.stringify({ a: ['b', 'c'] }, { arrayFormat: 'repeat' }) ==> 'a=b&a=c'
    }: ToSearchStringOptions = {}
): string =>
    qs.stringify(queryParams, {
        addQueryPrefix: addQueryPrefix,
        encode: encode,
        skipNulls: skipNulls,
        arrayFormat: arrayFormat
    });

const setLanguagePrefix = (path: string, languageCodeIso2: string) => {
    // Inspired by `next/src/server/web/next-url.ts`
    const nextConfig = {
        i18n: {
            defaultLocale: DEFAULT_LANGUAGE,
            locales: SUPPORTED_LANGUAGES
        }
    };
    const pathnameInfo = getNextPathnameInfo(path, {
        nextConfig,
        parseData: true
    });
    const localizedPathname = formatNextPathnameInfo({
        ...pathnameInfo,
        defaultLocale: DEFAULT_LANGUAGE,
        locale: languageCodeIso2
    });
    return localizedPathname;
};

export const getBackToHereRedirectUrl = () => {
    // Add random query parameter to force reload when redirecting to this url
    // and only the hash differs
    const queryParams = {
        ...qs.parse(location.search, { ignoreQueryPrefix: true }),
        forceRedirect: Math.round(Math.random() * 100000)
    };
    return (
        location.protocol +
        '//' +
        location.host +
        location.pathname +
        qs.stringify(queryParams, { addQueryPrefix: true })
    );
};

/**
 * Converts an API request to URLSearchParams for use in a GET request
 */
export const convertApiRequestToURLParams = (
    apiRequest: Record<string, unknown>
): URLSearchParams => {
    return Object.entries(apiRequest).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined) {
            if (Array.isArray(value)) {
                value.forEach((item) => acc.append(key, item.toString()));
            } else {
                acc.append(key, value.toString());
            }
        }
        return acc;
    }, new URLSearchParams());
};

export const getHeroImageUrl = (
    heroImageUrl: string,
    version: 'small' | 'medium' | 'large' = 'medium'
) => {
    if (heroImageUrl) {
        return `https://img.tradera.net/heroimages/${version}-square/${heroImageUrl}`;
    }
    return undefined;
};

/**
 * Checks if the current page is a direct navigation
 *
 * If the current page's referrer contains tradera.com, then it's not a direct navigation, the user has been on the site before.
 *
 * @returns true if it is a direct navigation, false otherwise
 */
export const isDirectNavigation = () => {
    if (!document.referrer) return true; // Direct navigation or referrer blocked
    try {
        const referrerUrl = new URL(document.referrer);
        return !referrerUrl.hostname.endsWith('.tradera.com');
    } catch (e) {
        return true; // Invalid referrer URL
    }
};
