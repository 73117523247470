import { selectShippingRegionCountryCodeIso2 } from 'tradera/state/shipping-region/selectors';
import { areCountryCodesIso2Equal } from 'tradera/localization/countrycode';
import type { RootState } from '../configure-store';
import { RequirementTypeApi } from '../services/types/webapi-member-generated';

export const selectMember = (state: RootState) => state.member;

export const selectIsLoggedIn = (state: RootState) => state.member?.isLoggedIn;

export const selectGeolocation = (state: RootState) =>
    state.member?.geolocation;

export const selectMemberId = (state: RootState) => state?.member?.memberId;

export const selectMemberAlias = (state: RootState) =>
    state.member?.memberAlias;

export const selectMemberEmail = (state: RootState) =>
    state.member?.memberEmail;

export const selectMemberFirstName = (state: RootState) =>
    state.member?.memberFirstName;

export const selectMemberLastName = (state: RootState) =>
    state.member?.memberLastName;

export const selectMemberBirthdate = (state: RootState) =>
    state.member?.memberBirthdate;

export const selectIsMemberFemale = (state: RootState) =>
    state.member?.gender === 'female';

export const selectCurrencyCode = (state: RootState) =>
    state.member?.currencyCode;

// Prefer using selectShippingRegionCountryCodeIso2 to be safe for all users, non-logged in and logged in.
export const selectMemberCountryCodeIso2 = (state: RootState) =>
    state.member?.memberCountryCodeIso2;

export const selectIsOutsideSweden = (state: RootState) =>
    state.member?.geolocation?.isoCode &&
    state.member?.geolocation?.isoCode.toLowerCase() !== 'se';

export const showDanishFromCountry = (state: RootState) => {
    const fromCountry = selectShippingRegionCountryCodeIso2(state);
    return areCountryCodesIso2Equal(fromCountry, 'DK');
};

export const selectMemberTown = (state: RootState) => state.member?.memberTown;

export const selectMemberPermissions = (state: RootState) =>
    state.member.permissions;

export const selectIsUnderage = (state: RootState) => {
    const permissions = selectMemberPermissions(state);
    return (
        permissions?.sellPermission?.isPermitted === false &&
        permissions?.sellPermission?.requirementsNotMet?.includes(
            RequirementTypeApi.IsOverMinimumAge
        )
    );
};

export const selectIsCompany = (state: RootState) => state.member?.isCompany;

export const selectIsImpersonationSession = (state: RootState) =>
    state.member?.isImpersonationSession;

export const selectMemberState = (state: RootState) =>
    state.member?.memberState;

export const selectIsTrustedMember = (state: RootState) =>
    state.member?.isTrustedMember;

export const selectPreviousLoginMethod = (state: RootState) =>
    state.member?.previousLoginMethod;
