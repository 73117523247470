import axios, {
    type AxiosError,
    type AxiosInstance,
    type AxiosResponse,
    type CancelTokenSource
} from 'axios';

import { logger } from 'packages/logger';
import { HTTP_STATUS_CODES } from 'tradera/constants/http-status-codes';
import {
    refreshTokensOnce,
    UnauthorizedError
} from 'tradera/utils/http/api-renew-tokens';
import { isServer } from 'tradera/utils/nextjs';

/**
 * Standardized api error codes
 */
export const API_ERRORS = {
    IGNORE_ME: 'IGNORE_ME',
    ABORTED: 'ABORTED',
    CANCELLED: 'CANCELLED',
    NETWORK: 'NETWORK',
    TIMEOUT: 'TIMEOUT',
    VERSION_MISMATCH: 'VERSION_MISMATCH',
    LOGGED_OUT: 'LOGGED_OUT'
};

export const defaultJsonRequestHeaders = {
    // Force json response
    Accept: 'application/json',
    // For WebAPI and RequiresAuthorization to handle ajax request
    'X-Requested-With': 'XMLHttpRequest'
};

/**
 * Standard Axios configs
 */
export const axiosConfigs = {
    // Requests to endpoints with authentication
    authenticated: {
        // Send auth cookies
        withCredentials: true,
        headers: defaultJsonRequestHeaders
    },
    notAuthenticated: {
        // Dont send auth cookies
        withCredentials: false,
        headers: defaultJsonRequestHeaders
    }
};

const isMissingAuthToken = (responseString: string) => {
    try {
        const responseObject = JSON.parse(responseString);
        const isMissing =
            responseObject.responseStatus.errorCode === 'MissingAuthToken';
        return isMissing;
    } catch {
        return false;
    }
};

export const addAntiCacheParam = (url?: string) => {
    const antiCacheRegExp = /([?&]_=)[^&]*/;
    return antiCacheRegExp.test(url || '')
        ? url?.replace(antiCacheRegExp, '$1' + new Date().getTime())
        : url +
              (/\?/.test(url || '') ? '&' : '?') +
              '_=' +
              new Date().getTime();
};

const submitOriginalRequest = async (error: AxiosError) =>
    axios({
        ...error.config,
        url: addAntiCacheParam(error.config?.url)
    });

const handleErrorAfterRefresh =
    (error: AxiosError) => async (errorAfterRefreshToken: AxiosError) => {
        if (errorAfterRefreshToken instanceof UnauthorizedError) {
            // eslint-disable-next-line better-mutation/no-mutation
            error.message = API_ERRORS.LOGGED_OUT;
        }
        return Promise.reject(error);
    };

const handleMissingAuthToken = async (error: AxiosError) => {
    return refreshTokensOnce()
        .then(() => submitOriginalRequest(error))
        .catch(handleErrorAfterRefresh(error));
};

const handleUnauthorized = async (error: AxiosError) => {
    // Refresh access token, then member token if refresh token exists.
    try {
        await refreshTokensOnce();
        return submitOriginalRequest(error);
    } catch (errorAfterRefreshToken) {
        if (errorAfterRefreshToken instanceof UnauthorizedError) {
            // eslint-disable-next-line better-mutation/no-mutation
            error.message = API_ERRORS.LOGGED_OUT;
        }
        return Promise.reject(error);
    }
};

export const errorResponseInterceptor = (error: AxiosError) => {
    // While error.response.data might be parsed JSON, error.request?.response is a string
    if (isMissingAuthToken(error.request?.response)) {
        return handleMissingAuthToken(error);
        // Yes, status does exist in the request prototype as well
    } else if (error.request?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
        return handleUnauthorized(error);
    }
    return Promise.reject(error);
};

/**
 * Create Axios instance or decorate existing instance with interceptor for expired token responses
 *
 */
export const axiosWithTokenRefresh = (axiosInstance?: AxiosInstance) => {
    let instance;
    if (axiosInstance === undefined) {
        instance = axios.create();
    } else {
        instance = axiosInstance;
    }
    instance.interceptors.response.use(
        (response) => response,
        errorResponseInterceptor
    );
    return instance;
};

const cancelTokens: Record<string, CancelTokenSource> = {};

/**
 * Create new or return existing cancellation function
 *
 * Usage:
 * const { cancel, cancelToken } = utilizeCancelToken('tokenId')
 * if (cancel) cancel();
 * axios.get('http://api.com/...', { cancelToken });
 *
 * @param {string} tokenId - A string ID shared between requests that cancel together
 */
export const utilizeCancelToken = (tokenId: string) => {
    if (isServer) {
        throw new Error(
            'This implementation is not compatible with SSR as global properties like cancelTokens here are shared between server instances'
        );
    }
    let cancel;
    if (tokenId in cancelTokens) {
        cancel = cancelTokens[tokenId].cancel;
    }
    // eslint-disable-next-line better-mutation/no-mutation
    cancelTokens[tokenId] = axios.CancelToken.source();
    return {
        cancel,
        cancelToken: cancelTokens[tokenId].token
    };
};

/**
 * Check response version and throw error if different from current
 *
 */
export const checkResponseVersion = (version = '12.0.0') => {
    return <TResponse>(response: AxiosResponse<TResponse>) => {
        if (
            response.headers &&
            response.headers['X-Tradera-Version'] &&
            response.headers['X-Tradera-Version'] !== version
        ) {
            throw new Error(API_ERRORS.VERSION_MISMATCH);
        } else {
            return response;
        }
    };
};

/**
 * Checks redirect response for logged out user
 * This is an old solution. New solutions return a 401 response instead (see handleError).
 * Requires Axios config to include { withCredentials: true }
 *
 */
export const checkResponseLoggedOut = () => {
    return (response: AxiosResponse) => {
        if (
            response.data &&
            typeof response.data === 'string' &&
            response.data.includes('Logga in')
        ) {
            throw new Error(API_ERRORS.LOGGED_OUT);
        } else {
            return response;
        }
    };
};

/**
 * Final standardized formatting of an api response
 *
 */
export const finalizeResponse = () => {
    return <TResponse>(response: AxiosResponse<TResponse>) => {
        const { data, status } = response;
        return {
            data,
            status
        };
    };
};

type StatusHandlers = Record<number, string>;

/**
 * Common network request error handling
 */
export const handleError = (statusHandlers: StatusHandlers = {}) => {
    return (error: AxiosError) => {
        const status = error.response && error.response.status;
        const message = error.message ? error.message : error.toString();

        let errorMessage = error.message;

        if (axios.isCancel(error)) {
            errorMessage = API_ERRORS.CANCELLED;
        } else if (message.includes('timeout') || status === 408) {
            errorMessage = API_ERRORS.TIMEOUT;
        } else if (message.includes('Network')) {
            errorMessage = API_ERRORS.NETWORK;
        } else if (message.includes('Request aborted')) {
            errorMessage = API_ERRORS.ABORTED;
        } else if (status === 401) {
            errorMessage = API_ERRORS.LOGGED_OUT;
        } else if (status && status in statusHandlers) {
            errorMessage = statusHandlers[status];
        }

        /* eslint-disable better-mutation/no-mutation */
        error.message = errorMessage;

        throw error;
    };
};

export const reloadOnUnauthorized = (error: AxiosError) => {
    switch (error.message) {
        case API_ERRORS.LOGGED_OUT:
            window.location.reload();
            break;
        default:
            throw error;
    }
};

export const isClientNetworkError = (error: AxiosError) => {
    switch (error.message) {
        case API_ERRORS.IGNORE_ME:
        case API_ERRORS.CANCELLED:
        case API_ERRORS.ABORTED:
        case API_ERRORS.NETWORK:
        case API_ERRORS.TIMEOUT:
            return true;
        default:
            return false;
    }
};

export const logError = (error: AxiosError, scope?: object) => {
    if (!isClientNetworkError(error)) {
        logger(error, scope);
    }
};
