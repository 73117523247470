import React, { useRef } from 'react';
import { toLocalizedUrl } from 'tradera/utils/url';
import {
    NS_TOUCHWEB,
    I18N_FRONTEND_NAMESPACES,
    TRANS_SUFFIX
} from './constants.mjs';
import { isServer } from 'tradera/utils/nextjs';
import { useAppSelector as useSelector } from 'tradera/state/hooks';

// eslint-disable-next-line no-restricted-imports
import {
    type TFunction,
    type TranslationProps,
    useTranslation,
    Trans as TransComponent,
    I18nextProvider as NextI18nextProvider
} from 'react-i18next';
import type { i18n } from 'i18next';
import type { RootState } from 'tradera/state/configure-store';
import { selectPreferredLanguageCode } from 'tradera/state/language/selectors';
import type { AddTranslation } from 'tradera/lang/translations-context';
import { useAddTranslation } from 'tradera/lang/translations-context';
import { useQuery } from 'tradera/hooks/use-query';

export type TranslationFunction = TFunction;

export const I18nextProvider = NextI18nextProvider;

const createWrappedT =
    (
        i18nObject: i18n,
        t: TranslationFunction,
        addTranslation: AddTranslation,
        language: string,
        hasLoadedAllTranslations: boolean,
        throwOnTransSuffix?: boolean,
        isShowingTranslationKeysEnabled?: boolean
    ) =>
    (
        i18nKey: string,
        options?: TranslationProps | string,
        ...args: object[]
    ) => {
        if (
            typeof i18nKey === 'string' &&
            i18nKey.endsWith(TRANS_SUFFIX) &&
            throwOnTransSuffix
        ) {
            throw new Error(
                `t function keys should not have "Trans" suffix, got key "${i18nKey}". Use Trans component instead.`
            );
        }
        const translation = t(
            i18nKey,
            // @ts-expect-error: TS2698 because I have no idea how these types from/using i18n work
            { ...options, lng: language, hasLoadedAllTranslations },
            ...args
        );
        if (isServer) {
            addTranslationForHydration(
                i18nKey,
                options,
                language,
                i18nObject,
                addTranslation
            );
        }
        return isShowingTranslationKeysEnabled ? i18nKey : translation;
    };

const isQueryToggleEnabled = (query: string | null) => {
    if (query === null) return false;
    return ['1', 'true', ''].includes(query);
};

/**
 * i18next translation wrappers for translating static text in react components
 * When to use what?
 * -----
 * 1. For functional components use `useTranslator()` hook
 * 2. For class components use `withTranslator()` HOC
 */

/**
 * Translation hook, wrapper for react-i18next
 */
export const useTranslator = () => {
    const addTranslation = useAddTranslation();
    const [isShowingTranslationKeysEnabledQuery] = useQuery('translation-keys');
    const isShowingTranslationKeysEnabled = isQueryToggleEnabled(
        isShowingTranslationKeysEnabledQuery
    );
    const language = useSelector(selectPreferredLanguageCode);
    const loadedLanguages =
        useSelector((state: RootState) => state.status?.loadedLanguages) || [];
    const hasLoadedAllTranslations = loadedLanguages.includes(language);
    const { t, ready, i18n } = useTranslation(I18N_FRONTEND_NAMESPACES);
    const wrappedT: TranslationFunction = React.useMemo(
        () =>
            createWrappedT(
                i18n,
                t,
                addTranslation,
                language,
                hasLoadedAllTranslations,
                true,
                isShowingTranslationKeysEnabled
            ),
        [
            hasLoadedAllTranslations,
            i18n,
            t,
            addTranslation,
            language,
            isShowingTranslationKeysEnabled
        ]
    );
    return { t: ready ? wrappedT : () => '...', i18n };
};

const addTranslationForHydration = (
    i18nKey: string,
    options = {},
    language: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    i18n: any,
    addTranslation: AddTranslation
) => {
    const { key, namespaces } = i18n.translator.extractFromKey(
        i18nKey,
        options
    );
    const namespace = namespaces[namespaces.length - 1];
    const { res, exactUsedKey } = i18n.translator.resolve(key, {
        ...options,
        lng: language,
        ns: namespace
    });
    const pluralizedKey = exactUsedKey || i18nKey;

    addTranslation(namespace, pluralizedKey, res);
};

export type WithTranslatorProps = ReturnType<typeof useTranslator>['t'];

/**
 * Translation HOC, wrapper for react-i18next
 */
export function withTranslator<
    TComponent extends WithTranslatorProps = WithTranslatorProps
>(
    WrappedComponent: React.ComponentType<TComponent>
): React.ComponentType<TComponent> {
    function WithTranslator(
        props: Omit<TComponent, keyof WithTranslatorProps>
    ) {
        const { t } = useTranslator();
        return <WrappedComponent t={t} {...props} />;
    }

    WithTranslator.displayName = `withTranslator(${
        WrappedComponent.displayName || WrappedComponent.name
    }`;
    return WithTranslator;
}

export type WithUrlLocalizerProps = ReturnType<
    typeof useUrlLocalizer
>['toLocalizedUrl'];

/**
 * Localization HOC, wrapper for URL-localizer
 * @param {Component} WrappedComponent
 */
export function withUrlLocalizer<
    TComponent extends WithUrlLocalizerProps = WithUrlLocalizerProps
>(WrappedComponent: React.ComponentType<TComponent>) {
    function WithUrlLocalizer(
        props: Omit<TComponent, keyof WithUrlLocalizerProps>
    ) {
        const { toLocalizedUrl } = useUrlLocalizer();
        return <WrappedComponent toLocalizedUrl={toLocalizedUrl} {...props} />;
    }

    WithUrlLocalizer.displayName = `withUrlLocalizer(${
        WrappedComponent.displayName || WrappedComponent.name
    }`;

    return WithUrlLocalizer;
}

/**
 * Current language hook, wrapper for react-i18next
 */
export const useCurrentLanguage = () => {
    const preferredLanguage = useSelector(selectPreferredLanguageCode);
    // Using language from Redux instead of i18n.language since that
    // will always be 'sv' if we don't clone the instance.
    return {
        language: preferredLanguage
    };
};

/**
 * Returns function to append language prefix to link
 */
export const useUrlLocalizer = () => {
    const preferredLanguage = useSelector(selectPreferredLanguageCode);
    const urlLocalizer = useRef(function toLocalizedUrlWrapper<T>(
        url: T
    ): T | string {
        return toLocalizedUrl(url, preferredLanguage);
    });
    return {
        toLocalizedUrl: urlLocalizer.current
    };
};

export type TransProps = Omit<
    React.ComponentProps<typeof TransComponent>,
    'i18nKey'
> & {
    i18nKey: string;
};

export const Trans = (props: TransProps) => {
    const addTranslation = useAddTranslation();
    const language = useSelector(selectPreferredLanguageCode);
    const [isShowingTranslationKeysEnabledQuery] = useQuery('translation-keys');
    const isShowingTranslationKeysEnabled = isQueryToggleEnabled(
        isShowingTranslationKeysEnabledQuery
    );
    const loadedLanguages =
        useSelector((state: RootState) => state.status?.loadedLanguages) || [];
    const hasLoadedAllTranslations = loadedLanguages.includes(language);
    const { t, ready, i18n } = useTranslation(I18N_FRONTEND_NAMESPACES);
    const { i18nKey, children, defaults } = props;
    const wrappedT = React.useMemo(
        () =>
            createWrappedT(
                i18n,
                t,
                addTranslation,
                language,
                hasLoadedAllTranslations,
                false,
                isShowingTranslationKeysEnabled
            ),
        [
            hasLoadedAllTranslations,
            i18n,
            t,
            addTranslation,
            language,
            isShowingTranslationKeysEnabled
        ]
    );

    // TODO: since we don't have TS everywhere we still need to check these things, when we have TS we can use TS to enforce most of it instead instead

    if (!i18nKey) {
        throw new Error('Trans component key must have a i18nKey property');
    }
    if (!i18nKey.endsWith(TRANS_SUFFIX)) {
        throw new Error(
            `Trans component key must have the "Trans" suffix, got key "${i18nKey}"`
        );
    }
    if (children) {
        throw new Error(
            `Trans component must not have children, use components or t('${i18nKey}') function instead`
        );
    }
    if (defaults) {
        throw new Error(
            'Trans components must not have defaults, use lokalise instead.'
        );
    }
    if (!ready) {
        return <>...</>;
    }
    return <TransComponent ns={NS_TOUCHWEB} t={wrappedT} {...props} />;
};
