import type { RootState } from '../configure-store';
import { defaultCurrency } from './reducer';

export const selectCurrencies = (state: RootState) =>
    state.multiCurrency.currencies;

export const selectPreferredCurrency = (state: RootState) =>
    state.multiCurrency.preferredCurrency ||
    state.multiCurrency.currencies.find((c) => c.code === 'SEK') ||
    defaultCurrency;

export const selectIsPreferredCurrencySEK = (state: RootState) =>
    selectPreferredCurrency(state)?.code === 'SEK';

export const selectShowCurrencySelection = (state: RootState) =>
    !!(
        state.multiCurrency.enabled &&
        state.multiCurrency.preferredCurrency &&
        state.multiCurrency.currencies &&
        state.multiCurrency.currencies.length > 0
    );

export const selectCurrencyWithCode = (code: string) => (state: RootState) =>
    code
        ? state.multiCurrency.currencies.find((c) => c.code === code)
        : undefined;
