import { HTTP_STATUS_CODES } from 'tradera/constants/http-status-codes';
import type {
    WebClientTokenApiResponse,
    WebMemberTokenApiResponse
} from 'tradera/state/services/types/webapi-auth-generated';
import { isServer } from 'tradera/utils/nextjs';
type TokenResponse = WebClientTokenApiResponse | WebMemberTokenApiResponse;

type TokenResponsePromise = Promise<TokenResponse>;

const throwIfServer = () => {
    if (isServer) {
        throw new Error(
            'There are module scope variables in this module and is for client side use only!!!'
        );
    }
};

export class HttpError extends Error {
    public status: number;
    public statusText: string;

    constructor(response: Response, message: string) {
        super(`${response.status} ${response.statusText} - ${message}`);
        this.name = 'HttpError';
        this.status = response.status;
        this.statusText = response.statusText;
    }
}

export class UnauthorizedError extends HttpError {
    constructor(response: Response, message: string) {
        super(response, message);
        this.name = 'HttpError';
    }
}

const isUnauthorized = (statusCode: number) =>
    statusCode === HTTP_STATUS_CODES.UNAUTHORIZED;

const refreshAccessToken = async (): Promise<WebClientTokenApiResponse> => {
    const response = await fetch('/api/webapi/auth/web/client/token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (isUnauthorized(response.status)) {
        throw new UnauthorizedError(response, 'Failed to refresh access token');
    } else if (!response.ok) {
        throw new HttpError(response, 'Failed to refresh access token');
    }

    try {
        return response.json();
    } catch (error) {
        throw new HttpError(
            response,
            'Failed to parse JSON response when refreshing access token'
        );
    }
};

const renewMemberToken = async (): Promise<WebMemberTokenApiResponse> => {
    const response = await fetch('/api/webapi/auth/web/member/token/renew', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (isUnauthorized(response.status)) {
        throw new UnauthorizedError(response, 'Failed to refresh member token');
    } else if (!response.ok) {
        throw new HttpError(response, 'Failed to refresh member token');
    }

    try {
        return response.json();
    } catch (error) {
        throw new HttpError(
            response,
            'Failed to parse JSON response when refreshing member token'
        );
    }
};

let pendingTokensRefreshPromise: TokenResponsePromise | null = null;

const refreshTokens = async () => {
    const publicAccessTokenResponse = await refreshAccessToken();

    if (publicAccessTokenResponse.hasRefreshToken) {
        const memberTokenResponse = await renewMemberToken();
        return memberTokenResponse;
    }

    return publicAccessTokenResponse;
};

export const refreshTokensOnce = (): TokenResponsePromise => {
    throwIfServer();
    if (!pendingTokensRefreshPromise) {
        // eslint-disable-next-line better-mutation/no-mutation
        pendingTokensRefreshPromise = refreshTokens();
    }
    const promise = pendingTokensRefreshPromise;
    promise
        .finally(() => {
            // eslint-disable-next-line better-mutation/no-mutation
            pendingTokensRefreshPromise = null;
        })
        .catch(() => {
            // The catching of this promise should be done in the caller of this function
            // This is just to avoid unhandled promise rejection errors
        });
    return promise;
};
