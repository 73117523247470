import ALink from 'tradera/components/alink/alink';
import { useTranslator, useUrlLocalizer } from 'tradera/lang/translate';
import styles from './item-toast.module.scss';
import { useLocalizedPriceFormatter } from 'tradera/localization/use-localized-price-formatter';
import { CurrencyRateType } from 'tradera/state/multi-currency/reducer';

export const ItemToast = ({
    imageUrl,
    itemUrl,
    shortDescription,
    type,
    eventData
}) => {
    const { toLocalizedUrl } = useUrlLocalizer();

    return (
        <ALink useLink={true} href={itemUrl} className={styles.itemToast}>
            <div className="d-flex">
                <img
                    className={styles.itemToastImage}
                    src={imageUrl}
                    title={shortDescription}
                    alt={shortDescription}
                />
                <ToastMessage
                    type={type}
                    shortDescription={shortDescription}
                    itemUrl={toLocalizedUrl(itemUrl)}
                    eventData={eventData}
                />
            </div>
        </ALink>
    );
};

const ToastMessageType = {
    ItemOutbidded: 'ItemOutbidded',
    ItemFirstBid: 'ItemFirstBid',
    ItemSold: 'ItemSold',
    ItemWon: 'ItemWon',
    ItemPaid: 'ItemPaid',
    ItemWishListReminder: 'ItemWishListReminder'
};

const ToastMessagesWithBuyerRate = new Set([
    ToastMessageType.ItemOutbidded,
    ToastMessageType.ItemWon
]);

const ToastMessage = ({ type, shortDescription, eventData }) => {
    const { t } = useTranslator();
    const localizedPriceFormatter = useLocalizedPriceFormatter(
        ToastMessagesWithBuyerRate.has(type)
            ? CurrencyRateType.Buyer
            : CurrencyRateType.Seller
    );
    let message;
    switch (type) {
        case ToastMessageType.ItemOutbidded:
            message = t('siteWideNotifications_itemOutbidded_localized', {
                newLeadingBid: localizedPriceFormatter(
                    eventData.newLeadingBidAmount
                ),
                shortDescription
            });
            break;
        case ToastMessageType.ItemFirstBid:
            message = t('siteWideNotifications_itemFirstBid', {
                shortDescription
            });
            break;
        case ToastMessageType.ItemSold:
            message = t('siteWideNotifications_itemSold_localized', {
                price: localizedPriceFormatter(eventData.price),
                shortDescription
            });
            break;
        case ToastMessageType.ItemWon:
            message = t('siteWideNotifications_itemWon_localized', {
                price: localizedPriceFormatter(eventData.price),
                shortDescription
            });
            break;
        case ToastMessageType.ItemPaid:
            message = t('siteWideNotifications_itemPaid', {
                shortDescription
            });
            break;
        case ToastMessageType.ItemWishListReminder:
            message = t('siteWideNotifications_itemWishListReminder', {
                shortDescription,
                timeLeftMinutes: eventData.timeLeftMinutes
            });
    }
    return <div className="ml-1">{message}</div>;
};
