import type { InitData } from 'tradera/state/init-data/types';
import { bootLanguages } from 'tradera/lang/boot-languages';
import { GtmService as gtm } from 'tradera/services/google-tagmanager/google-tagmanager-service';
import { bootSiteWideNotifications } from 'tradera/services/boot-site-wide-notifications';
import { syncServerTime } from 'tradera/utils/server-date';
import { configureDayjs } from 'tradera/apps/tradera/configure-dayjs';

export const bootApp = ({
    languageCodeIso2,
    webLiveUrl,
    memberId
}: InitData) => {
    bootLanguages();
    gtm.loadGtmScript();
    syncServerTime();

    if (languageCodeIso2) {
        configureDayjs(languageCodeIso2);
    }

    if (webLiveUrl) {
        bootSiteWideNotifications({
            socketUrl: webLiveUrl,
            joinMemberRoom: !!memberId
        });
    }
};
