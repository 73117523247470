import { CurrencyRateType } from 'tradera/state/multi-currency/reducer';
import type { Currency } from 'tradera/state/multi-currency/reducer';
import { isNullOrUndefined } from 'tradera/utils/condition-check';

export const convertSekToCurrency = (
    price: number | null | undefined,
    currency: Currency,
    rateType: CurrencyRateType,
    rounding?: RoundingType
): number | null => {
    const rate = getRateFromRateType(currency, rateType);
    return convertPrice(price, rate, currency.decimals, rounding);
};

export const convertSekToCurrencyWithRate = (
    price: number | null | undefined,
    currency: Currency,
    rate: number,
    rounding?: RoundingType
): number | null => {
    return convertPrice(price, rate, currency.decimals, rounding);
};

export const convertCurrencyToSek = (
    price: number | null | undefined,
    currency: Currency,
    rateType: CurrencyRateType,
    rounding?: RoundingType
): number | null => {
    const rate = getRateFromRateType(currency, rateType);
    return convertPrice(price, 1 / rate, 0, rounding);
};

const convertPrice = (
    price: number | null | undefined,
    rate: number,
    decimals: number,
    rounding: RoundingType = 'round'
): number | null => {
    if (isNullOrUndefined(price)) return null;

    const decimalFactorForRounding = Math.pow(10, decimals);
    return (
        round(rate * price * decimalFactorForRounding, rounding) /
        decimalFactorForRounding
    );
};

export const getRateFromRateType = (
    currency: Currency,
    rateType?: CurrencyRateType
) => {
    switch (rateType) {
        case CurrencyRateType.Buyer:
            return currency.buyerRate;
        case CurrencyRateType.Seller:
            return currency.sellerRate;
        default:
            throw new Error(`Unknown rate type: ${rateType}`);
    }
};

type RoundingType = 'up' | 'down' | 'round' | 'none';

const round = (value: number, rounding?: RoundingType) => {
    switch (rounding) {
        case 'up':
            return Math.ceil(value);
        case 'down':
            return Math.floor(value);
        case 'round':
            return Math.round(value);
        case 'none':
            return value;
        default:
            throw new Error('Unknown rounding');
    }
};
