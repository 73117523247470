import {
    showItemFirstBidToast,
    showItemOutbiddedToast,
    showItemPaidToast,
    showItemSoldToast,
    showItemWishListReminderToast,
    showItemWonToast
} from './toasts';
import { getGlobalSocket } from './global-socket';

export const bootSiteWideNotifications = ({ socketUrl, joinMemberRoom }) => {
    if (!joinMemberRoom) {
        return;
    }

    const socket = getGlobalSocket(socketUrl);
    let isRetry = false;
    socket.on('JoinMemberRoom', async ({ success }) => {
        // To avoid infinite loop
        if (success || isRetry) {
            // eslint-disable-next-line better-mutation/no-mutation
            isRetry = false;
            return;
        }

        // eslint-disable-next-line better-mutation/no-mutation
        isRetry = true;

        socket.disconnect();
        socket.connect();
    });
    socket.addEventListener('MemberItemOutbidded', (data) =>
        showItemOutbiddedToast(data.ItemId, data.NewLeadingBidAmount)
    );
    socket.addEventListener('MemberItemFirstBid', (data) =>
        showItemFirstBidToast(data.ItemId)
    );
    socket.addEventListener('MemberItemSold', (data) =>
        showItemSoldToast(data.ItemId, data.Price)
    );
    socket.addEventListener('MemberItemWon', (data) =>
        showItemWonToast(data.ItemId, data.Price)
    );
    socket.addEventListener('MemberItemPaid', (data) =>
        showItemPaidToast(data.ItemId)
    );
    socket.addEventListener('MemberItemWishListReminder', (data) =>
        showItemWishListReminderToast(data.ItemId, data.TimeLeftMinutes)
    );
    socket.join('member');
};
