import io from 'socket.io-client';
import { logger } from 'packages/logger';
import { isServer } from 'tradera/utils/nextjs';

export class MissingSocketIoServerUrl extends ReferenceError {}
export class MissingRoomId extends ReferenceError {}

const isAllowedToConnect = !isServer;

/**
 * Connect to a socket.io channel & add callbacks for events it broadcasts
 * @param {string} socketIoServerUrl
 */
export class SocketIoChannel {
    constructor(socketIoServerUrl, autoConnect = false) {
        if (!socketIoServerUrl) {
            throw new MissingSocketIoServerUrl();
        }
        this.socket = io(socketIoServerUrl, {
            autoConnect: isAllowedToConnect && autoConnect
        });
        this.events = {};
        this.rooms = new Set();

        this.socket.on('connect', () => {
            // Rejoin room on reconnect if network connection is lost
            this.rooms.forEach((room) => this.join(room));
        });

        this.socket.on('clientEvent', (payload) => {
            if (!payload.Type) {
                logger('socket.io missing clientEvent Type', {
                    contexts: {
                        socketIo: {
                            payload
                        }
                    }
                });
                return;
            }
            const callback = this.events[payload.Type];
            if (callback) {
                callback(payload.Event, this);
            } else {
                logger(`socket.io unknown clientEvent type`, {
                    contexts: {
                        socketIo: {
                            type: payload.Type
                        }
                    }
                });
            }
        });
    }

    connect() {
        if (isAllowedToConnect && !this.isConnected()) this.socket.connect();
    }

    join(room) {
        if (!room) {
            throw new MissingRoomId();
        }
        if (this.isConnected()) {
            this.socket.emit('join', room);
        }
        if (!this.rooms.has(room)) {
            // Queue join until connect
            this.rooms.add(room);
        }
    }

    leave(room, rejoinOnConnect = false) {
        if (!room) {
            throw new MissingRoomId();
        }
        if (this.isConnected() && this.rooms.has(room)) {
            this.socket.emit('leave', room);
        }
        if (!rejoinOnConnect) {
            this.rooms.delete(room);
        }
    }

    addEventListener(event, callback) {
        this.events[event] = callback;
    }

    removeEventListener(event) {
        delete this.events[event];
    }

    on(event, callback) {
        this.socket.on(event, callback);
    }

    off(event, callback) {
        this.socket.off(event, callback);
    }

    isConnected() {
        return this.socket?.connected;
    }

    disconnect() {
        if (!this || !this.isConnected()) return;
        this.rooms.forEach((room) => this.leave(room, true));
        this.socket.disconnect();
    }
}
