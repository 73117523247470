import ENDPOINTS from 'tradera/constants/endpoints';
import {
    axiosWithTokenRefresh,
    checkResponseVersion,
    finalizeResponse,
    handleError,
    utilizeCancelToken,
    axiosConfigs
} from 'tradera/utils/api';
import initData from 'tradera/legacy/static/packages/init-data';
import { toLocalizedUrl } from 'tradera/utils/url';
import { getLanguage } from 'tradera/utils/language';
import { isServer } from 'tradera/utils/nextjs';
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

type HttpClientConfig = AxiosRequestConfig & { cancelTokenId?: string };

const ensureClientOnly = () => {
    // Cancel tokens would be shared per server instance so ensure we are not on server.
    if (isServer) {
        throw new Error('This HTTP client is only safe to use on frontend');
    }
};

// Prevents URL:s that begins with // when that was not intended.
const getSafeUrl = (baseUrl: string, url?: string) => {
    if (baseUrl.endsWith('/') && url?.startsWith('/')) {
        return baseUrl + url.substring(1);
    }
    return baseUrl + url;
};

const httpClient = (baseUrl: string, shouldLocalizeUrl?: boolean) => {
    const axiosWrapper = <TResponse, TRequest>(
        url: string | undefined,
        httpClientConfig: HttpClientConfig,
        axiosCaller: (
            axios: AxiosInstance,
            url: string,
            config: AxiosRequestConfig
        ) => Promise<AxiosResponse<TResponse, TRequest>>
    ) => {
        const { cancelTokenId, ...axiosConfig } = httpClientConfig;
        const version = initData.version;
        if (cancelTokenId) {
            const { cancel, cancelToken } = utilizeCancelToken(cancelTokenId);
            // eslint-disable-next-line better-mutation/no-mutation
            axiosConfig.cancelToken = cancelToken;
            if (cancel) {
                cancel();
            }
        }
        const safeUrl = getSafeUrl(baseUrl, url);
        const localizedUrl = shouldLocalizeUrl
            ? toLocalizedUrl(safeUrl, getLanguage())
            : safeUrl;
        return axiosCaller(axiosWithTokenRefresh(), localizedUrl, axiosConfig)
            .then(
                version ? checkResponseVersion(version) : (response) => response
            )
            .then(finalizeResponse())
            .catch(handleError());
    };

    return {
        get: <TResponse>(
            url: string,
            httpClientConfig: HttpClientConfig = axiosConfigs.authenticated
        ) => {
            ensureClientOnly();
            return axiosWrapper(url, httpClientConfig, (axios, url, config) =>
                axios.get<TResponse>(url, config)
            );
        },
        post: <TResponse, TRequest>(
            url: string,
            payload: TRequest,
            httpClientConfig: HttpClientConfig = axiosConfigs.authenticated
        ) => {
            ensureClientOnly();
            return axiosWrapper(url, httpClientConfig, (axios, url, config) =>
                axios.post<TResponse, AxiosResponse<TResponse>, TRequest>(
                    url,
                    payload,
                    config
                )
            );
        },
        put: <TResponse, TRequest>(
            url: string,
            payload: TRequest,
            httpClientConfig: HttpClientConfig = axiosConfigs.authenticated
        ) => {
            ensureClientOnly();
            return axiosWrapper(url, httpClientConfig, (axios, url, config) =>
                axios.put<TResponse, AxiosResponse<TResponse>, TRequest>(
                    url,
                    payload,
                    config
                )
            );
        },
        delete: <TResponse>(
            url: string,
            httpClientConfig: HttpClientConfig = axiosConfigs.authenticated
        ) => {
            ensureClientOnly();
            return axiosWrapper(url, httpClientConfig, (axios, url, config) =>
                axios.delete<TResponse>(url, config)
            );
        },
        request: (
            config: AxiosRequestConfig,
            httpClientConfig: HttpClientConfig = axiosConfigs.authenticated
        ) => {
            ensureClientOnly();
            return axiosWrapper(
                config.url,
                httpClientConfig,
                (axios, url, enhancedConfig) => {
                    return axios.request({ ...config, ...enhancedConfig, url });
                }
            );
        }
    };
};

const trpcClient = (endpoint: string) => {
    const client = httpClient(`/api/${endpoint}`, false);
    function unpackResponse<TResponse>(response: { data: TResponse }) {
        return response.data;
    }
    return {
        command: <TResponse, TRequest>(
            commandName: string,
            payload: TRequest,
            httpClientConfig?: HttpClientConfig
        ): Promise<TResponse | undefined> =>
            client
                .post<
                    TResponse,
                    TRequest
                >(`/commands/${commandName}`, payload, httpClientConfig)
                .then(unpackResponse<TResponse>),
        query: <TResponse>(
            queryName: string,
            httpClientConfig?: HttpClientConfig
        ) =>
            client
                .get<TResponse>(`/queries/${queryName}`, httpClientConfig)
                .then(unpackResponse<TResponse>)
    };
};

export const defaultClient = httpClient('');
export const touchWebClient = httpClient('/', true);
export const nextwebClient = httpClient('/api');
export const webApiClient = httpClient(ENDPOINTS.WEB_API, false);
export const cmsApiClient = httpClient('/api/cms');
export const marketingApiClient = httpClient(ENDPOINTS.MARKETING_PUBLIC_API);
export const memberIdentificationClient = trpcClient('member-identification');
export const translationDynamicApiClient = trpcClient(
    'translation-dynamic-api'
);
