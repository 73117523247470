import { logger } from 'tradera/legacy/static/packages/logger';
import { isServer } from 'tradera/utils/nextjs';
import type { TaskPriority } from 'src/types/scheduler-polyfill.d.ts';

type isInputPendingOptions = { includeContinuous: boolean };
type PostTaskCallback = () => unknown;

declare global {
    interface Navigator {
        scheduling: {
            isInputPending: (options?: isInputPendingOptions) => boolean;
        };
    }

    interface Window {
        scheduler: Scheduler;
    }
}

const LONG_RUNNING_TASK_DURATION = 50; // ms

const polyfillScheduler = () => {
    if (isServer) {
        return Promise.reject();
    }

    if (typeof window.scheduler !== 'undefined') {
        return Promise.resolve();
    }

    return import('scheduler-polyfill');
};

const schedulerPolyfill = polyfillScheduler();

export const isInputPending = (): boolean =>
    Boolean(navigator.scheduling?.isInputPending({ includeContinuous: true }));

/**
 * Pauses a synchronous task to allow other main thread tasks on the event loop to run (good for INP)
 * Note that nested setTimeout can cause an up to a 15ms delay in loops
 * (https://chromestatus.com/feature/5710690097561600), so use sparingly and with `isInputPending`:
 * if (isInputPending()) {
 *     await yieldToMain();
 * }
 * Docs: https://web.dev/articles/optimize-long-tasks#use_asyncawait_to_create_yield_points
 */
export const yieldToMain = (): Promise<void> =>
    new Promise((resolve) => {
        setTimeout(resolve, 0);
    });

/**
 * Loop through an array (or other iterable object) of as a background task
 *
 * For each iteration this will pause if there is a user interaction event that needs
 * to be handled on the main thread or if it has exceeded the limit of a long running task (>=50ms);
 */
export const forEachYield = async <ArrayType>(
    array: Array<ArrayType>,
    callback: (
        element: ArrayType,
        index: number,
        array: Array<ArrayType>
    ) => void
): Promise<void> => {
    let deadline = performance.now() + LONG_RUNNING_TASK_DURATION;
    let index = 0;

    for (const element of array) {
        if (isInputPending() || performance.now() >= deadline) {
            // There's a pending user input. Yield here:
            await yieldToMain();
            deadline = performance.now() + LONG_RUNNING_TASK_DURATION;
        }
        callback(element, index, array);
        index += 1;
    }
};

const postTask = async (
    taskCallback: PostTaskCallback,
    priority: TaskPriority
) => {
    try {
        await schedulerPolyfill;
    } catch (error) {
        if (isServer) {
            logger('scheduler-polyfill does not support a node environment');
        }
        // Fallback to ensure taskCallback() is always called
        await yieldToMain();
        return taskCallback();
    }

    return await scheduler.postTask(taskCallback, { priority });
};

export const queueUserBlockingTask = (taskCallback: PostTaskCallback) =>
    postTask(taskCallback, 'user-blocking');

export const queueUserVisibleTask = (taskCallback: PostTaskCallback) =>
    postTask(taskCallback, 'user-visible');

export const queueBackgroundTask = (taskCallback: PostTaskCallback) =>
    postTask(taskCallback, 'background');
