import cookie from 'cookie';

export const getCookieFromString = (cookieString: string, key: string) =>
    cookie.parse(cookieString)[key] || null;

/**
 * Helper function for cookie and GDPR utilities do NOT use this directly. Use the cookie
 * utility instead as that is GDPR compliant.
 * @returns
 */
export const getCookieFromBrowser = (key: string) =>
    getCookieFromString(window.document.cookie, key);

/**
 * Helper function for cookie and GDPR utilities do NOT use this directly. Use the cookie
 * utility instead as that is GDPR compliant.
 * @returns
 */
export const setCookieToBrowser = (s: string) => {
    window.document.cookie = s;
};
