import type { Request } from 'tradera/backend/http/types/http';

export const isServer = typeof window === 'undefined';
export const isServerFunc = () => typeof window === 'undefined';

export const isTest = process.env.NODE_ENV === 'test';

export const isClientApiRequest = (req: Request) =>
    Boolean(req?.url?.startsWith('/_next/data'));

export const isTouchwebInNextweb =
    typeof document !== 'undefined' &&
    document.getElementById('__next') !== null &&
    document.getElementById('init-data') !== null;

export const isNextJs = isServer || document.getElementById('__next') !== null;
