import { PAGE_LIST_TYPES } from 'tradera/apps/my-tradera/app/constants/list-types';

/**
 * @returns {object} list of default cookies that could be set in Touchweb
 * @desc
 * key: Cookie Name
 * default: Default Value if not set
 * length: Time to be stored (months)
 */
export const DEFAULT_COOKIES = {
    ITEM_LAYOUT: {
        key: 'Srp_Item_Layout',
        default: 'layout-grid',
        length: 365
    },
    SITE_LAYOUT: {
        key: 'Srp_Site_Layout',
        default: 'site-width-default',
        length: 365
    }
};

export const MYTRADERA_DEFAULT_COOKIES = {
    LIST_VIEW_TYPE: {
        key: 'MyTradera_ListViewType',
        default: 'Basic',
        length: 14
    },
    ITEMS_PER_PAGE: {
        key: 'MyTradera_ItemsPerPage',
        default: '15',
        length: 14
    },
    SHOW_FILTERS: {
        key: 'MyTradera_ShowFiltersToolbar',
        default: 'hidden',
        length: 14
    },
    LIST_VIEW_TYPE_BUYER_ACTIVE_ITEMS: {
        key: 'MyTradera_ListViewType_' + PAGE_LIST_TYPES.BUYER_ACTIVE_ITEMS,
        default: 'Basic',
        length: 14
    },
    LIST_VIEW_TYPE_BUYER_ITEMS_LOST: {
        key: 'MyTradera_ListViewType_' + PAGE_LIST_TYPES.BUYER_ITEMS_LOST,
        default: 'Basic',
        length: 14
    },
    LIST_VIEW_TYPE_SELLER_ACTIVE: {
        key: 'MyTradera_ListViewType_' + PAGE_LIST_TYPES.SELLER_ACTIVE,
        default: 'Basic',
        length: 14
    },
    LIST_VIEW_TYPE_SELLER_NOT_SOLD: {
        key: 'MyTradera_ListViewType_' + PAGE_LIST_TYPES.SELLER_NOT_SOLD,
        default: 'Basic',
        length: 14
    }
};

export const APP_DOWNLOAD_MODAL_DISMISSED = 'app_download_modal_dismissed';
export const GDPR_CONSENT_COOKIE = 'gdpr_consent_v1';
export const FORCE_GEO_DEV = 'force-geo-dev';
export const ITEMS_PER_PAGE = 'ITEMS_PER_PAGE';
export const LIST_VIEW_TYPE = 'LIST_VIEW_TYPE';
export const MOBILE_OPT_IN_TAGS = 'mobile-opt-in-tags';
export const NATIVE_APP_ENVIRONMENT = 'native-app-environment';
export const PREFERRED_CURRENCY = 'preferred_currency';
export const REGION_PICKER_DISMISSED = 'region_picker_dismissed_session';
export const S = 's';
export const SHIPPING_COUNTRY = 'shipping_country';
export const SHOW_FILTERS = 'SHOW_FILTERS';
export const SIGNUP_REFERRAL_PATH = 'signup_referral_path';
export const SOLD_VIEW_PICKLIST = 'sold_view_picklist';
export const TEST = 'test';
export const SAVE_SEARCH_MODAL_DISMISSED = 'save_search_modal_dismissed';
export const USERPREFERENCES = 'UserPreferences';
export const CART_ID = 'cartId';
export const VIEWPORT_WIDTH = 'viewport-width';
export const SHIP_TO_SWEDEN_DISCLAIMER_DISMISSED =
    'ship-to-sweden-disclaimer-dismissed';
export const HAS_LOGGED_IN = 'has-logged-in';
export const PREVIOUS_LOGIN_METHOD = 'previous-login-method';
export const EXPERIMENT_BUCKET = 'experiment_bucket';
export const PRODUCT_COMMUNICATION_DISMISSED =
    'product_communication_dismissed';
export const WISH_LIST_NOTIFICATION_DISPLAY_COUNT =
    'wish-list-nudge-notification-display-count';
export const WISH_LIST_NOTIFICATION_HIDDEN =
    'wish-list-nudge-notification-hidden';
export const RECENTLY_VIEWED_ITEM_IDS = 'recently-viewed-items';
export const RECENT_SEARCHES = 'recent-searches';
export const SAVED_SEARCHES = 'saved-searches';
export const ENTERED_FASHION_PAGE = 'entered-fashion-page';

// Social login cookies -->
export const APPLE_ATTEMPTED_ID = 'apple_attempted_id';
export const APPLE_ATTEMPTED_TOKEN = 'apple_attempted_token';

export const FACEBOOK_ATTEMPTED_ID = 'fb_attempted_id';
export const FACEBOOK_ATTEMPTED_TOKEN = 'fb_attempted_token';

export const GOOGLE_ATTEMPTED_ID = 'google_attempted_id';
export const GOOGLE_ATTEMPTED_TOKEN = 'google_attempted_token';

export const KLARNA_ATTEMPTED_ID = 'klarna_attempted_id';
export const KLARNA_ATTEMPTED_TOKEN = 'klarna_attempted_token';
export const KLARNA_ATTEMPTED_REFRESH_TOKEN = 'klarna_attempted_refresh_token';
// <-- Social login cookies

export const COOKIE_CATEGORIES = {
    Essential: 1,
    Performance: 2,
    Functional: 3,
    Marketing: 4
};

export const COLLECT_SSN_MODAL_DISMISSED = 'collect-ssn-modal-dismissed';
export const TRADERA_PAY_CONSENT_MODAL_DISMISSED =
    'tradera-pay-consent-modal-dismissed';
export const HAS_SEEN_COMBINEDSHIPPING_MODAL =
    'has-seen-combined-shipping-modal';

/**
 * all cookies that are used by tradera must be categorized in the array below.
 */
export const CATEGORIZED_COOKIES = {
    [GDPR_CONSENT_COOKIE]: COOKIE_CATEGORIES.Essential,
    [NATIVE_APP_ENVIRONMENT]: COOKIE_CATEGORIES.Essential,
    [TEST]: COOKIE_CATEGORIES.Essential, // Unit test cookie

    [EXPERIMENT_BUCKET]: COOKIE_CATEGORIES.Performance,

    [APP_DOWNLOAD_MODAL_DISMISSED]: COOKIE_CATEGORIES.Functional,
    [DEFAULT_COOKIES.ITEM_LAYOUT.key]: COOKIE_CATEGORIES.Functional,
    [DEFAULT_COOKIES.SITE_LAYOUT.key]: COOKIE_CATEGORIES.Functional,
    [FORCE_GEO_DEV]: COOKIE_CATEGORIES.Functional,
    [HAS_LOGGED_IN]: COOKIE_CATEGORIES.Functional,
    [PREVIOUS_LOGIN_METHOD]: COOKIE_CATEGORIES.Functional,
    [ITEMS_PER_PAGE]: COOKIE_CATEGORIES.Functional,
    [LIST_VIEW_TYPE]: COOKIE_CATEGORIES.Functional,
    [MOBILE_OPT_IN_TAGS]: COOKIE_CATEGORIES.Functional,
    [MYTRADERA_DEFAULT_COOKIES.ITEMS_PER_PAGE.key]:
        COOKIE_CATEGORIES.Functional,
    [MYTRADERA_DEFAULT_COOKIES.LIST_VIEW_TYPE.key]:
        COOKIE_CATEGORIES.Functional,
    [MYTRADERA_DEFAULT_COOKIES.SHOW_FILTERS.key]: COOKIE_CATEGORIES.Functional,
    [PREFERRED_CURRENCY]: COOKIE_CATEGORIES.Functional,
    [REGION_PICKER_DISMISSED]: COOKIE_CATEGORIES.Functional,
    [S]: COOKIE_CATEGORIES.Functional, // saved search
    [SHIPPING_COUNTRY]: COOKIE_CATEGORIES.Essential,
    [SHOW_FILTERS]: COOKIE_CATEGORIES.Functional,
    [SIGNUP_REFERRAL_PATH]: COOKIE_CATEGORIES.Functional,
    [SOLD_VIEW_PICKLIST]: COOKIE_CATEGORIES.Functional,
    [USERPREFERENCES]: COOKIE_CATEGORIES.Functional,
    [SAVE_SEARCH_MODAL_DISMISSED]: COOKIE_CATEGORIES.Functional,
    [CART_ID]: COOKIE_CATEGORIES.Functional,
    [VIEWPORT_WIDTH]: COOKIE_CATEGORIES.Functional,
    [SHIP_TO_SWEDEN_DISCLAIMER_DISMISSED]: COOKIE_CATEGORIES.Functional,
    [MYTRADERA_DEFAULT_COOKIES.LIST_VIEW_TYPE_BUYER_ACTIVE_ITEMS.key]:
        COOKIE_CATEGORIES.Functional,
    [MYTRADERA_DEFAULT_COOKIES.LIST_VIEW_TYPE_BUYER_ITEMS_LOST.key]:
        COOKIE_CATEGORIES.Functional,
    [MYTRADERA_DEFAULT_COOKIES.LIST_VIEW_TYPE_SELLER_ACTIVE.key]:
        COOKIE_CATEGORIES.Functional,
    [MYTRADERA_DEFAULT_COOKIES.LIST_VIEW_TYPE_SELLER_NOT_SOLD.key]:
        COOKIE_CATEGORIES.Functional,
    [PRODUCT_COMMUNICATION_DISMISSED]: COOKIE_CATEGORIES.Functional,
    [WISH_LIST_NOTIFICATION_DISPLAY_COUNT]: COOKIE_CATEGORIES.Functional,
    [WISH_LIST_NOTIFICATION_HIDDEN]: COOKIE_CATEGORIES.Functional,
    [COLLECT_SSN_MODAL_DISMISSED]: COOKIE_CATEGORIES.Functional,
    [TRADERA_PAY_CONSENT_MODAL_DISMISSED]: COOKIE_CATEGORIES.Functional,
    [HAS_SEEN_COMBINEDSHIPPING_MODAL]: COOKIE_CATEGORIES.Functional,
    [RECENTLY_VIEWED_ITEM_IDS]: COOKIE_CATEGORIES.Functional,
    [RECENT_SEARCHES]: COOKIE_CATEGORIES.Functional,
    [APPLE_ATTEMPTED_ID]: COOKIE_CATEGORIES.Essential,
    [APPLE_ATTEMPTED_TOKEN]: COOKIE_CATEGORIES.Essential,
    [FACEBOOK_ATTEMPTED_ID]: COOKIE_CATEGORIES.Essential,
    [FACEBOOK_ATTEMPTED_TOKEN]: COOKIE_CATEGORIES.Essential,
    [GOOGLE_ATTEMPTED_ID]: COOKIE_CATEGORIES.Essential,
    [GOOGLE_ATTEMPTED_TOKEN]: COOKIE_CATEGORIES.Essential,
    [KLARNA_ATTEMPTED_ID]: COOKIE_CATEGORIES.Essential,
    [KLARNA_ATTEMPTED_TOKEN]: COOKIE_CATEGORIES.Essential,
    [KLARNA_ATTEMPTED_REFRESH_TOKEN]: COOKIE_CATEGORIES.Essential,
    [SAVED_SEARCHES]: COOKIE_CATEGORIES.Functional,
    [ENTERED_FASHION_PAGE]: COOKIE_CATEGORIES.Functional
};

export const COOKIE_DEFAULT_CATEGORY = COOKIE_CATEGORIES.Functional;

/**
 * List of cookies which are OK for any JavaScript to access. Never add cookies
 * that would be a security risk if exposed to third party scripts.
 */
export const COOKIES_REQUIRED_FOR_SSR_EXPOSED_TO_JS_IN_BROWSER = [
    CART_ID,
    EXPERIMENT_BUCKET,
    FORCE_GEO_DEV,
    GDPR_CONSENT_COOKIE,
    REGION_PICKER_DISMISSED,
    PRODUCT_COMMUNICATION_DISMISSED,
    SHIPPING_COUNTRY,
    RECENTLY_VIEWED_ITEM_IDS,
    RECENT_SEARCHES,
    DEFAULT_COOKIES.SITE_LAYOUT.key,
    DEFAULT_COOKIES.ITEM_LAYOUT.key,
    SAVED_SEARCHES
];

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
export const YEAR_IN_MILLISECONDS = DAY_IN_MILLISECONDS * 365;
