export const isSwedishOrUndefined = (languageCodeIso2) =>
    languageCodeIso2 === undefined || languageCodeIso2.toLowerCase() === 'sv';

export const getLanguageFromCountryCode = (countryCodeIso2) => {
    const key = countryCodeIso2?.toLowerCase() || 'se';
    const languageMap = {
        se: 'sv',
        dk: 'da',
        de: 'de',
        fr: 'fr',
        nl: 'nl'
    };
    return languageMap[key] || 'en';
};

export const getCountryCodeForFlagFromLanguage = (languageCode) => {
    const key = languageCode?.toLowerCase() || 'sv';
    const countryMap = {
        en: 'gb', // We default to GB which isn't strictly correct
        da: 'dk',
        de: 'de',
        fr: 'fr',
        nl: 'nl'
    };
    return countryMap[key] || 'se';
};

export const getCountryCodeFromLanguage = (languageCode) => {
    const key = languageCode?.toLowerCase() || 'sv';
    const countryMap = {
        da: 'dk',
        de: 'de',
        fr: 'fr',
        nl: 'nl'
    };
    return countryMap[key] || 'se';
};

export const areLanguageCodesIso2Equal = (first, second) =>
    (first || 'SV').toUpperCase() === (second || 'SV').toUpperCase();
