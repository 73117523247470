import { formatNumberWithSeparators } from 'tradera/utils/format';

export type Price = string | number;

type FormatPriceOptions = {
    currency: string;
    preferredCurrency: string;
    style: 'decimal' | 'currency' | 'percent' | 'unit';
    currencyDisplay: 'code' | 'symbol' | 'narrowSymbol' | 'name';
    useGrouping: boolean;
    minimumFractionDigits: number;
    maximumFractionDigits: number;
};

export type OverrideFormatPriceOptions = Partial<FormatPriceOptions>;

export const formatPrice = (
    price: Price,
    inputLocale: string | null,
    overrideOptions: OverrideFormatPriceOptions = {}
): string => {
    const locale = inputLocale || 'sv-SE';
    const defaultOptions: FormatPriceOptions = {
        style: 'currency',
        currency: 'SEK',
        preferredCurrency: 'SEK',
        currencyDisplay:
            locale.startsWith('sv') &&
            (overrideOptions.currency || 'SEK') === 'SEK' &&
            (overrideOptions.preferredCurrency || 'SEK') === 'SEK'
                ? 'symbol'
                : 'code',
        useGrouping: false,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };
    const options = {
        ...defaultOptions,
        ...overrideOptions
    };
    const priceAsNumber = typeof price !== 'number' ? parseInt(price) : price;

    if (!isLocaleFormattingSupportedInBrowser) {
        const suffix =
            options.currencyDisplay === 'code' && options.currency === 'SEK'
                ? 'kr'
                : options.currency;
        return `${priceAsNumber} ${suffix}`;
    }

    const formattedPrice = priceAsNumber.toLocaleString(locale, options);
    return formatNumberWithSeparators(formattedPrice);
};

// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
function toLocaleStringSupportsLocales() {
    try {
        Number(0).toLocaleString('i');
    } catch (e: unknown) {
        return (
            e !== null &&
            typeof e === 'object' &&
            'name' in e &&
            e.name === 'RangeError'
        );
    }
    return false;
}

// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
function toLocaleStringSupportsOptions() {
    return !!(
        typeof Intl === 'object' &&
        Intl &&
        typeof Intl.NumberFormat === 'function'
    );
}

const isLocaleFormattingSupportedInBrowser =
    toLocaleStringSupportsLocales() && toLocaleStringSupportsOptions();
