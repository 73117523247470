import 'core-js/modules/es.promise.finally';
import 'core-js/modules/es.array.iterator';
import 'intersection-observer';
import 'custom-event-polyfill';
import 'whatwg-fetch';

import initData from 'init-data';
import {
    handleModuleError,
    isSpaModule,
    loadModule,
    loadSpaModule,
    runModule
} from 'utils/modules';
import { isServer } from 'tradera/utils/nextjs';
import { bootApp } from './boot-app';
import 'tradera/styles/global.scss';

const bootInit = () => {
    bootApp(initData);

    if (initData) {
        const module = initData.module.split('/')[4];
        let moduleLoader;
        if (isSpaModule(module, initData)) {
            moduleLoader = loadSpaModule(initData);
        } else {
            moduleLoader = import('./init-module')
                .then((initModule) => initModule.default(initData))
                .then(() => loadModule(module, initData))
                .then((loadedModule) => {
                    if (loadedModule) {
                        runModule(loadedModule, initData);
                    }
                    return;
                });
        }
        moduleLoader
            .then(() => {
                window.document.body.setAttribute('data-module-loaded', 'true');
                return;
            })
            .catch(handleModuleError);
    }
};

if (!isServer) {
    bootInit();
}
