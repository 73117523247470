import type { RootState } from '../configure-store';

export const selectEnvironment = (state: RootState) => state.environment;

export const selectFeatureSwitches = (state: RootState) =>
    state?.environment?.featureSwitches;
export const selectIsFeatureEnabled =
    (featureName: string) => (state: RootState) =>
        selectFeatureSwitches(state)?.[featureName] === true;

export const selectEnvVariables = (state: RootState) =>
    state.environment.variables;

export const selectWebLiveUrl = (state: RootState) =>
    state.environment.variables.PUBLIC_WEB_LIVE_URL;

export const selectBrowserName = (state: RootState) =>
    state?.environment?.browserName;

export const selectIsNativeApp = (state: RootState) =>
    !!state?.environment?.isNativeAppContext;

export const selectNativeAppLanguage = (state: RootState) =>
    state?.environment?.appLanguage;
