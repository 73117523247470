export const formatNumberWithSeparators = (
    number: number | string,
    separator: string = '\u2006'
): string => {
    return number
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + separator);
};

// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
function toLocaleStringSupportsLocales(): boolean {
    try {
        Number(0).toLocaleString('i');
    } catch (e) {
        return e instanceof RangeError;
    }
    return false;
}

// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
function toLocaleStringSupportsOptions(): boolean {
    return !!(
        typeof Intl === 'object' &&
        Intl &&
        typeof Intl.NumberFormat === 'function'
    );
}

const isLocaleFormattingSupportedInBrowser: boolean =
    toLocaleStringSupportsLocales() && toLocaleStringSupportsOptions();

export const formatSellerDsrAverage = (
    value?: number | null,
    locale: string = 'sv-SE'
): string | undefined => {
    return value?.toLocaleString(locale, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    });
};

type CurrencyOptions = Intl.NumberFormatOptions & {
    preferredCurrency?: string;
};

export const getCurrencySymbol = (
    locale: string,
    overrideOptions: CurrencyOptions = {}
): string => {
    const defaultOptions: Intl.NumberFormatOptions = {
        style: 'currency',
        currency: 'SEK',
        currencyDisplay:
            locale.startsWith('sv') &&
            (overrideOptions.currency || 'SEK') === 'SEK' &&
            (overrideOptions.preferredCurrency || 'SEK') === 'SEK'
                ? 'symbol'
                : 'code',
        useGrouping: false,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    };
    return (0)
        .toLocaleString(locale, {
            ...defaultOptions,
            ...overrideOptions
        } as Intl.NumberFormatOptions)
        .replace(/\d/g, '')
        .trim();
};

export const formatDateWithDayOfWeek = (
    dateString: string,
    locale: string
): string => {
    if (!isLocaleFormattingSupportedInBrowser) {
        return dateString;
    }

    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: undefined,
        month: 'long',
        day: 'numeric'
    };
    return new Intl.DateTimeFormat(locale, options).format(
        Date.parse(dateString)
    );
};

export const formatItemId = (itemId?: number): string | undefined => {
    if (!itemId || !Number.isInteger(itemId)) return undefined;

    return itemId.toLocaleString('sv-SE'); // Groupings of 3 separated by spaces
};

export const formatOrderNumber = (
    orderNr?: string | number
): string | undefined =>
    formatItemId(
        typeof orderNr === 'string' ? Number.parseInt(orderNr) : orderNr
    );
