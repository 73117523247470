import {
    selectGeolocation,
    selectIsLoggedIn,
    selectIsOutsideSweden
} from 'tradera/state/member/selectors';
import { selectPreferredLanguage } from 'tradera/state/language/selectors';
import { getLanguageFromCountryCode } from 'tradera/localization/languagecode';
import {
    isDenmarkCountryCode,
    isNetherlandsCountryCode,
    isSwedenCountryCodeOrUndefined
} from 'tradera/localization/countrycode';
import type { RootState } from '../configure-store';

export const selectShowRegionSelection = (state: RootState) =>
    !selectIsLoggedIn(state) && selectIsOutsideSweden(state);
export const selectAvailableShippingCountryCodes = (state: RootState) =>
    state.shippingRegion.availableShippingCountryCodes;
export const selectAvailableShippingCountries = (state: RootState) =>
    state.shippingRegion.availableShippingCountries;
export const selectShippingCountry = (state: RootState) =>
    state.shippingRegion.shippingCountry;
export const selectIsLoadingAvailableShippingCountries = (state: RootState) =>
    state.shippingRegion.isLoadingAvailableShippingCountries;
export const selectHasLoadedAvailableShippingCountries = (state: RootState) =>
    state.shippingRegion.hasLoadedAvailableShippingCountries;
export const selectShippingRegionCountryCodeIso2 = (state: RootState) =>
    state.shippingRegion.shippingCountry?.countryCodeIso2;
export const selectIsSwedishVisitor = (state: RootState) =>
    isSwedenCountryCodeOrUndefined(selectShippingRegionCountryCodeIso2(state));
export const selectIsDanishVisitor = (state: RootState) =>
    isDenmarkCountryCode(selectShippingRegionCountryCodeIso2(state));
export const selectIsDutchVisitor = (state: RootState) =>
    isNetherlandsCountryCode(selectShippingRegionCountryCodeIso2(state));

const gpsMatchesLanguage = (state: RootState) => {
    const geoLocation = selectGeolocation(state);
    if (!geoLocation) {
        return true;
    }
    const country = geoLocation?.isoCode?.toLowerCase();
    const expectedLang = getLanguageFromCountryCode(country);
    const language =
        selectPreferredLanguage(state)?.languageCodeIso2?.toLowerCase() || 'sv';
    return expectedLang === language;
};

export const selectShowLocalizationRegionPicker = (state: RootState) =>
    !selectIsLoggedIn(state) &&
    !state.shippingRegion.regionPickerDismissed &&
    !state.shippingRegion.shippingCountryCookieValue &&
    !gpsMatchesLanguage(state);
