import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { NextLink } from 'tradera/apps/nextweb/components/next-link';
import { updateEnvironmentHash } from 'tradera/state/environment/actions';
import { useIsFeatureEnabled } from 'tradera/hooks/use-is-feature-enabled';
import { useLocation } from 'tradera/hooks/use-location';
import { isTouchwebInNextweb } from 'tradera/utils/nextjs';
import { useAppSelector } from 'tradera/state/hooks';
import { removePrivacySandboxIframes } from 'tradera/utils/privacy-sandbox-iframes';
import {
    selectFeatureSwitches,
    selectIsNativeApp
} from 'tradera/state/environment/selectors';
import {
    getNavigationType,
    NavigationType
} from 'tradera/components/alink/navigation-type';

const PRODUCTION_ORIGIN = 'https://www.tradera.com';

const isFragmentNavSamePage = (url: URL, href?: string) =>
    !!url.hash && url.pathname === '/' && !href?.startsWith('/#');

const getRelativeUrl = (url: URL, href?: string) => {
    if (isFragmentNavSamePage(url, href)) return `${url.search}${url.hash}`;
    return `${url.pathname}${url.search}${url.hash}`;
};

const getReferrerPolicy = (url: URL) =>
    url.hostname === 'info.tradera.com'
        ? { referrerPolicy: 'unsafe-url' as const }
        : {};

export type ALinkProps = React.ComponentPropsWithRef<'a'> & {
    useLink?: boolean;
};

const mapTarget = (target?: string) => {
    switch (target) {
        case '_blank':
            return 'new';
        case '_parent':
        case '_top':
            return 'top';
        default:
            return 'self';
    }
};

const ALink = React.forwardRef<HTMLAnchorElement, ALinkProps>(function ALink(
    {
        useLink: isTouchwebSpaLink,
        href,
        children,
        onClick: givenOnClick,
        ...otherProps
    }: ALinkProps,
    forwardedRef
) {
    const dispatch = useDispatch();
    const currentLocation = useLocation();
    const isNativeApp = useAppSelector(selectIsNativeApp);
    const toggles = useAppSelector(selectFeatureSwitches) || {};
    const isSpaNavigationEnabled =
        (useAppSelector((state) => state.environment.isSpaNavigationEnabled) ||
            isTouchwebInNextweb) &&
        !isNativeApp; // Apps cannot handle history correctly if SPA navigation is enabled
    const isNextWebAddUrlParamEnabled = useIsFeatureEnabled(
        'next-web-add-url-param'
    );
    const isTouchWebAddUrlParamEnabled = useIsFeatureEnabled(
        'touch-web-add-url-param'
    );
    const absoluteLinkUrl = new URL(href ?? '', PRODUCTION_ORIGIN);

    if (isTouchWebAddUrlParamEnabled) {
        absoluteLinkUrl.searchParams.set('next', '0');
    } else if (isNextWebAddUrlParamEnabled) {
        absoluteLinkUrl.searchParams.set('next', '1');
    }

    const navigationType = getNavigationType({
        fromUrl: currentLocation,
        toUrl: absoluteLinkUrl,
        target: mapTarget(otherProps.target),
        isTouchwebSpaLink,
        isNativeApp,
        toggles
    });
    const handleSpaNavigationClick = useCallback(
        (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            removePrivacySandboxIframes();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dispatch<any>(updateEnvironmentHash());
            if (givenOnClick) givenOnClick(evt);
        },
        [dispatch, givenOnClick]
    );

    const spaNavigationOrigins = [PRODUCTION_ORIGIN, currentLocation?.origin];
    const isRelativeUrl = spaNavigationOrigins.includes(absoluteLinkUrl.origin);

    // Strip away origin for SPA links
    const relativeUrl = getRelativeUrl(absoluteLinkUrl, href);

    if (isSpaNavigationEnabled && isRelativeUrl) {
        switch (navigationType) {
            case NavigationType.NextSpaNavigation:
                // NextJS routing
                return (
                    <NextLink
                        href={relativeUrl}
                        passHref
                        onClick={handleSpaNavigationClick}
                        ref={forwardedRef}
                        data-link-type="next-link"
                        {...otherProps}>
                        {children}
                    </NextLink>
                );
            case NavigationType.TouchwebSpaNavigation:
                // react-router-dom routing
                return (
                    <Link
                        to={relativeUrl}
                        {...otherProps}
                        onClick={handleSpaNavigationClick}
                        data-link-type="react-router-dom-link"
                        ref={forwardedRef}>
                        {children}
                    </Link>
                );
        }
    }

    const url = isRelativeUrl ? relativeUrl : absoluteLinkUrl.toString();
    return (
        <a
            href={url}
            onClick={givenOnClick}
            {...getReferrerPolicy(absoluteLinkUrl)}
            {...otherProps}
            ref={forwardedRef}
            data-link-type="a-link">
            {children}
        </a>
    );
});

// Deprecated, please use the named export instead
export default ALink;
