export const DEFAULT_PAGE_TITLE_TRANSLATION_KEY = 'pageTitle_startPage';

export const NS_TOUCHWEB = 'touchWeb';
export const NS_ATTRIBUTES = 'attributes';
export const NS_CATEGORIES = 'categories';
export const NS_BACKEND = 'backend';

export const DEFAULT_LANGUAGE = 'sv';
export const URL_LANGUAGES = ['en', 'de', 'da', 'fr', 'nl'];

export const SUPPORT_SITE_DEFAULT_LANGUAGE = 'se';
export const SUPPORT_SITE_LANGUAGE_CODE_MAP = {
    sv: 'se',
    en: 'gb',
    da: 'dk',
    de: 'de',
    fr: 'fr',
    nl: 'nl'
};

export const DEFAULT_LOCALE = 'sv-SE';
export const LANGUAGE_LOCALE = {
    sv: 'sv-SE',
    en: 'en-US',
    da: 'da-DK',
    de: 'de-DE',
    fr: 'fr-FR',
    nl: 'nl-NL'
};

export const LOCALES_FOR_LOGIN = {
    sv: 'sv_SE',
    en: 'en_GB',
    de: 'de_DE',
    da: 'da_DK',
    fr: 'fr_FR',
    nl: 'nl_NL'
};

export const I18N_FRONTEND_NAMESPACES = [NS_TOUCHWEB, NS_ATTRIBUTES];
export const I18N_BACKEND_NAMESPACES = [NS_CATEGORIES, NS_BACKEND];
export const I18N_NAMESPACES = [
    ...I18N_FRONTEND_NAMESPACES,
    ...I18N_BACKEND_NAMESPACES
];
export const NAMESPACE_PROJECTID = {
    [NS_TOUCHWEB]: '414441505e1db31fcaa776.55234720',
    [NS_ATTRIBUTES]: '9959867960be20fdc7d187.66761514',
    [NS_CATEGORIES]: '894792985d6665b8f079e1.31726015',
    [NS_BACKEND]: '916557165cc061c54ae7c8.44409330'
};

export const SUPPORTED_LANGUAGES = [DEFAULT_LANGUAGE, ...URL_LANGUAGES];
export const SUPPORTED_CURRENCIES = [
    'DKK',
    'GBP',
    'JPY',
    'NOK',
    'SEK',
    'USD',
    'EUR'
];

export const TRANS_SUFFIX = 'Trans';

export const I18N_CACHE_MS = 2 * 60 * 1000;
