/* eslint-disable better-mutation/no-mutation */

import { getServerTime } from 'tradera/utils/time-api';
import { isServer } from 'tradera/utils/nextjs';

let _minServerCheckInterval = 1000 * 60 * 5;
let _serverDiff = 0;
let _didGetServerResponse = false;
let _lastCheckAtMs = null;

const updateServerDiff = async () => {
    _lastCheckAtMs = Date.now();
    const serverTimeObject = await getServerTime();
    if (serverTimeObject) {
        _didGetServerResponse = true;
        // We add the whole latency even though it may give a later
        // time than in reality since a later time gives less time
        // left until auctions expire. This is better than showing
        // too much time left.
        const timeNowOnServer = new Date(
            serverTimeObject.currentTime + serverTimeObject.latency
        );
        _serverDiff = timeNowOnServer.getTime() - Date.now();
    }
};

const shouldCheckServer = () =>
    _lastCheckAtMs === null ||
    Date.now() - _lastCheckAtMs >= _minServerCheckInterval;

let _initialPromise = null;

/**
 * Gets the date on the server and shall be used for calculating auction time left.
 * @returns The date or null if server did not respond at least once.
 */
export const syncServerTime = async () => {
    if (isServer) {
        throw new Error('Never call this on server');
    }
    if (shouldCheckServer()) {
        const promise = updateServerDiff();
        if (!_didGetServerResponse) {
            _initialPromise = promise;
        }
    }

    if (_initialPromise) {
        try {
            await _initialPromise;
        } catch {
            // Don't throw any errors.
        }
        _initialPromise = null;
    }

    return _didGetServerResponse ? new Date(Date.now() + _serverDiff) : null;
};

export const resetForTesting = () => {
    _minServerCheckInterval = 0;
    _didGetServerResponse = null;
};
