import { SocketIoChannel } from 'tradera/services/socket-io-channel';
import { isServer } from 'tradera/utils/nextjs';

let globalSocket = null;

export const getGlobalSocket = (socketUrl) => {
    if (isServer) {
        throw new Error('Must not be used on server.');
    }
    if (!globalSocket) {
        // eslint-disable-next-line better-mutation/no-mutation
        globalSocket = new SocketIoChannel(socketUrl, true);
    }
    return globalSocket;
};
