import { DEFAULT_LANGUAGE } from 'tradera/lang/constants.mjs';
import type { RootState } from '../configure-store';
import { createSelector } from '@reduxjs/toolkit';

export const selectAvailableLanguages = (state: RootState) =>
    state.language.available;

export const selectAvailableLanguageCodesIso2 = createSelector(
    selectAvailableLanguages,
    (available) => available?.map((x) => x.languageCodeIso2)
);

export const selectPreferredLanguage = (state: RootState) =>
    state.language.preferred;

export const selectPreferredLanguageCode = (state: RootState) =>
    state?.language?.preferred?.languageCodeIso2 || DEFAULT_LANGUAGE;

export const selectLocale = (state: RootState) => {
    const language = selectPreferredLanguageCode(state);
    const country = state.language.memberCountryCodeIso2 || 'SE';

    return `${language}-${country}`;
};

export const selectIsForeignLanguageActivated = (state: RootState) =>
    selectPreferredLanguage(state)?.languageCodeIso2 !== DEFAULT_LANGUAGE;

export const selectAutomaticTranslationPreferred = (
    state: RootState
): boolean => state.language.automaticTranslationPreferred ?? true;
