import { useCallback } from 'react';
import { useCurrentLanguage } from 'tradera/lang/translate';
import { useAppSelector } from 'tradera/state/hooks';
import {
    selectCurrencyWithCode,
    selectPreferredCurrency
} from 'tradera/state/multi-currency/selectors';
import type {
    OverrideFormatPriceOptions,
    Price
} from 'tradera/localization/format-price';
import { formatPrice } from 'tradera/localization/format-price';
import type {
    Currency,
    CurrencyRateType
} from 'tradera/state/multi-currency/reducer';
import {
    convertSekToCurrencyWithRate,
    getRateFromRateType
} from 'tradera/localization/convert-price';
import type { ExchangeRateApiDto } from 'tradera/state/services/types/webapi-browse-generated';

/**
 * Formats a price in the current language and currency.
 */
export const useLocalizedPriceFormatter = (rateType: CurrencyRateType) =>
    useLocalizedPriceFormatterInternal({ rateType });

/**
 * Same as useLocalizedPriceFormatter but it always rounds up. Needed for fixed prices,
 * so we never display a price that is lower than what the user will pay in the end.
 */
export const useLocalizedPriceFormatterRoundingUp = (
    rateType: CurrencyRateType
) => useLocalizedPriceFormatterInternal({ roundUp: true, rateType });

/**
 * Does not convert anything. If you for some reason are working with the local currency
 * it could be used but avoid it. Example of usage: in the bid-modal we want to display
 * the exact amount that was input
 */
export const useLocalizedPriceFormatterNoConversion = (
    rateType: CurrencyRateType
) => useLocalizedPriceFormatterInternal({ conversion: false, rateType });

/**
 * Makes the conversion but leaves the symbol. Used to compare local amounts to make a
 * decision in the code.
 */
export const useLocalizedPriceFormatterNoSymbol = (
    rateType: CurrencyRateType
) => useLocalizedPriceFormatterInternal({ style: 'decimal', rateType });

/**
 * Formats a price in the current language and currency but overrides the range of
 * decimals it normally uses.
 */
export const useLocalizedPriceFormatterWithDecimalRange = (
    minDecimals: number | undefined,
    maxDecimals: number | undefined,
    rateType: CurrencyRateType
) => useLocalizedPriceFormatterInternal({ minDecimals, maxDecimals, rateType });

/**
 * Formats a price in the current language and specified currency.
 */
export const useLocalizedPriceFormatterWithSpecificCurrency = (
    currency: Currency,
    rateType: CurrencyRateType
) => useLocalizedPriceFormatterInternal({ currency, rateType });

/**
 * Formats a price in the current language and specified currency.
 */
export const useLocalizedPriceFormatterWithSpecificRate = (
    specificRate: number,
    currencyCode: string
) =>
    useLocalizedPriceFormatterInternal({
        specificRate,
        currency: useAppSelector(selectCurrencyWithCode(currencyCode))!
    });

/**
 * Formats a price in the current language and specified currency/rate if paymentExchangeRate
 * is provided, otherwise it uses current global values.
 */
export const useLocalizedPriceFormatterWithExchangeRate = (
    exchangeRate: ExchangeRateApiDto | null | undefined,
    rateTypeFallback: CurrencyRateType
) =>
    useLocalizedPriceFormatterInternal({
        specificRate: exchangeRate?.rate,
        currency: useAppSelector(
            selectCurrencyWithCode(exchangeRate?.currencyCode || '')
        ),
        rateType: rateTypeFallback
    });

type LocalizedPriceFormatterOptions = {
    conversion?: boolean;
    style?: OverrideFormatPriceOptions['style'];
    roundUp?: boolean;
    minDecimals?: OverrideFormatPriceOptions['minimumFractionDigits'];
    maxDecimals?: OverrideFormatPriceOptions['maximumFractionDigits'];
} & (
    | { rateType: CurrencyRateType }
    | { specificRate: number; currency: Currency }
);

/**
 * Internal to this module, needed to limit the scope of the API.
 */
const useLocalizedPriceFormatterInternal = ({
    conversion = true,
    style,
    roundUp,
    minDecimals,
    maxDecimals,
    ...rest
}: LocalizedPriceFormatterOptions) => {
    const { language } = useCurrentLanguage();
    const preferredCurrency = useAppSelector(selectPreferredCurrency);
    const rateType = 'rateType' in rest ? rest.rateType : undefined;
    const specificRate = 'specificRate' in rest ? rest.specificRate : undefined;
    const currency = 'currency' in rest ? rest.currency : undefined;
    const currencyToUse = currency || preferredCurrency;
    const rate =
        specificRate !== undefined
            ? specificRate
            : getRateFromRateType(currencyToUse, rateType);

    return useCallback(
        (price: Price) =>
            formatPrice(
                conversion
                    ? convertSekToCurrencyWithRate(
                          Number(price),
                          {
                              ...currencyToUse,
                              decimals: maxDecimals || currencyToUse.decimals
                          },
                          rate,
                          roundUp ? 'up' : 'round'
                      )!
                    : Number(price),
                language,
                {
                    currency: currencyToUse.code,
                    minimumFractionDigits:
                        minDecimals === undefined
                            ? currencyToUse.decimals
                            : minDecimals,
                    maximumFractionDigits:
                        maxDecimals === undefined
                            ? currencyToUse.decimals
                            : maxDecimals,
                    preferredCurrency: preferredCurrency.code,
                    ...(style ? { style } : {})
                }
            ),
        [
            conversion,
            language,
            currencyToUse,
            roundUp,
            style,
            minDecimals,
            maxDecimals,
            preferredCurrency,
            rate
        ]
    );
};
