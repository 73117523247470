import { useTranslator } from 'tradera/lang/translate';

type Props = {
    message: string;
    header?: string;
    messageData?: Record<string, unknown>;
    headerData?: Record<string, unknown>;
};

export const MessageToast = ({
    message,
    header,
    messageData,
    headerData
}: Props) => {
    const { t } = useTranslator();

    return (
        <div>
            {header && <h4>{t(header, headerData)}</h4>}
            {message && (
                <span className="font-weight-normal">
                    {t(message, messageData)}
                </span>
            )}
        </div>
    );
};
