import initData from 'tradera/legacy/static/packages/init-data';
import { isNextJs, isServer, isTouchwebInNextweb } from 'tradera/utils/nextjs';

const getSiteBaseUrl = () =>
    isServer
        ? process.env.PUBLIC_TOUCHWEB_WINDOW_LOCATION_ORIGIN
        : window.location.origin;

const webApiUrl: string =
    isNextJs && !isTouchwebInNextweb
        ? `${getSiteBaseUrl()}/api/webapi`
        : initData.webApiUrl
          ? initData.webApiUrl
          : '/api/webapi';

export default {
    AVAILABLE_SHIPPING_COUNTRIES:
        webApiUrl + '/member/getavailablecountriesforshipping',
    CART_OVERVIEW_VIEW: webApiUrl + '/shopping/checkout/cartoverview',
    CART_ADD_ITEM: webApiUrl + '/shopping/cart/item',
    CART_REMOVE_ITEMS: webApiUrl + '/shopping/cart/removeitems',
    CART_SET_ITEM_QUANTITY: webApiUrl + '/shopping/cart/setitemquantity',
    FAVOURITE_SELLERS: webApiUrl + `/discover/favouritesellers-and-items2`,
    HYPERWALLET_PAYOUT_COMPLETE:
        webApiUrl + '/member/paymentprovider/completehyperwallettransfermethod',
    HYPERWALLET_PAYOUT_TOKEN:
        webApiUrl + '/member/paymentprovider/hyperwalletauthenticationtoken',
    HYPERWALLET_PAYOUT:
        webApiUrl +
        '/member/paymentprovider/hyperwallettransfermethodviewmodel',
    INTERNATIONAL_SHIPPING_CALCULATOR:
        webApiUrl + '/browse/internationalshippingcalculator',
    ORDER_CHECKOUT_PAYMENT: initData.orderCheckoutPaymentUrl,
    ORDER_CHECKOUT_REQUEST_COMBINED_PRICE:
        webApiUrl + '/shopping/ordercheckout/requestcombinedshippingprice',
    ORDER_CHECKOUT_CREATE_UNCOMMITTED_PURCHASE_ORDER:
        webApiUrl + '/shopping/ordercheckout/createuncommittedpurchaseorder',
    ORDER_PURCHASE_SHOP_ITEMS:
        webApiUrl + '/shopping/ordercheckout/purchaseshopitems',
    SEARCH: '/search',
    WEB_API: webApiUrl,
    UPI: (purchaseOrderId: string) =>
        webApiUrl + '/member/uppo-strike/?PurchaseOrderId=' + purchaseOrderId,
    MCP: (purchaseOrderId: string) =>
        webApiUrl + '/member/mcp-strike/?PurchaseOrderId=' + purchaseOrderId,
    MCP_CONFIRM: (purchaseOrderId: string) =>
        webApiUrl +
        '/member/mcp-strike-confirm/?PurchaseOrderId=' +
        purchaseOrderId,
    PURCHASE_ORDER: (purchaseOrderId: string) =>
        webApiUrl + '/member/purchaseorder/?PurchaseOrderId=' + purchaseOrderId,
    MARKETING_PUBLIC_API: '/api/marketing',
    SHIPPING_CHECKOUT_DELETE: '/my/shipping/checkout/delete'
};
