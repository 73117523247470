import { logger } from 'packages/logger';

function isSpaModule(module, initData) {
    const { isSinglePageApp } = initData;
    switch (module) {
        case 'endless-recommendation':
        case 'favourite-sellers':
        case 'landing':
        case 'report-item':
        case 'tradera-shop':
            return true;

        default:
            return isSinglePageApp;
    }
}

async function loadSpaModule(initData) {
    const module = await import(
        /* webpackChunkName: "tradera" */ '../../../apps/tradera/'
    );
    return module.default(initData);
}

async function loadModule(module) {
    switch (module) {
        // Fallback modules for when SPA is toggled off
        // TODO: Remove when fully incorporated in SPA
        case 'order-checkout':
        case 'landing':
        case 'cart':
        case 'upi':
        case 'inr':
            return await import(
                /* webpackChunkName: "tradera" */ '../../../apps/tradera/'
            );
    }
}

function runModule(module, initData = {}) {
    if (!module.default) {
        console.error(`Cannot run module "${module}" without exported default`);
    }
    return module.default(initData);
}

const handleModuleError = (error) => logger(error);

export { isSpaModule, loadSpaModule, loadModule, runModule, handleModuleError };
