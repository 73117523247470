import { createElement } from 'react';
import { toast } from 'react-toastify';

import { ItemToast } from 'tradera/components/toasts/item-toast';
import { MessageToast } from 'tradera/components/toasts/message-toast';
import { isServer } from 'tradera/utils/nextjs';
import { webApiClient } from '../utils/http';
import { createQueue } from './toast-queue';
import type { ItemBasicInfoApiResponse } from 'tradera/state/services/types/webapi-browse-generated';

const queuedToasts = createQueue();

const openNextToast = async () => {
    if (isServer) {
        return;
    }
    if (queuedToasts.isInProgress()) {
        return;
    }
    const nextItem = queuedToasts.next();
    if (nextItem) {
        try {
            const queueLength = queuedToasts.getLength();
            const toastOptions = {
                autoClose: Math.max(2, 5 - queueLength) * 1000,
                onClose: () => {
                    queuedToasts.notifyDone();
                    openNextToast();
                }
            };
            if (nextItem.eventData.itemId) {
                const response =
                    await webApiClient.get<ItemBasicInfoApiResponse>(
                        `/browse/item-basic-info/${nextItem.eventData.itemId}`
                    );
                const { imageUrl, itemUrl, shortDescription } =
                    response.data.item || {};
                toast(
                    // TODO: convert to JSX when we have a proper story for this
                    createElement(ItemToast, {
                        imageUrl,
                        itemUrl,
                        shortDescription,
                        type: nextItem.type,
                        eventData: nextItem.eventData
                    }),
                    toastOptions
                );
            } else if (nextItem.eventData.message) {
                const { message, header, messageData, headerData } =
                    nextItem.eventData;
                toast(
                    <MessageToast
                        message={message}
                        header={header}
                        messageData={messageData}
                        headerData={headerData}
                    />,
                    { ...toastOptions, type: nextItem.eventData.type }
                );
            }
        } catch (e) {
            queuedToasts.notifyDone();
            openNextToast();
            throw e;
        }
    }
};

const handleNotification = async (
    type: string,
    eventData:
        | {
              message: string;
              header?: string | null;
              messageData: unknown;
              headerData: unknown;
              type: (typeof toast.TYPE)[keyof typeof toast.TYPE] | string;
          }
        | {
              itemId: number;
              newLeadingBidAmount?: number;
              price?: number;
              timeLeftMinutes?: number;
          },
    ttlInMinutes = 10
) => {
    if (document.visibilityState !== 'visible') {
        // We don't want to show messages if page is not visible. If we do they will queue up!
        return;
    }
    queuedToasts.add({ type, eventData }, ttlInMinutes);
    openNextToast();
};

export const showItemOutbiddedToast = (
    itemId: number,
    newLeadingBidAmount: number
) =>
    handleNotification('ItemOutbidded', {
        itemId,
        newLeadingBidAmount
    });

export const showItemFirstBidToast = (itemId: number) =>
    handleNotification('ItemFirstBid', { itemId });

export const showItemSoldToast = (itemId: number, price: number) =>
    handleNotification('ItemSold', { itemId, price });

export const showItemWonToast = (itemId: number, price: number) =>
    handleNotification('ItemWon', { itemId, price });

export const showItemPaidToast = (itemId: number) =>
    handleNotification('ItemPaid', { itemId });

export const showItemWishListReminderToast = (
    itemId: number,
    timeLeftMinutes: number
) =>
    handleNotification(
        'ItemWishListReminder',
        { itemId, timeLeftMinutes },
        timeLeftMinutes > 10 ? 10 : 2
    );

/**
 *
 * @param message The message translation key
 * @param header The header translation key
 * @param messageData Any data that's needed for the message translation key
 * @param headerData Any data that's needed for the header translation key
 */
export const showSuccessToast = (
    message: string,
    header?: string,
    messageData: unknown = null,
    headerData: unknown = null
) => {
    handleNotification('Message', {
        message,
        header,
        messageData,
        headerData,
        type: toast.TYPE.SUCCESS
    });
};

export const showErrorToast = (
    message: string,
    header?: string | null,
    messageData: unknown = null,
    headerData: unknown = null
) => {
    handleNotification('Message', {
        header,
        message,
        messageData,
        headerData,
        type: toast.TYPE.ERROR
    });
};

export const showInfoToast = (
    message: string,
    header?: string,
    messageData = null,
    headerData = null
) => {
    handleNotification('Message', {
        message,
        header,
        messageData,
        headerData,
        type: toast.TYPE.INFO
    });
};

// export const ToastTestButton = () => (
//     <button
//         onClick={(e) => {
//             e.preventDefault();
//             showItemWishListReminderToast(85391643, 3);
//             showItemOutbiddedToast(85391639, 99);
//             showItemFirstBidToast(85391639);
//             showItemSoldToast(85391639, 1234);
//             showItemWonToast(85391639, 1234);
//             showItemPaidToast(85391639);
//         }}>
//         Test
//     </button>
// );
