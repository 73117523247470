/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActivesummaryRequest {
    request?: MemberActiveItemsSummaryApiRequest;
}

export interface AliasSuggestionsCreateParams {
    request?: GetAliasSuggestionsApiRequest;
}

export interface AnswerRefundOfferCreateParams {
    request?: AnswerRefundOfferApiRequest;
}

export interface AutoShipmentCheckoutEnabledUpdateParams {
    request?: SetAutoShipmentCheckoutMemberEnabledApiRequest;
}

export interface AutoshipmentRequest {
    request?: GetMemberHasAutoShipmentCheckoutApiRequest;
}

export interface BankIdPasswordRecoveryCancelCreateParams {
    request?: BankIdPasswordRecoveryCancelApiRequest;
}

export interface BankIdProgressWithIdRequest {
    bankIdOrderRef?: string;
    /** @format int32 */
    memberid?: number;
}

export interface BankIdRecoveryWithIdCreateParams {
    request?: BankIdBeginWithIdApiRequest;
}

export interface BankaccountCreateParams {
    request?: SetBankAccountPayoutTransferMethodApiRequest;
}

export interface BankaccountDeleteParams {
    request?: RemoveBankAccountPayoutTransferMethodApiRequest;
}

export interface BankidVerificationRequest {
    /** @format int32 */
    fixedPrice?: number;
}

export interface BlockedMembersCreateParams {
    request?: BlockMemberApiRequest;
}

export interface BlockedMembersDeleteParams {
    request?: UnblockMemberApiRequest;
}

export interface BlockedMembersRequest {
    request?: GetBlockedMembersApiRequest;
}

export interface BlueshiftCredentialsRequest {
    request?: GetBlueshiftEventApiCredentialsApiRequest;
}

export interface BraintreeOnboardingDetailsRequest {
    request?: GetBraintreeOnboardingDetailsApiRequest;
}

export interface BuyerConfirmationCreateParams {
    request?: AutoPayoutReviewBuyerConfirmationApiRequest;
}

export interface BuyerConfirmationRequest {
    /** @format int32 */
    payoutReviewId?: number;
}

export interface BuyerCreateParams {
    request?: SaveFeedbackAsBuyerApiRequest;
}

export interface BuyerRequest {
    /** @format int32 */
    transactionId?: number;
}

export interface BuyerTransactionVisibilityCreateParams {
    request?: SetBuyerTransactionVisibilityApiRequest;
}

export interface ChangeStatusToAppealedCreateParams {
    request?: ChangeDisputeStatusToAppealedApiRequest;
}

export interface ChangealiasCreateParams {
    request?: ChangeAliasApiRequest;
}

export interface ChangeemailCreateParams {
    request?: ChangeEmailWithIdApiRequest;
    memberId: string;
}

export interface ChangepasswordCreate2Params {
    request?: ChangePasswordWithIdApiRequest;
    memberId: string;
}

export interface ChangepasswordCreateParams {
    request?: ChangePasswordApiRequest;
}

export interface CombinedShippingDaysUpdateParams {
    request?: SetCombinedShippingMemberDaysApiRequest;
}

export interface CombinedShippingEnabledUpdateParams {
    request?: SetCombinedShippingMemberEnabledApiRequest;
}

export interface CommentCreateParams {
    request?: CommentFeedbackApiRequest;
}

export interface CommitonpaymentEnabledUpdateParams {
    request?: SetCommitOnPaymentMemberEnabledApiRequest;
}

export interface CompanyContactSsnRequest {
    request?: MemberHasCompanyContactSsnApiRequest;
}

export interface CompanyContactSsnUpdateParams {
    request?: UpdateMemberCompanyContactSsnApiRequest;
}

export interface CompanyDac7StatusRequest {
    request?: GetMemberCompanyDac7StatusApiRequest;
}

export interface CompanyMoneyTransferLicenseStatusRequest {
    request?: GetMemberCompanyMoneyTransferLicenseStatusApiRequest;
}

export interface CompleteQuestionnaireCreateParams {
    request?: CompleteQuestionnaireApiRequest;
}

export interface CompletehyperwallettransfermethodCreateParams {
    request?: CompleteHyperwalletTransferMethodApiRequest;
}

export interface ConfirmCreateParams {
    request?: ConfirmMutualFeedbackRemovalApiRequest;
}

export interface ConfirmationValidationRequest {
    /** @format int32 */
    id?: number;
}

export interface ConsentCreateParams {
    request?: SetMemberConsentApiRequest;
}

export interface ContactSellerCreateParams {
    request?: ContactSellerApiRequest;
}

export interface CreateCreateParams {
    request?: CreateFeedbackRemovalApiRequest;
}

export interface CreateVerificationCodeCreateParams {
    request?: CreateVerificationCodeApiRequest;
}

export interface CreateVerificationCodeWithMemberIdCreateParams {
    request?: CreateVerificationCodeWithMemberIdApiRequest;
}

export interface CreationValidationRequest {
    /** @format int32 */
    transactionId?: number;
}

export interface CurrencyUpdateParams {
    request?: UpdateMemberCurrencyApiRequest;
}

export interface DeactivateaccountCreateParams {
    request?: DeactivateMemberAccountPermanentlyApiRequest;
}

export interface DeactivateaccountRequest {
    request?: GetAccountDeactivationSettingsApiRequest;
}

export interface DeactivateaccounttemporarilyCreateParams {
    request?: DeactivateMemberAccountTemporarilyApiRequest;
}

export interface DenyCreateParams {
    request?: DenyMutualFeedbackRemovalApiRequest;
}

export interface DetailsRequest {
    request?: MemberContactDetailsApiRequest;
}

export interface DetailsUpdateParams {
    request?: UpdateMemberContactDetailsApiRequest;
}

export interface DisputeCreateParams {
    request?: CreateDisputeApiRequest;
}

export interface DisputeRequest {
    /** @format int32 */
    transactionId?: number;
}

export interface DisputeMessageCreateParams {
    request?: AddDisputeMessageApiRequest;
}

export interface DisputesRequest {
    transactionIds?: number[];
}

export interface EmailSettingsRequest {
    request?: MemberEmailSettingsApiRequest;
}

export interface EmailSettingsUpdateParams {
    request?: UpdateMemberEmailSettingsApiRequest;
}

export interface EmailnotifyRequest {
    /** @format int32 */
    memberId?: number;
}

export interface EmailnotifyUpdateParams {
    request?: SetMessagingEmailNotifyApiRequest;
}

export interface FeatureSummaryByIdsRequest {
    itemIds?: number[];
}

export interface FeedbackRequest {
    /** @format int32 */
    memberId?: number;
    feedbackRoleFilter?: FeedbackRoleFilter;
    feedbackRatingFilter?: FeedbackRatingFilter;
    /** @format int32 */
    skip?: number;
    /** @format int32 */
    take?: number;
}

export interface FeedbackRemovalDetailsRequest {
    /** @format int32 */
    feedbackRemovalRequestId?: number;
}

export interface FeedbackRemovalsCreateParams {
    request?: FeedbackRemovalsApiRequest;
}

export interface FeedbacksRequest {
    /** @format int32 */
    transactionId?: number;
    showDeleted?: boolean;
}

export interface FindRequest {
    query?: string;
    /** @format int32 */
    page?: number;
    /** @format int32 */
    pageSize?: number;
}

export interface GetGeoParams {
    request?: GetMemberGeoDataApiRequest;
}

export interface GetSsnParams {
    request?: MemberHasSsnApiRequest;
}

export interface GetWalletProfileStatusRequest {
    request?: GetMemberRiskProfileStatusApiRequest;
}

export interface GetavailablecountriesforshippingRequest {
    request?: GetAvailableCountriesForShippingApiRequest;
}

export interface GetcreditmemobyencryptedidRequest {
    encryptedId?: string;
}

export interface GetcreditmemopdfRequest {
    /** @format int64 */
    invoiceId?: number;
    creditMemoDocumentId?: string;
}

export interface GetcreditmemopdfbyidRequest {
    /** @format int64 */
    creditMemoId?: number;
}

export interface GethyperwalletmemberdetailsRequest {
    request?: GetHyperwalletMemberDetailsApiRequest;
}

export interface GetinvoicecsvRequest {
    /** @format int64 */
    invoiceId?: number;
}

export interface GetinvoicepdfRequest {
    /** @format int64 */
    invoiceId?: number;
}

export interface GetinvoicepdfbyencryptedidRequest {
    encryptedId?: string;
}

export interface GetinvoicesRequest {
    /** @format int32 */
    pageIndex?: number;
    /** @format int32 */
    itemsPerPage?: number;
}

export interface GetiscombinedshippingenabledRequest {
    request?: GetIsCombinedShippingEnabledApiRequest;
}

export interface GetistraderapayenabledRequest {
    request?: GetIsTraderaPayEnabledApiRequest;
}

export interface GetshippinginvoicememberRequest {
    request?: GetShippingInvoiceMemberApiRequest;
}

export interface GettraderapayonboardingdetailsRequest {
    request?: GetTraderaPayOnboardingDetailsApiRequest;
}

export interface GradeTransactionsRequest {
    gradeIds?: number[];
}

export interface HasTinRequest {
    request?: GetMemberHasTaxIdentificationNumberApiRequest;
}

export interface HyperwalletauthenticationtokenRequest {
    request?: GetHyperwalletAuthenticationTokenApiRequest;
}

export interface HyperwallettransfermethodviewmodelRequest {
    request?: GetHyperwalletTransferMethodViewModelApiRequest;
}

export interface ImageUploadUrlRequest {
    contentType?: string;
}

export interface InitializeQuestionnaireCreateParams {
    request?: InitializeQuestionnaireApiRequest;
}

export interface InitializeWalletProfileUpdateCreateParams {
    request?: UpdateRiskProfileApiRequest;
}

export interface ItemIdsRequest {
    request?: GetWishListItemIdsApiRequest;
}

export interface ItemShippedCreateParams {
    request?: SetItemShippedApiRequest;
}

export interface ItemShippingBookedCreateParams {
    request?: SetItemShippingBookedApiRequest;
}

export interface ItemVisibilityCreateParams {
    request?: SetItemVisibilityApiRequest;
}

export interface ItemsRequest {
    /** @format int32 */
    memberId?: number;
    role?: FeedbackRoleFilter;
    rating?: FeedbackRatingFilter;
    /** @format int32 */
    skip?: number;
    /** @format int32 */
    take?: number;
}

export interface LanguageUpdateParams {
    request?: UpdateMemberLanguageApiRequest;
}

export interface LastnotifiedCreateParams {
    request?: SetLastNotifiedApiRequest;
}

export interface ListingFeeRequest {
    request?: MemberListingFeeApiRequest;
}

export interface MakeAnOfferEnabledUpdateParams {
    request?: SetMakeAnOfferMemberEnabledApiRequest;
}

export interface MarkAsEscalatedCreateParams {
    request?: MarkDisputeAsEscalatedApiRequest;
}

export interface MarkAsSolvedCreateParams {
    request?: MarkDisputeAsSolvedApiRequest;
}

export interface MarkedAsPaidBySellerCreateParams {
    request?: MarkAsPaidBySellerApiRequest;
}

export interface MatchbankCreateParams {
    request?: MatchBankApiRequest;
}

export interface McpStrikeConfirmCreateParams {
    request?: ConfirmMutualCancelOfPurchaseStrikeApiRequest;
}

export interface McpStrikeCreateParams {
    request?: AddMutualCancelOfPurchaseStrikeApiRequest;
}

export interface McpStrikeRequest {
    /** @format uuid */
    purchaseOrderId?: string;
}

export interface MemberLatestPublishedListingRequest {
    request?: MemberLatestPublishedListingApiRequest;
}

export interface MemberLocalizationRequest {
    request?: GetMemberLocalizationApiRequest;
}

export interface MemberconsentRequest {
    request?: GetMemberConsentApiRequest;
}

export interface MemberemailnotificationssettingsviewmodelRequest {
    request?: GetMemberEmailNotificationsSettingsViewModelApiRequest;
}

export interface MemberprofilesettingsviewmodelRequest {
    request?: GetMemberProfileSettingsViewModelApiRequest;
}

export interface MembersecuritysettingsviewmodelRequest {
    request?: GetMemberSecuritySettingsViewModelApiRequest;
}

export interface MembersellersettingsviewmodelRequest {
    request?: GetMemberSellerSettingsViewModelApiRequest;
}

export interface MessagingConfigurationRequest {
    request?: GetMessagingConfigurationApiRequest;
}

export interface MoneyTransferLicenseAgreementRequest {
    /** @format date-time */
    asOf?: string;
}

export interface NewDisputeItemSelectorPageRequest {
    /** @format uuid */
    purchaseOrderId?: string;
}

export interface NewDisputePageRequest {
    /** @format uuid */
    purchaseOrderId?: string;
    /** @deprecated */
    itemIds?: number[];
    transactionIds?: number[];
}

export interface NotificationsRequest {
    /** @format int32 */
    take?: number;
    /** @format int32 */
    skip?: number;
}

export interface OpenSupportThreadCreateParams {
    request?: CreateDisputeSupportMessagingThreadApiRequest;
}

export interface PagedTransactionsRequest {
    /** @format int32 */
    skip?: number;
    /** @format int32 */
    take?: number;
}

export interface PaymentproviderDeleteParams {
    request?: RemovePayoutTransferMethodApiRequest;
}

export interface PayoutfrequencyRequest {
    request?: GetPayoutFrequencyApiRequest;
}

export interface PayoutfrequencyoptionsRequest {
    request?: GetPayoutFrequencyOptionsApiRequest;
}

export interface PayoutsettingsmodalviewmodelRequest {
    tinkRedirectPath?: string;
}

export interface PayoutsettingsviewmodelRequest {
    request?: GetPayoutSettingsViewModelApiRequest;
}

export interface PaypalCreateParams {
    request?: SetPayPalPayoutTransferMethodApiRequest;
}

export interface PaypalDeleteParams {
    request?: RemovePayPalPayoutTransferMethodApiRequest;
}

export interface PendingActionsRequest {
    request?: GetKycPendingActionsApiRequest;
}

export interface PicklistRequest {
    /** @format int32 */
    pageIndex?: number;
    /** @format int32 */
    itemsPerPage?: number;
    retrieveHidden?: boolean;
    paidStatus?: PickListFilterPaidStatus;
    shippedStatus?: PickListFilterShippedStatus;
    /** @format date-time */
    from?: string;
    /** @format date-time */
    to?: string;
}

export interface PicklistV2Request {
    /** @format int32 */
    skip?: number;
    /** @format int32 */
    take?: number;
    retrieveHidden?: boolean;
    paidStatus?: PickListFilterPaidStatus;
    shippedStatus?: PickListFilterShippedStatus;
    /** @format date-time */
    fromDate?: string;
    /** @format date-time */
    toDate?: string;
}

export interface PonrStrikeCreateParams {
    request?: AddPurchaseOrderNotReceivedStrikeApiRequest;
}

export interface PonrStrikeRequest {
    /** @format uuid */
    purchaseOrderId?: string;
}

export interface ProfileHomepageThemeRequest {
    request?: GetHomepageThemeSettingApiRequest;
}

export interface ProfileHomepageThemeUpdateParams {
    request?: UpdateHomepageThemeApiRequest;
}

export interface ProfilePageRequest {
    /** @format int32 */
    memberId?: number;
    languageCodeIso2?: string;
}

export interface PublicProfileHeroImageDeleteParams {
    request?: RemoveMemberPublicProfileHeroImageApiRequest;
}

export interface PublicProfileHeroImageUpdateParams {
    request?: UpdateMemberPublicProfileHeroImageApiRequest;
}

export interface PublicProfileInformationUpdateParams {
    request?: UpdateMemberPublicProfileInformationApiRequest;
}

export interface PublicprofileRequest {
    /** @format int32 */
    memberId?: number;
    toLanguageCodeIso2?: string;
}

export interface PurchaseRequest {
    /** @format int32 */
    transactionId?: number;
}

export interface PurchaseOrderStrikeCreateParams {
    request?: AddPurchaseOrderStrikeApiRequest;
}

export interface PurchaseOrderStrikesRequest {
    /** @format uuid */
    purchaseOrderId?: string;
}

export interface PurchaseorderRequest {
    /** @format uuid */
    purchaseOrderId?: string;
}

export interface PurchaseorderitemRequest {
    /** @format uuid */
    purchaseOrderId?: string;
}

export interface PutSsnParams {
    request?: UpdateMemberSsnApiRequest;
}

export interface PutTinParams {
    request?: UpdateMemberTaxIdentificationNumberApiRequest;
}

export interface QuestionnaireRequest {
    actionType?: string;
    languageCodeIso2?: string;
}

export interface ReactivateAccountCreateParams {
    request?: ReactivateMemberAccountApiRequest;
}

export interface ReferralCodeCreateParams {
    request?: GenerateMemberReferralCodeApiRequest;
}

export interface RemovetraderapaypayoutdestinationCreateParams {
    request?: RemoveTraderaPayPayoutDestinationApiRequest;
}

export interface ResendverificationCreateParams {
    request?: ResendPayoutTransferMethodVerificationCodeApiRequest;
}

export interface RestartItemsSummaryRequest {
    itemIds?: number[];
}

export interface SelectShippingCreateParams {
    request?: SelectAutoPayoutReviewAlternativeShipmentApiRequest;
}

export interface SelectedDescriptionLanguageUpdateParams {
    request?: UpdateMemberSelectedDescriptionLanguageApiRequest;
}

export interface SellerAutoFeedbackRequest {
    request?: SellerAutoFeedbackApiRequest;
}

export interface SellerAutoFeedbackUpdateParams {
    request?: SaveSellerAutoFeedbackSettingsApiRequest;
}

export interface SellerAutoPaymentReminderRequest {
    request?: SellerAutoPaymentReminderApiRequest;
}

export interface SellerAutoPaymentReminderUpdateParams {
    request?: SaveSellerAutoPaymentReminderSettingsApiRequest;
}

export interface SellerCreateParams {
    request?: SaveFeedbackAsSellerApiRequest;
}

export interface SellerRequest {
    /** @format int32 */
    transactionId?: number;
}

export interface SellerMagicalSyiEnabledRequest {
    request?: GetMagicalSyiEnabledApiRequest;
}

export interface SellerMagicalSyiEnabledUpdateParams {
    request?: SetMagicalSyiEnabledApiRequest;
}

export interface SelloprofileRequest {
    request?: MemberSelloProfileApiRequest;
}

export interface SendGiftCardMailCreateParams {
    request?: SendGiftCardPackMailApiRequest;
    purchaseOrderId: string;
}

export interface ServerStorageRequest {
    request?: MemberServerStorageListApiRequest;
}

export interface ServerStorageUpdateParams {
    request?: MemberServerStoragePutApiRequest;
}

export interface SessionDataRequest {
    request?: MemberSessionDataApiRequest;
}

export interface SethyperwalletpayeeverificationassubmittedCreateParams {
    request?: SetHyperwalletPayeeVerificationAsSubmittedApiRequest;
}

export interface SetpayoutfrequencyCreateParams {
    request?: SetPayoutFrequencyApiRequest;
}

export interface ShippingFeeRuleFreeShippingCostLimitUpdateParams {
    request?: SetShippingFeeRuleFreeShippingCostLimitApiRequest;
}

export interface ShippingFeeRuleShippingOptionUpdateParams {
    request?: SetShippingFeeRuleShippingOptionApiRequest;
}

export interface ShippingFromCountryUpdateParams {
    request?: UpdateMemberShippingFromCountryApiRequest;
}

export interface ShippingSelectorRequest {
    /** @format int32 */
    payoutReviewId?: number;
}

export interface ShopDetailsRequest {
    /** @format int32 */
    memberId?: number;
}

export interface ShopitemOrderhistoryRequest {
    /** @format int32 */
    itemId?: number;
}

export interface ShouldCollectTinRequest {
    request?: GetMemberShouldCollectTaxIdentificationNumberApiRequest;
}

export interface SilentRequest {
    request?: GetMessagingSilentApiRequest;
}

export interface SilentUpdateParams {
    request?: SetMessagingSilentApiRequest;
}

export interface SmsCheckCreateParams {
    request?: SmsCheckApiRequest;
}

export interface SmsVerificationCreateParams {
    request?: SendVerificationSmsApiRequest;
}

export interface SmsVerificationRequest {
    action?: string;
}

export interface SmsVerificationWithIdCreateParams {
    request?: SendVerificationSmsWithIdApiRequest;
}

export interface SolditemsRequest {
    soldItemStateFilter?: string;
    soldItemOrderBy?: string;
    retrieveHidden?: boolean;
    /** @format int32 */
    take?: number;
    /** @format int32 */
    skip?: number;
}

export interface SoldsummaryRequest {
    request?: MemberSoldItemsSummaryApiRequest;
}

export interface StatsRequest {
    request?: GetMessagingStatsApiRequest;
}

export interface StatsThisMonthRequest {
    request?: MemberStatsThisMonthApiRequest;
}

export interface SummaryRequest {
    /** @format int32 */
    memberId?: number;
}

export interface SuspendedUntilRequest {
    request?: GetMemberSuspendedUntilApiRequest;
}

export interface SwishCreateParams {
    request?: SetSwishPayoutTransferMethodApiRequest;
}

export interface SystemMessagesRequest {
    request?: GetSystemMessagesApiRequest;
}

export interface TinImageUploadUrlCreateParams {
    request?: CreateTaxIdentificationImageUploadUrlApiRequest;
}

export interface TinkBankaccountsRequest {
    code?: string;
}

export interface TinkaccountCreateParams {
    request?: SetPayoutTransferMethodUsingTinkApiRequest;
}

export interface TrackExecutedSearchCreateParams {
    request?: TrackExecutedSearchApiRequest;
}

export interface ResponseError {
    errorCode?: string | null;
    fieldName?: string | null;
    message?: string | null;
}

export interface ResponseError3 {
    request?: WebChangeEmailWithIdApiRequest;
}

export interface ResponseError6 {
    transactionIds?: number[];
}

export interface ResponseError7 {
    /** @format int32 */
    skip?: number;
    /** @format int32 */
    take?: number;
}

export interface ResponseError8 {
    request?: SellerSetItemVisibilityApiRequest;
}

export interface ResponseStatus {
    errorCode?: string | null;
    errors?: ResponseError[] | null;
    message?: string | null;
    stackTrace?: string | null;
}

export interface BankIdAuthenticationBeginApiResponse {
    autoStartToken?: string | null;
    bankIdOrderRef?: string | null;
    qrData?: string | null;
    responseStatus?: ResponseStatus | null;
}

export interface BankIdAuthenticationCancelApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface BankIdAuthenticationProgressApiResponse {
    isCompleted: boolean;
    progress?: string | null;
    qrData?: string | null;
    responseStatus?: ResponseStatus | null;
}

export interface ItemImageUrls {
    medium?: string | null;
    normal?: string | null;
}

export enum ItemTransactionTypeApiDto {
    Auction = 'Auction',
    Bin = 'Bin',
    Cart = 'Cart',
    PurchaseOfferOnActive = 'PurchaseOfferOnActive',
    PurchaseOfferOnEnded = 'PurchaseOfferOnEnded'
}

export enum ItemTypeApiDto {
    Auction = 'Auction',
    AuctionBin = 'AuctionBin',
    PureBin = 'PureBin',
    ShopItem = 'ShopItem'
}

export interface AutoPayoutReviewBuyerConfirmationApiRequest {
    confirmationType?: string | null;
    /** @format int32 */
    payoutReviewId: number;
}

export interface AutoPayoutReviewBuyerConfirmationApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface AutoPayoutReview {
    /** @format double */
    amount: number;
    /** @format date-time */
    autoPayoutReviewCreated: string;
    autoPayoutReviewStatus?: string | null;
    /** @format date-time */
    autoPayoutReviewStatusUpdated: string;
    /** @format date-time */
    autoPayoutReviewUpdated: string;
    /** @format int32 */
    buyerMemberId: number;
    /** @format date-time */
    created: string;
    /** @format int32 */
    disputeId?: number | null;
    endingReason?: string | null;
    orderHasMultipleItems: boolean;
    orderImageUrl?: string | null;
    /** @format int32 */
    payoutReviewId: number;
    /** @format int32 */
    payoutReviewStatus: number;
    /** @format uuid */
    purchaseOrderId: string;
    /** @format int32 */
    sellerMemberId: number;
    /** @format date-time */
    statusChanged: string;
    statusChangedBy?: string | null;
}

export interface GetAutoPayoutReviewBuyerConfirmationApiResponse {
    autoPayoutReview?: AutoPayoutReview | null;
    buyerProtectionState?: string | null;
    responseStatus?: ResponseStatus | null;
}

export type GetMemberRiskProfileStatusApiRequest = object;

export interface GetMemberRiskProfileStatusApiResponse {
    isProfileComplete: boolean;
    responseStatus?: ResponseStatus | null;
}

export type UpdateRiskProfileApiRequest = object;

export interface UpdateRiskProfileApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface BlockMemberApiRequest {
    aliasToBlock?: string | null;
}

export interface BlockMemberApiResponse {
    /** @format int32 */
    blockedMemberId: number;
    responseStatus?: ResponseStatus | null;
}

export interface AddDisputeMessageApiRequest {
    attachments?: string[] | null;
    message?: string | null;
    /** @format int32 */
    transactionId: number;
}

export interface AddDisputeMessageApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface ChangeDisputeStatusToAppealedApiRequest {
    /** @format int32 */
    transactionId: number;
}

export interface ChangeDisputeStatusToAppealedApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface CreateDisputeApiRequest {
    message?: string | null;
    /** @format int32 */
    reason: number;
    /** @format int32 */
    transactionId: number;
}

export interface CreateDisputeApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface GetDisputeApiResponse {
    dispute?: DisputeApiDto | null;
    responseStatus?: ResponseStatus | null;
}

export interface GetDisputesApiResponse {
    disputes?: DisputeApiDto[] | null;
    responseStatus?: ResponseStatus | null;
}

export interface MarkDisputeAsEscalatedApiRequest {
    /** @format int32 */
    transactionId: number;
}

export interface MarkDisputeAsEscalatedApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface MarkDisputeAsSolvedApiRequest {
    /** @format int32 */
    transactionId: number;
}

export interface MarkDisputeAsSolvedApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface AnswerRefundOfferApiRequest {
    accepted: boolean;
    /** @format int32 */
    disputeId: number;
}

export interface AnswerRefundOfferApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface CreateDisputeFileUploadUrlApiRequest {
    contentType?: string | null;
}

export interface CreateDisputeFileUploadUrlApiResponse {
    objectName?: string | null;
    responseStatus?: ResponseStatus | null;
    uploadUrl?: string | null;
}

export interface CreateDisputeSupportMessagingThreadApiRequest {
    /** @format int32 */
    disputeId: number;
}

export interface CreateDisputeSupportMessagingThreadApiResponse {
    responseStatus?: ResponseStatus | null;
    threadId?: string | null;
}

export interface DisputeV2File {
    contentType?: string | null;
    fileName?: string | null;
    objectName?: string | null;
}

export interface DisputeV2FormData {
    /** @format double */
    buyerSolutionAmount?: number | null;
    comment?: string | null;
    files?: DisputeV2File[] | null;
    itemIds?: number[] | null;
    /** @format uuid */
    purchaseOrderId: string;
    reason?: string | null;
    status?: string | null;
    suggestedSolution?: string | null;
    transactionIds?: number[] | null;
    type?: string | null;
}

export interface DisputeV2ItemData {
    imageUrl?: string | null;
    /** @format int32 */
    itemId: number;
    itemUrl?: string | null;
    /** @format double */
    price: number;
    title?: string | null;
    /** @format int32 */
    transactionId: number;
}

export interface DisputeV2MemberData {
    alias?: string | null;
    /** @format int32 */
    memberId: number;
}

export interface DisputeV2PurchaseOrderData {
    buyerMember?: DisputeV2MemberData | null;
    /** @format double */
    buyerProtectionCost: number;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    orderNumber: number;
    paymentMethodExchangeRate?: ExchangeRateApiDto | null;
    /** @format uuid */
    purchaseOrderId: string;
    /** @format double */
    selectedShippingCostAfterRefunds: number;
    sellerMember?: DisputeV2MemberData | null;
    shipping?: DisputeV2Shipping | null;
    /** @format double */
    totalOrderAmount: number;
}

export interface DisputeV2PurchaseOrderItemData {
    hasReportedDispute: boolean;
    imageUrl?: string | null;
    /** @format int32 */
    itemId: number;
    itemUrl?: string | null;
    /** @format double */
    price: number;
    title?: string | null;
    /** @format int32 */
    transactionId: number;
}

export interface DisputeV2Shipping {
    isSoldByTradera: boolean;
    /** @format uuid */
    shipmentId: string;
    /** @format double */
    shippingCost: number;
    shippingProvider?: string | null;
}

export interface EscalateDisputeV2ApiRequest {
    /** @format int32 */
    disputeId: number;
}

export interface EscalateDisputeV2ApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface GetDisputeDataV2ApiResponse {
    /** @format double */
    buyerSolutionAmount?: number | null;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    createdFromPayoutReviewId?: number | null;
    /** @format int32 */
    disputeId: number;
    formData?: DisputeV2FormData | null;
    isOngoingPayoutReview: boolean;
    items?: DisputeV2ItemData[] | null;
    purchaseOrder?: DisputeV2PurchaseOrderData | null;
    /** @format int32 */
    refundOfferAmount?: number | null;
    responseStatus?: ResponseStatus | null;
    /** @format date-time */
    updatedDate: string;
}

export interface MarkDisputeAsSolvedV2ApiRequest {
    /** @format int32 */
    disputeId: number;
}

export interface MarkDisputeAsSolvedV2ApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SaveDisputeDataV2ApiRequest {
    /** @format int32 */
    createdFromPayoutReviewId?: number | null;
    formData?: DisputeV2FormData | null;
}

export interface SaveDisputeDataV2ApiResponse {
    /** @format int32 */
    disputeId: number;
    responseStatus?: ResponseStatus | null;
}

export interface DisputeApiDto {
    /** @format int32 */
    buyerMemberId: number;
    isHandledByExternalPart: boolean;
    /** @format int32 */
    itemId: number;
    message?: string | null;
    messages?: DisputeMessageDto[] | null;
    /** @format int32 */
    sellerMemberId: number;
    status: DisputeStatus;
    /** @format date-time */
    timeStamp: string;
    /** @format int32 */
    transactionId: number;
}

export interface DisputeMessageDto {
    attachments?: string[] | null;
    /** @format date-time */
    created: string;
    /** @format uuid */
    id: string;
    sentBy: MessageSentBy;
    text?: string | null;
}

export enum ContactBuyerQuestionAnswer {
    BuyerAndSellerAreInAgreement = 'BuyerAndSellerAreInAgreement',
    BuyerIsNotResponding = 'BuyerIsNotResponding',
    BuyerRefusesPayment = 'BuyerRefusesPayment',
    SellerHasNotContactedBuyer = 'SellerHasNotContactedBuyer'
}

export enum DisputeStatus {
    New = 'New',
    WaitingForSeller = 'WaitingForSeller',
    TraderaIsInvestigating = 'TraderaIsInvestigating',
    ToBeClosed = 'ToBeClosed',
    WaitingForBuyer = 'WaitingForBuyer',
    BuyerWonDispute = 'BuyerWonDispute',
    SellerWonDispute = 'SellerWonDispute',
    WaitingForReturn = 'WaitingForReturn',
    Appealed = 'Appealed',
    AvslutadUnder120Kr = 'AvslutadUnder120Kr',
    NotEscalated = 'NotEscalated',
    BuyerMarkedAsSolved = 'BuyerMarkedAsSolved',
    AvailableForPickupTradera = 'AvailableForPickupTradera',
    AvailableForPickupSeller = 'AvailableForPickupSeller',
    WaitingForKlarnaOrPayPal = 'WaitingForKlarnaOrPayPal',
    TheOffice = 'TheOffice',
    Other = 'Other'
}

export enum MessageSentBy {
    CsAgent = 'CsAgent',
    Buyer = 'Buyer',
    Seller = 'Seller'
}

export enum PurchaseOrderCancellationItemActions {
    RestartItems = 'RestartItems',
    MakeItemsAvailableForPurchaseOffer = 'MakeItemsAvailableForPurchaseOffer',
    DoNothing = 'DoNothing'
}

export enum StrikeType {
    None = 'None',
    ItemNotReceived = 'ItemNotReceived',
    UnpaidItem = 'UnpaidItem',
    MutualCancelOfPurchase = 'MutualCancelOfPurchase',
    UnpaidItemAfterGracePeriod = 'UnpaidItemAfterGracePeriod'
}

export interface ItemStrikeSubmission {
    strikeSubmitted: boolean;
    /** @format int32 */
    transactionId: number;
}

export interface AddPurchaseOrderNotReceivedStrikeApiRequest {
    comment?: string | null;
    /** @format uuid */
    purchaseOrderId: string;
}

export interface AddPurchaseOrderNotReceivedStrikeApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface PurchaseOrderNotReceivedStrikeApiResponse {
    items?: ItemStrikeSubmission[] | null;
    responseStatus?: ResponseStatus | null;
    strikeSubmitted: boolean;
}

export interface AddMutualCancelOfPurchaseStrikeApiRequest {
    comment?: string | null;
    /** @format uuid */
    purchaseOrderId: string;
}

export interface AddMutualCancelOfPurchaseStrikeApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface ConfirmMutualCancelOfPurchaseStrikeApiRequest {
    approved: boolean;
    /** @format uuid */
    purchaseOrderId: string;
}

export interface ConfirmMutualCancelOfPurchaseStrikeApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface MutualCancelOfPurchaseStrikeApiResponse {
    items?: StrikeItem[] | null;
    /** @format uuid */
    purchaseOrderId: string;
    responseStatus?: ResponseStatus | null;
    strikeConfirmed: boolean;
    strikeSubmitted: boolean;
}

export interface StrikeItem {
    /** @format int32 */
    transactionId: number;
}

export interface AddPurchaseOrderStrikeApiRequest {
    comment?: string | null;
    contactBuyerQuestionAnswer: ContactBuyerQuestionAnswer;
    itemAction: PurchaseOrderCancellationItemActions;
    /** @format uuid */
    purchaseOrderId: string;
}

export interface AddPurchaseOrderStrikeApiResponse {
    itemAction: PurchaseOrderCancellationItemActions;
    responseStatus?: ResponseStatus | null;
}

export interface GetPurchaseOrderStrikeItemsApiResponse {
    responseStatus?: ResponseStatus | null;
    strikes?: Strike[] | null;
}

export interface Strike {
    strikeType: StrikeType;
    /** @format int32 */
    transactionId: number;
}

export interface AddUnpaidPurchaseOrderStrikeApiRequest {
    comment?: string | null;
    /** @format uuid */
    purchaseOrderId: string;
}

export interface AddUnpaidPurchaseOrderStrikeApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface UnpaidItemStrikeApiResponse {
    responseStatus?: ResponseStatus | null;
    strikeSubmitted: boolean;
}

export interface UnpaidPurchaseOrderStrikeApiResponse {
    items?: ItemStrikeSubmission[] | null;
    responseStatus?: ResponseStatus | null;
    strikeSubmitted: boolean;
}

export interface CommentFeedbackApiRequest {
    comment?: string | null;
    /** @format int32 */
    gradeId: number;
    /** @format int32 */
    memberId: number;
}

export interface CommentFeedbackApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface ContactSellerApiRequest {
    /** @format int32 */
    itemId: number;
    message?: string | null;
    sendCopytoSender: boolean;
}

export interface ContactSellerApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface Country {
    countryCodeIso2?: string | null;
    countryNameEng?: string | null;
    countryNameSwe?: string | null;
}

export interface BlockedMemberDto {
    alias?: string | null;
    /** @format date-time */
    createdDate: string;
    /** @format int32 */
    memberId: number;
}

export interface BookedShipping {
    hasProviderBookingFailed: boolean;
    hasShipmentLabel: boolean;
    hasShipmentReceipt: boolean;
    /** @format uuid */
    shipmentCheckoutId: string;
    /** @format uuid */
    shipmentId: string;
    shippingType?: string | null;
    trackingId?: string | null;
    trackingLink?: string | null;
}

export interface BuyerPaidDataDto {
    /** @format int32 */
    numberOfItems: number;
    /** @format uuid */
    purchaseOrderId: string;
    shipmentBookingType?: string | null;
}

export interface ContactDetailsApiDto {
    countryCodeIso2?: string | null;
    alias?: string | null;
    companyName?: string | null;
    country?: string | null;
    email?: string | null;
    firstName?: string | null;
    fullName?: string | null;
    isCompany: boolean;
    lastName?: string | null;
    /** @format int32 */
    memberId: number;
    organizationNumber?: string | null;
    phoneNumber?: string | null;
    streetName?: string | null;
    town?: string | null;
    zipCode?: string | null;
}

export interface CreditSafeLookupResult {
    firstName?: string | null;
    givenName?: string | null;
    lastName?: string | null;
    socialSecurityNumber?: string | null;
}

export interface CreditSafeNameVerificationResult {
    lookupResult?: CreditSafeLookupResult | null;
    status: CreditSafeNameVerificationStatus;
}

export enum CreditSafeNameVerificationStatus {
    Valid = 'Valid',
    Invalid = 'Invalid',
    SSNFail = 'SSNFail',
    NotFound = 'NotFound',
    Error = 'Error'
}

export interface DetailedSellerRatingApiDto {
    /** @format double */
    averageRating: number;
    category?: string | null;
    /** @format int32 */
    categoryCount: number;
}

export interface DetailedSellerRatingSummaryForMemberApiDto {
    communication?: DetailedSellerRatingApiDto | null;
    deliveryTime?: DetailedSellerRatingApiDto | null;
    /** @format double */
    detailedSellerRatingAverage: number;
    itemDescription?: DetailedSellerRatingApiDto | null;
    /** @format int32 */
    memberId: number;
    shippingCost?: DetailedSellerRatingApiDto | null;
}

export interface DiscountDto {
    /** @format double */
    amount: number;
    /** @format date-time */
    created: string;
    type?: string | null;
}

export interface ExchangeRateApiDto {
    currencyCode?: string | null;
    /** @format double */
    rate: number;
}

export interface FeatureSummaryApiDto {
    /** @format int32 */
    cost: number;
    /** @format int32 */
    count: number;
    name?: string | null;
}

export interface FeedbackItemApiDto {
    feedbackMemberCountryCodeIso2?: string | null;
    /** @format date-time */
    created: string;
    feedbackMemberAlias?: string | null;
    /** @format int32 */
    feedbackMemberId: number;
    feedbackMemberRole: FeedbackRoleApiDto;
    /** @format int32 */
    gradeId: number;
    /** @format int32 */
    itemCategoryId: number;
    /** @format date-time */
    itemEndDate: string;
    /** @format int32 */
    itemId: number;
    /** @format int32 */
    itemMaxBid: number;
    itemShortDescription?: string | null;
    mutualFeedbackRemovalItem?: MutualFeedbackRemovalItemApiDto | null;
    rating: FeedbackRatingApiDto;
    reply?: string | null;
    text?: string | null;
    /** @format int32 */
    totalRating: number;
}

export enum FeedbackRatingApiDto {
    None = 'None',
    Negative = 'Negative',
    Positive = 'Positive'
}

export enum FeedbackRatingFilter {
    All = 'All',
    Positive = 'Positive',
    Negative = 'Negative'
}

export interface FeedbackReminderDataDto {
    isSeller: boolean;
}

export enum FeedbackRoleApiDto {
    Buyer = 'Buyer',
    Seller = 'Seller'
}

export enum FeedbackRoleFilter {
    All = 'All',
    Seller = 'Seller',
    Buyer = 'Buyer',
    LeftBy = 'LeftBy'
}

export interface FeedbackSummaryItemApiDto {
    /** @format int32 */
    totalNegative: number;
    /** @format int32 */
    totalPositive: number;
}

export enum HomepageTheme {
    Default = 'Default',
    Fashion = 'Fashion'
}

export interface ItemTransactionApiDto {
    /** @format int32 */
    bidCount?: number | null;
    /** @format int32 */
    buyItNowPrice?: number | null;
    /** @format int32 */
    buyerMemberId: number;
    discounts?: DiscountDto[] | null;
    hasBuyerLeftFeedback: boolean;
    hasSellerLeftFeedback: boolean;
    hasTraderaAsMerchantOfRecord: boolean;
    imageUrl?: string | null;
    imageUrlTemplate?: string | null;
    isHidden: boolean;
    isPaid: boolean;
    isPaynovaPayment: boolean;
    /** @format int32 */
    itemCategoryId: number;
    /** @format int32 */
    itemId: number;
    /** @format int32 */
    itemPrice?: number | null;
    itemResellPublished: boolean;
    itemTitle?: string | null;
    itemTransactionType: ItemTransactionTypeApiDto;
    /** @format int32 */
    openingBid?: number | null;
    /** @format int32 */
    orderNumber: number;
    paidStatus?: string | null;
    /** @format uuid */
    purchaseOrderId: string;
    selectedShipping?: ShippingApiDto | null;
    sellerAlias?: string | null;
    /** @format int32 */
    sellerMemberId: number;
    /** @format int32 */
    sellerRating: number;
    trackingLink?: string | null;
    /** @format date-time */
    transactionDate: string;
    /** @format int32 */
    transactionId: number;
}

export enum MemberAccountState {
    None = 'None',
    Active = 'Active',
    TemporarySuspended = 'TemporarySuspended',
    PermanentlySuspended = 'PermanentlySuspended',
    Deregistered = 'Deregistered'
}

export interface MemberActiveItemsSummaryApiDto {
    /** @format int32 */
    auctionCount: number;
    /** @format int32 */
    bidCount: number;
    /** @format int32 */
    bidSum: number;
    /** @format int32 */
    buyItNowCount: number;
    /** @format int32 */
    itemCount: number;
    /** @format int32 */
    shopItemCount: number;
    /** @format int32 */
    sumOpeningBids: number;
}

export interface MemberContactDetailsApiDto {
    countryCodeIso2?: string | null;
    alias?: string | null;
    country?: string | null;
    email?: string | null;
    firstName?: string | null;
    fullName?: string | null;
    lastName?: string | null;
    /** @format int32 */
    memberId: number;
    phoneNumber?: string | null;
    ssn?: string | null;
    streetName?: string | null;
    town?: string | null;
    zipCode?: string | null;
}

export interface MemberLoginDetailDto {
    accountState: MemberAccountState;
    alias?: string | null;
    hasTraderMessage: boolean;
    heroImageUrl?: string | null;
    isCompanyUser: boolean;
    /** @format int32 */
    memberId: number;
    /** @format date-time */
    memberSince: string;
    /** @format int32 */
    totalRating: number;
}

export interface MemberPermissionsApiDto {
    apiRegistrationPermission?: PermissionApiDto | null;
    sellPermission?: PermissionApiDto | null;
}

export interface MemberSoldItemApiDto {
    /** @format int32 */
    bidCount?: number | null;
    bookedShipping?: BookedShipping | null;
    buyerContactDetails?: MemberContactDetailsApiDto | null;
    buyerMemberAlias?: string | null;
    /** @format int32 */
    buyerMemberId: number;
    /** @format int32 */
    buyerTotalRating: number;
    /** @format int32 */
    categoryId: number;
    discounts?: DiscountDto[] | null;
    /** @format int32 */
    fixedPrice?: number | null;
    hasBuyerLeftFeedback: boolean;
    hasSellerLeftFeedback: boolean;
    hiddenBySeller: boolean;
    isDiscountAfterPaymentSupported: boolean;
    isDiscountBeforePaymentSupported: boolean;
    isMarkedAsPaidByBuyer: boolean;
    isMarkedAsPaidBySeller: boolean;
    isMarkedAsShipped: boolean;
    /** @deprecated */
    isPaidWithIntegratedPaymentOption: boolean;
    isShippingBooked: boolean;
    /** @format int32 */
    itemId: number;
    itemTitle?: string | null;
    itemTransactionType: ItemTransactionTypeApiDto;
    /** @format int32 */
    leadingBid?: number | null;
    mainImage?: ItemImageUrls | null;
    mainImageTemplate?: string | null;
    offeredShippingOptions?: ShippingApiDto[] | null;
    /** @format int32 */
    openingBid?: number | null;
    paidStatus?: string | null;
    /** @deprecated */
    paymentProvider?: string | null;
    /** @format int32 */
    price: number;
    /** @format uuid */
    purchaseOrderId: string;
    /** @format int32 */
    reservedPrice?: number | null;
    selectedShipping?: ShippingApiDto | null;
    /** @deprecated */
    shippingDiscount?: ShippingDiscount | null;
    soldItemState?: string | null;
    /** @deprecated */
    trackingLink?: string | null;
    /** @format date-time */
    transactionDate: string;
    /** @format int32 */
    transactionId: number;
    userReference?: string | null;
}

export interface MemberSoldItemsSummaryApiDto {
    /** @format int32 */
    auctionCount: number;
    /** @format int32 */
    bidCount: number;
    /** @format int32 */
    bidSum: number;
    /** @format int32 */
    buyItNowCount: number;
    /** @format int32 */
    itemCount: number;
    /** @format int32 */
    shopItemCount: number;
    /** @format int32 */
    sumOpeningBids: number;
    /** @format int32 */
    totalPrice: number;
}

export interface MutualFeedbackRemovalItemApiDto {
    /** @format int32 */
    feedbackRemovalRequestId?: number | null;
    status: MutualFeedbackRemovalStatus;
    /** @deprecated */
    statusText?: string | null;
    /** @format int32 */
    transactionId: number;
}

export enum MutualFeedbackRemovalStatus {
    Available = 'Available',
    Unavailable = 'Unavailable',
    Requested = 'Requested',
    Received = 'Received',
    Denied = 'Denied'
}

export interface NotificationApiDto {
    /** @format int32 */
    amount: number;
    buyerPaidData?: BuyerPaidDataDto | null;
    feedbackReminderData?: FeedbackReminderDataDto | null;
    isRead: boolean;
    /** @format int32 */
    itemId: number;
    mainImage?: ItemImageUrls | null;
    mainImageTemplate?: string | null;
    /** @format int32 */
    memberId: number;
    purchaseOrderUnpaidData?: PurchaseOrderUnpaidDataDto | null;
    shipmentSentData?: ShipmentSentDataDto | null;
    /** @format date-time */
    timeStamp: string;
    title?: string | null;
    type?: string | null;
    wishListItemsEndingTodayData?: WishListItemsEndingTodayDataDto | null;
    wishlistItemPriceLoweredData?: WishlistItemPriceLoweredDataDto | null;
}

export interface OfferedShippingOption {
    /** @format int32 */
    cost: number;
    type?: string | null;
}

export interface PaymentOptionApiDto {
    displayName?: string | null;
    paymentType?: string | null;
}

export interface PermissionApiDto {
    isPermitted: boolean;
    requirementsNotMet?: RequirementTypeApi[] | null;
}

export interface PickListBuyerApiDto {
    buyerContactDetails?: MemberContactDetailsApiDto | null;
    buyerMemberAlias?: string | null;
    /** @format int32 */
    buyerMemberId: number;
    /** @format int32 */
    feedbackCount: number;
    items?: MemberSoldItemApiDto[] | null;
}

export interface PickListBuyerV2ApiDto {
    buyerContactDetails?: ContactDetailsApiDto | null;
    buyerMemberAlias?: string | null;
    /** @format int32 */
    buyerMemberId: number;
    items?: PickListItemApiDto[] | null;
}

export interface PickListItemApiDto {
    hasSellerLeftFeedback: boolean;
    hiddenBySeller: boolean;
    isMarkedAsPaidBySeller: boolean;
    isMarkedAsShipped: boolean;
    /** @format int32 */
    itemId: number;
    itemTitle?: string | null;
    itemUrl?: string | null;
    mainImage?: ItemImageUrls | null;
    mainImageTemplate?: string | null;
    offeredShippingOptions?: OfferedShippingOption[] | null;
    /** @format int32 */
    price: number;
    /** @format uuid */
    purchaseOrderId: string;
    selectedShipping?: SelectedShippingOption | null;
    temporaryShippingAddress?: Address | null;
    /** @format double */
    totalDiscountAmount: number;
    /** @format date-time */
    transactionDate: string;
    /** @format int32 */
    transactionId: number;
    userReference?: string | null;
}

export interface PublicProfileApiDto {
    alias?: string | null;
    city?: string | null;
    country?: string | null;
    /** @format double */
    detailedSellerRating?: number | null;
    hasShop: boolean;
    isCompanyUser: boolean;
    /** @format int32 */
    memberId: number;
    /** @format int32 */
    totalRating: number;
    zipCode?: string | null;
}

export interface PurchaseOrderUnpaidDataDto {
    /** @format int32 */
    numberOfItems: number;
    /** @format uuid */
    purchaseOrderId: string;
}

export enum RequirementTypeApi {
    Unknown = 'Unknown',
    IsOverMinimumAge = 'IsOverMinimumAge'
}

export interface SelectedShippingOption {
    /** @format int32 */
    cost: number;
    type?: string | null;
}

export enum SellerAutoFeedbackCriteria {
    Manual = 'Manual',
    PositiveFeedback = 'PositiveFeedback',
    Paid = 'Paid'
}

export interface ShipmentSentDataDto {
    /** @format int32 */
    numberOfItems: number;
    /** @format uuid */
    purchaseOrderId: string;
}

export interface ShippingApiDto {
    fromCountryCodeIso2?: string | null;
    toCountryCodeIso2?: string | null;
    /** @format double */
    cost: number;
    isFreeShipping: boolean;
    isTakeaway: boolean;
    name?: string | null;
    partnerProvider?: string | null;
    /** @format int32 */
    productId?: number | null;
    type?: string | null;
    /** @format int32 */
    weight?: number | null;
}

export interface SoldItemSummaryDto {
    /** @format int32 */
    itemId: number;
    itemTitle?: string | null;
    /** @format int32 */
    price: number;
    /** @format date-time */
    transactionDate: string;
    userReference?: string | null;
}

export interface TotalFeedbackSummaryForMemberApiDto {
    lastMonth?: FeedbackSummaryItemApiDto | null;
    lastSixMonths?: FeedbackSummaryItemApiDto | null;
    lastTwelveMonths?: FeedbackSummaryItemApiDto | null;
    /** @format int32 */
    memberId: number;
    sinceRegistration?: FeedbackSummaryItemApiDto | null;
    unique?: FeedbackSummaryItemApiDto | null;
}

export interface TranslatedFeedbackItemApiDto {
    /** @format int32 */
    gradeId: number;
    reply?: string | null;
    text?: string | null;
}

export interface WishListItemsEndingTodayDataDto {
    /** @format int32 */
    numberOfItems: number;
}

export enum WishListStatusFilter {
    All = 'All',
    Active = 'Active',
    Ended = 'Ended'
}

export interface WishlistItemPriceLoweredDataDto {
    currencyCode?: string | null;
    /** @format int32 */
    newPrice: number;
}

export interface GetNotificationsApiResponse {
    items?: NotificationApiDto[] | null;
    responseStatus?: ResponseStatus | null;
    /** @format int64 */
    totalCount: number;
    /** @format int64 */
    unreadCount: number;
}

export type GetUnreadNotificationsCountApiRequest = object;

export interface GetUnreadNotificationsCountApiResponse {
    responseStatus?: ResponseStatus | null;
    /** @format int64 */
    unreadCount: number;
}

export type SetLastNotifiedApiRequest = object;

export interface SetLastNotifiedApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface CreditMemoByEncryptedIdApiResponse {
    /** @format byte */
    pdfFileContent?: string | null;
    responseStatus?: ResponseStatus | null;
}

export interface CreditMemoDto {
    creditMemoDocumentId?: string | null;
    /** @format int64 */
    invoiceId: number;
}

export interface GetBraintreeInvoicePaymentApiResponse {
    /** @format double */
    amount: number;
    /** @format int64 */
    invoiceId: number;
    orderId?: string | null;
    responseStatus?: ResponseStatus | null;
    status?: string | null;
}

export interface GetCreditMemoPdfApiResponse {
    /** @format byte */
    pdfFileContent?: string | null;
    responseStatus?: ResponseStatus | null;
}

export interface GetInvoiceCsvApiResponse {
    /** @format byte */
    csvFileContent?: string | null;
    responseStatus?: ResponseStatus | null;
}

export interface GetInvoiceIsPaidApiResponse {
    isPaid: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface GetInvoicePdfApiResponse {
    /** @format byte */
    pdfFileContent?: string | null;
    responseStatus?: ResponseStatus | null;
}

export interface GetInvoicesApiResponse {
    invoices?: InvoiceDto[] | null;
    isBillingUnderMaintenance: boolean;
    responseStatus?: ResponseStatus | null;
    /** @format int64 */
    totalCount: number;
}

export interface InvoiceDto {
    /** @format double */
    amountOwed: number;
    creditMemos?: CreditMemoDto[] | null;
    csvGenerated: boolean;
    /** @format date-time */
    dueDate: string;
    /** @format double */
    invoiceAmount: number;
    /** @format date-time */
    invoiceDate: string;
    /** @format int64 */
    invoiceId: number;
    /** @format int64 */
    ocr: number;
    paymentStatus?: string | null;
}

export interface FindMembersApiResponse {
    members?: MemberLoginDetailDto[] | null;
    responseStatus?: ResponseStatus | null;
    /** @format int32 */
    totalCount: number;
}

export interface GetAutoPayoutReviewAlternativeShipmentApiResponse {
    payoutReview?: AutoPayoutReview | null;
    responseStatus?: ResponseStatus | null;
}

export interface SelectAutoPayoutReviewAlternativeShipmentApiRequest {
    alternativeShippingOption?: string | null;
    /** @format int32 */
    payoutReviewId: number;
    shippingReference?: string | null;
}

export interface SelectAutoPayoutReviewAlternativeShipmentApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface GetAliasSuggestionsApiRequest {
    alias?: string | null;
    emailAddress?: string | null;
    firstName?: string | null;
    lastName?: string | null;
}

export interface GetAliasSuggestionsApiResponse {
    responseStatus?: ResponseStatus | null;
    suggestions?: string[] | null;
}

export interface GetAllItemsForLeaveFeedbackAsBuyerApiResponse {
    items?: ItemTransactionApiDto[] | null;
    /** @format int64 */
    itemsLeft: number;
    /** @format int64 */
    paidTotalCount: number;
    responseStatus?: ResponseStatus | null;
    /** @format int64 */
    totalCount: number;
}

export interface GetAllItemsForLeaveFeedbackAsSellerApiResponse {
    items?: MemberSoldItemApiDto[] | null;
    /** @format int64 */
    itemsLeft: number;
    responseStatus?: ResponseStatus | null;
    /** @format int64 */
    totalCount: number;
}

export type GetAvailableCountriesForShippingApiRequest = object;

export interface GetAvailableCountriesForShippingApiResponse {
    availableCountries?: Country[] | null;
    responseStatus?: ResponseStatus | null;
}

export type GetBlockedMembersApiRequest = object;

export interface GetBlockedMembersApiResponse {
    blockedMembers?: BlockedMemberDto[] | null;
    responseStatus?: ResponseStatus | null;
}

export type GetBlueshiftEventApiCredentialsApiRequest = object;

export interface GetBlueshiftEventApiCredentialsApiResponse {
    eventApiKey?: string | null;
    eventSignature?: string | null;
    responseStatus?: ResponseStatus | null;
    verificationKey?: string | null;
}

export interface GetImageUploadUrlApiResponse {
    responseStatus?: ResponseStatus | null;
    signedUrl?: string | null;
}

export type GetIsCombinedShippingEnabledApiRequest = object;

export interface GetIsCombinedShippingEnabledApiResponse {
    isEnabled: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface GetItemsForLeaveFeedbackAsBuyerApiResponse {
    responseStatus?: ResponseStatus | null;
    transactions?: ItemTransactionApiDto[] | null;
}

export interface GetItemsForLeaveFeedbackAsSellerApiResponse {
    items?: MemberSoldItemApiDto[] | null;
    responseStatus?: ResponseStatus | null;
}

export type GetMemberGeoDataApiRequest = object;

export interface GetMemberGeoDataApiResponse {
    /** @format double */
    latitude: number;
    /** @format double */
    longitude: number;
    responseStatus?: ResponseStatus | null;
}

export type GetMemberSuspendedUntilApiRequest = object;

export interface GetMemberSuspendedUntilApiResponse {
    responseStatus?: ResponseStatus | null;
    /** @format date-time */
    suspendedUntil?: string | null;
}

export type GetMemberVatDetailsApiRequest = object;

export interface GetMemberVatDetailsApiResponse {
    memberIsSubjectToVat: boolean;
    responseStatus?: ResponseStatus | null;
    /** @format byte */
    selectableVatValues?: string | null;
}

export interface GetMemberWithAliasApiResponse {
    alias?: string | null;
    hasShopAccount: boolean;
    isCompanyUser: boolean;
    /** @format int32 */
    memberId: number;
    responseStatus?: ResponseStatus | null;
}

export type GetSystemMessagesApiRequest = object;

export interface GetSystemMessagesApiResponse {
    items?: SystemMessage[] | null;
    responseStatus?: ResponseStatus | null;
    /** @format int64 */
    totalCount: number;
}

export interface GetTransactionFeedbacksApiResponse {
    feedbacks?: TransactionFeedbackApiDto[] | null;
    responseStatus?: ResponseStatus | null;
}

export interface GradeTransactionsApiResponse {
    gradesTransactions?: Record<string, number>;
    responseStatus?: ResponseStatus | null;
}

export interface CompleteQuestionnaireApiRequest {
    languageCodeIso2?: string | null;
    fieldValues?: Record<string, string[] | null>;
    /** @format int32 */
    questionnaireId: number;
}

export interface CompleteQuestionnaireApiResponse {
    alias?: string | null;
    isValid: boolean;
    responseStatus?: ResponseStatus | null;
    ruleViolations?: Record<string, string | null>;
}

export type GetKycPendingActionsApiRequest = object;

export interface GetKycPendingActionsApiResponse {
    /** @format int32 */
    memberId: number;
    pendingActionActionTypes?: string[] | null;
    responseStatus?: ResponseStatus | null;
}

export interface GetKycQuestionnairePageDefinitionApiResponse {
    alias?: string | null;
    /** @format int32 */
    memberId: number;
    pageDefinition?: any;
    responseStatus?: ResponseStatus | null;
}

export interface InitializeQuestionnaireApiRequest {
    alias?: string | null;
}

export interface InitializeQuestionnaireApiResponse {
    /** @format int32 */
    questionnaireId: number;
    responseStatus?: ResponseStatus | null;
}

export interface LeaveFeedbackAsBuyerApiResponse {
    responseStatus?: ResponseStatus | null;
    transaction?: ItemTransactionApiDto | null;
}

export interface LeaveFeedbackAsSellerApiResponse {
    responseStatus?: ResponseStatus | null;
    transaction?: ItemTransactionApiDto | null;
}

export type MemberContactDetailsApiRequest = object;

export interface MemberContactDetailsApiResponse {
    countryCodeIso2?: string | null;
    alias?: string | null;
    country?: string | null;
    email?: string | null;
    firstName?: string | null;
    fullName?: string | null;
    lastName?: string | null;
    /** @format int32 */
    memberId: number;
    phoneNumber?: string | null;
    responseStatus?: ResponseStatus | null;
    ssn?: string | null;
    streetName?: string | null;
    town?: string | null;
    zipCode?: string | null;
}

export type MemberEmailSettingsApiRequest = object;

export interface MemberEmailSettingsApiResponse {
    acceptsNewsLetter: boolean;
    acceptsOfferEmails: boolean;
    acceptsUnknownDeviceOrIpEmails: boolean;
    acceptsUpiInfoEmails: boolean;
    /** @format int32 */
    memberId: number;
    notifyBeforeWishListItemEnds: boolean;
    notifyOnFirstWinningBid: boolean;
    notifyWhenItemBecomesUnsold: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface MemberFeedbackApiResponse {
    accountState: MemberAccountState;
    detailedSellerRatingSummaryForMember?: DetailedSellerRatingSummaryForMemberApiDto | null;
    items?: FeedbackItemApiDto[] | null;
    /** @format date-time */
    memberSince: string;
    responseStatus?: ResponseStatus | null;
    /** @format int64 */
    totalCount: number;
    totalFeedbackSummary?: TotalFeedbackSummaryForMemberApiDto | null;
}

export interface MemberFeedbackItemsApiResponse {
    items?: FeedbackItemApiDto[] | null;
    responseStatus?: ResponseStatus | null;
    /** @format int64 */
    totalCount: number;
}

export interface MemberFeedbackSummaryApiResponse {
    accountState: MemberAccountState;
    detailedSellerRatingSummary?: DetailedSellerRatingSummaryForMemberApiDto | null;
    /** @format date-time */
    memberSince: string;
    responseStatus?: ResponseStatus | null;
    totalFeedbackSummary?: TotalFeedbackSummaryForMemberApiDto | null;
}

export interface MemberPurchaseApiResponse {
    buyer?: MemberContactDetailsApiDto | null;
    item?: ItemTransactionApiDto | null;
    responseStatus?: ResponseStatus | null;
    seller?: MemberContactDetailsApiDto | null;
}

export interface MemberPurchaseOrderApiResponse {
    buyer?: ContactDetailsApiDto | null;
    isAutomaticSellerReimbursementAllowed: boolean;
    items?: ItemTransactionApiDto[] | null;
    paymentMethod?: string | null;
    responseStatus?: ResponseStatus | null;
    seller?: ContactDetailsApiDto | null;
}

export interface MemberPurchaseOrderItemApiResponse {
    items?: ItemTransactionApiDto[] | null;
    paymentMethod?: string | null;
    paymentMethodExchangeRate?: ExchangeRateApiDto | null;
    responseStatus?: ResponseStatus | null;
}

export type MemberSelloProfileApiRequest = object;

export interface MemberSelloProfileApiResponse {
    accountState: MemberAccountState;
    alias?: string | null;
    city?: string | null;
    /** @format int32 */
    combinedShippingDays: number;
    /** @format float */
    detailedSellerRatingAverage?: number | null;
    email?: string | null;
    firstName?: string | null;
    hasShop: boolean;
    isCompanyUser: boolean;
    isSwedishUser: boolean;
    /** @format int32 */
    memberId: number;
    /** @format date-time */
    memberSince: string;
    responseStatus?: ResponseStatus | null;
    /** @format int32 */
    totalRating: number;
}

export type MemberServerStorageListApiRequest = object;

export interface MemberServerStorageListApiResponse {
    responseStatus?: ResponseStatus | null;
    rows?: Row[] | null;
}

export interface MemberServerStoragePutApiRequest {
    key?: string | null;
    value?: string | null;
}

export interface MemberServerStoragePutApiResponse {
    responseStatus?: ResponseStatus | null;
}

export type MemberSessionDataApiRequest = object;

export interface MemberSessionDataApiResponse {
    countryCodeIso2?: string | null;
    languageCodeIso2?: string | null;
    /** @format int32 */
    age?: number | null;
    alias?: string | null;
    aliasSlug?: string | null;
    appleId?: string | null;
    automaticTranslationPreferred: boolean;
    birthDate?: string | null;
    blueshiftEventSignature?: string | null;
    city?: string | null;
    country?: string | null;
    currencyCode?: string | null;
    /** @format float */
    detailedSellerRating?: number | null;
    email?: string | null;
    facebookId?: string | null;
    firstName?: string | null;
    gender?: string | null;
    googleId?: string | null;
    hasTraderaPay: boolean;
    heroImageUrl?: string | null;
    isBuyer: boolean;
    isSeller: boolean;
    isTrustedMember: boolean;
    klarnaId?: string | null;
    lastName?: string | null;
    /** @format int32 */
    memberId: number;
    memberIdHash?: string | null;
    /** @format int32 */
    memberState: number;
    permissions?: MemberPermissionsApiDto | null;
    /** @format date-time */
    registrationDate: string;
    responseStatus?: ResponseStatus | null;
}

export interface MemberShopDetailsApiResponse {
    alias?: string | null;
    companyInfo?: string | null;
    contactInfo?: string | null;
    hasLogo: boolean;
    logoFormat?: string | null;
    /** @format int32 */
    memberId: number;
    purchaseTerms?: string | null;
    responseStatus?: ResponseStatus | null;
}

export interface FirebaseConfiguration {
    apiKey?: string | null;
    authDomain?: string | null;
    databaseUrl?: string | null;
    functionsEmulatorUrl?: string | null;
    projectId?: string | null;
    region?: string | null;
    storageBucket?: string | null;
}

export type GetMessagingConfigurationApiRequest = object;

export interface GetMessagingConfigurationApiResponse {
    firebaseConfiguration?: FirebaseConfiguration | null;
    responseStatus?: ResponseStatus | null;
}

export interface GetMessagingEmailNotifyApiResponse {
    emailNotify: MessagingEmailNotification;
    /** @format int32 */
    memberId: number;
    responseStatus?: ResponseStatus | null;
}

export interface GetMessagingItemApiResponse {
    /** @format int32 */
    price: number;
    responseStatus?: ResponseStatus | null;
}

export type GetMessagingSilentApiRequest = object;

export interface GetMessagingSilentApiResponse {
    enabled: boolean;
    responseStatus?: ResponseStatus | null;
}

export type GetMessagingStatsApiRequest = object;

export interface GetMessagingStatsApiResponse {
    responseStatus?: ResponseStatus | null;
    /** @format int32 */
    threads: number;
    /** @format int32 */
    threadsUnread: number;
}

export enum MessagingEmailNotification {
    All = 'All',
    Unread = 'Unread',
    None = 'None'
}

export interface SetMessagingEmailNotifyApiRequest {
    emailNotify: MessagingEmailNotification;
}

export interface SetMessagingEmailNotifyApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetMessagingSilentApiRequest {
    enabled: boolean;
}

export interface SetMessagingSilentApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface ConfirmMutualFeedbackRemovalApiRequest {
    /** @format int32 */
    feedbackRemovalRequestId: number;
}

export interface ConfirmMutualFeedbackRemovalApiResponse {
    responseStatus?: ResponseStatus | null;
}

export enum ConfirmationStatus {
    ConfirmationAllowed = 'ConfirmationAllowed',
    RequestIsOlderThen30Days = 'RequestIsOlderThen30Days',
    RequestedBySubmitter = 'RequestedBySubmitter'
}

export interface CreateFeedbackRemovalApiRequest {
    comment?: string | null;
    /** @format int32 */
    transactionId: number;
}

export interface CreateFeedbackRemovalApiResponse {
    responseStatus?: ResponseStatus | null;
}

export enum CreationStatus {
    CreationAllowed = 'CreationAllowed',
    CreatedBySubmitter = 'CreatedBySubmitter',
    CanBeAcceptedByReceiver = 'CanBeAcceptedByReceiver'
}

export interface DenyMutualFeedbackRemovalApiRequest {
    /** @format int32 */
    feedbackRemovalRequestId: number;
}

export interface DenyMututalFeedbackRemovalApiResponse {
    responseStatus?: ResponseStatus | null;
}

export enum FeedbackRemovalFlowStatus {
    CanBeStarted = 'CanBeStarted',
    CanBeAccepted = 'CanBeAccepted',
    SubmittedAlready = 'SubmittedAlready',
    NotApplicable = 'NotApplicable'
}

export enum FeedbackRemovalState {
    Pending = 'Pending',
    Accepted = 'Accepted',
    Rejected = 'Rejected'
}

export interface FlowStatusRequest {
    /** @format int32 */
    feedbackMemberId: number;
    /** @format int32 */
    transactionId: number;
}

export interface FlowStatusResponse {
    feedbacksRemovalRequest?: MutualFeedbackRemovalApiDto | null;
    flowStatus: FeedbackRemovalFlowStatus;
    /** @format int32 */
    transactionId: number;
}

export interface MemberDetails {
    alias?: string | null;
    /** @format int32 */
    id: number;
}

export interface MutualFeedbackRemovalApiDto {
    comment?: string | null;
    /** @format date-time */
    completed?: string | null;
    /** @format date-time */
    created: string;
    fromMember?: MemberDetails | null;
    /** @format int32 */
    id: number;
    isCompleted: boolean;
    /** @format int32 */
    itemId: number;
    status?: FeedbackRemovalState | null;
    toMember?: MemberDetails | null;
    /** @format int32 */
    transactionBuyerMemberId: number;
    /** @format int32 */
    transactionId: number;
    /** @format int32 */
    transactionSellerMemberId: number;
}

export interface TransactionFeedbackApiDto {
    /** @format date-time */
    created: string;
    fromMemberAlias?: string | null;
    /** @format int32 */
    fromMemberId: number;
    memberRole: TransactionFeedbackRole;
    rating: TransactionFeedbackRating;
    text?: string | null;
    toMemberAlias?: string | null;
    /** @format int32 */
    toMemberId: number;
}

export enum TransactionFeedbackRating {
    None = 'None',
    Negative = 'Negative',
    Positive = 'Positive'
}

export enum TransactionFeedbackRole {
    Buyer = 'Buyer',
    Seller = 'Seller'
}

export interface FeedbackRemovalApiResponse {
    feedbackRemovalRequest?: MutualFeedbackRemovalApiDto | null;
    feedbacks?: TransactionFeedbackApiDto[] | null;
    responseStatus?: ResponseStatus | null;
}

export interface FeedbackRemovalsApiRequest {
    feedbacks?: FlowStatusRequest[] | null;
}

export interface FeedbackRemovalsApiResponse {
    feedbacksRemovalRequests?: FlowStatusResponse[] | null;
    responseStatus?: ResponseStatus | null;
}

export interface ValidateConfirmationApiResponse {
    confirmationStatus: ConfirmationStatus;
    feedbackRemovalRequest?: MutualFeedbackRemovalApiDto | null;
    feedbacks?: TransactionFeedbackApiDto[] | null;
    responseStatus?: ResponseStatus | null;
}

export interface ValidateCreationApiResponse {
    creationStatus: CreationStatus;
    feedbackRemovalRequest?: MutualFeedbackRemovalApiDto | null;
    feedbacks?: TransactionFeedbackApiDto[] | null;
    responseStatus?: ResponseStatus | null;
}

export enum AvailablePayoutOption {
    SwedishBankAccount = 'SwedishBankAccount',
    InternationalBankAccount = 'InternationalBankAccount',
    PayPal = 'PayPal',
    Swish = 'Swish',
    Tink = 'Tink',
    Unknown = 'Unknown'
}

export interface CompleteHyperwalletTransferMethodApiRequest {
    transferMethodToken?: string | null;
    transferMethodType?: string | null;
}

export interface CompleteHyperwalletTransferMethodApiResponse {
    responseStatus?: ResponseStatus | null;
}

export type GetHyperwalletAuthenticationTokenApiRequest = object;

export interface GetHyperwalletAuthenticationTokenApiResponse {
    authenticationToken?: string | null;
    responseStatus?: ResponseStatus | null;
}

export type GetHyperwalletMemberDetailsApiRequest = object;

export interface GetHyperwalletMemberDetailsApiResponse {
    payeeVerificationStatus?: string | null;
    responseStatus?: ResponseStatus | null;
    userToken?: string | null;
}

export type GetHyperwalletTransferMethodViewModelApiRequest = object;

export interface GetHyperwalletTransferMethodViewModelApiResponse {
    responseStatus?: ResponseStatus | null;
    userToken?: string | null;
    widgetBaseUrl?: string | null;
}

export type GetIsTraderaPayEnabledApiRequest = object;

export interface GetIsTraderaPayEnabledApiResponse {
    isEnabled: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface GetPayoutSettingsModalViewModelApiResponse {
    availablePayoutOptions?: AvailablePayoutOption[] | null;
    hyperwallet?: PayoutSettingsModalHyperwallet | null;
    member?: PayoutSettingsModalMember | null;
    payoutFrequencyOption?: string | null;
    responseStatus?: ResponseStatus | null;
    tink?: PayoutSettingsModalTink | null;
}

export type GetPayoutSettingsViewModelApiRequest = object;

export interface GetPayoutSettingsViewModelApiResponse {
    hyperwalletOnboardingProblems?: HyperwalletOnboardingProblem[] | null;
    hyperwalletPayeeVerificationStatus?: string | null;
    payoutDestination?: string | null;
    payoutDestinationType?: string | null;
    payoutFrequencyOption?: string | null;
    payoutFrequencyOptions?: string[] | null;
    responseStatus?: ResponseStatus | null;
}

export interface GetPayoutTransferMethodUpdateStatusApiResponse {
    errorCode?: string | null;
    responseStatus?: ResponseStatus | null;
    status?: string | null;
}

export interface HyperwalletOnboardingProblem {
    details?: string | null;
    type?: string | null;
}

export interface MatchBankApiRequest {
    bankAccountNumber?: string | null;
    clearingNumber?: string | null;
}

export interface MatchBankApiResponse {
    bankFound: boolean;
    /** @deprecated */
    bankName?: string | null;
    ok: boolean;
    /** @deprecated */
    requireBankIdVerification: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface PayoutSettingsModalHyperwallet {
    userToken?: string | null;
    widgetBaseUrl?: string | null;
}

export interface PayoutSettingsModalMember {
    emailAddress?: string | null;
    isCompany: boolean;
    /** @format int32 */
    memberId: number;
    phoneNumber?: string | null;
    requireBankIdVerification: boolean;
}

export interface PayoutSettingsModalTink {
    tinkLinkUrl?: string | null;
}

export type RemoveBankAccountPayoutTransferMethodApiRequest = object;

export interface RemoveBankAccountPayoutTransferMethodApiResponse {
    responseStatus?: ResponseStatus | null;
}

export type RemovePayPalPayoutTransferMethodApiRequest = object;

export interface RemovePayPalPayoutTransferMethodApiResponse {
    responseStatus?: ResponseStatus | null;
}

export type RemovePayoutTransferMethodApiRequest = object;

export interface RemovePayoutTransferMethodApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface ResendPayoutTransferMethodVerificationCodeApiRequest {
    /** @format uuid */
    processCorrelationId: string;
    swishNumber?: string | null;
}

export interface ResendPayoutTransferMethodVerificationCodeApiResponse {
    errorCode?: string | null;
    responseStatus?: ResponseStatus | null;
}

export interface SetBankAccountPayoutTransferMethodApiRequest {
    bankAccountNumber?: string | null;
    bankIdOrderRef?: string | null;
    clearingNumber?: string | null;
}

export interface SetBankAccountPayoutTransferMethodApiResponse {
    errorCode?: string | null;
    /** @format uuid */
    processCorrelationId: string;
    responseStatus?: ResponseStatus | null;
}

export type SetHyperwalletPayeeVerificationAsSubmittedApiRequest = object;

export interface SetHyperwalletPayeeVerificationAsSubmittedApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetPayPalPayoutTransferMethodApiRequest {
    bankIdOrderRef?: string | null;
    emailAddress?: string | null;
}

export interface SetPayPalPayoutTransferMethodApiResponse {
    errorCode?: string | null;
    /** @format uuid */
    processCorrelationId: string;
    responseStatus?: ResponseStatus | null;
}

export interface SetPayoutTransferMethodUsingTinkApiRequest {
    accountNumber?: string | null;
    bankIdOrderRef?: string | null;
}

export interface SetPayoutTransferMethodUsingTinkApiResponse {
    errorCode?: string | null;
    /** @format uuid */
    processCorrelationId: string;
    responseStatus?: ResponseStatus | null;
}

export interface SetSwishPayoutTransferMethodApiRequest {
    bankIdOrderRef?: string | null;
    swishNumber?: string | null;
}

export interface SetSwishPayoutTransferMethodApiResponse {
    errorCode?: string | null;
    /** @format uuid */
    processCorrelationId: string;
    responseStatus?: ResponseStatus | null;
}

export interface VerifyPayoutTransferMethodApiRequest {
    /** @format uuid */
    processCorrelationId: string;
    verificationCode?: string | null;
}

export interface VerifyPayoutTransferMethodApiResponse {
    errorCode?: string | null;
    responseStatus?: ResponseStatus | null;
}

export interface BankIdBeginWithIdApiRequest {
    /** @format int32 */
    memberId: number;
}

export interface BankIdPasswordRecoveryCancelApiRequest {
    bankIdOrderRef?: string | null;
    /** @format int32 */
    memberid: number;
}

export interface ChangeAliasApiRequest {
    browser?: string | null;
    ipAddress?: string | null;
    location?: string | null;
    newAlias?: string | null;
    operatingSystem?: string | null;
}

export interface ChangeAliasApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface ChangeEmailWithIdApiRequest {
    bankIdOrderRef?: string | null;
    email?: string | null;
    /** @format int32 */
    memberId: number;
    userIpAddress?: string | null;
}

export interface ChangeEmailWithIdApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface ChangePasswordApiRequest {
    password?: string | null;
}

export interface ChangePasswordApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface ChangePasswordWithIdApiRequest {
    /** @format int32 */
    memberId: number;
    password?: string | null;
}

export interface CreateTaxIdentificationImageUploadUrlApiRequest {
    contentType?: string | null;
}

export interface CreateTaxIdentificationImageUploadUrlApiResponse {
    objectName?: string | null;
    responseStatus?: ResponseStatus | null;
    uploadUrl?: string | null;
}

export type CreateVerificationCodeApiRequest = object;

export interface CreateVerificationCodeApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface CreateVerificationCodeWithMemberIdApiRequest {
    /** @format int32 */
    memberId: number;
    recoveryFlowType: RecoveryFlowType;
}

export interface CreateVerificationCodeWithMemberIdApiResponse {
    responseStatus?: ResponseStatus | null;
}

export type DeactivateMemberAccountPermanentlyApiRequest = object;

export interface DeactivateMemberAccountPermanentlyApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface DeactivateMemberAccountTemporarilyApiRequest {
    comment?: string | null;
}

export interface DeactivateMemberAccountTemporarilyApiResponse {
    responseStatus?: ResponseStatus | null;
}

export type GenerateMemberReferralCodeApiRequest = object;

export interface GenerateMemberReferralCodeApiResponse {
    /** @format double */
    amount: number;
    referralCode?: string | null;
    responseStatus?: ResponseStatus | null;
}

export type GetAccountDeactivationSettingsApiRequest = object;

export interface GetAccountDeactivationSettingsApiResponse {
    isAccountDeactivationAllowed: boolean;
    responseStatus?: ResponseStatus | null;
}

export type GetBraintreeOnboardingDetailsApiRequest = object;

export interface GetBraintreeOnboardingDetailsApiResponse {
    isAccountDetailsSupplied: boolean;
    maskedBankAccount?: string | null;
    responseStatus?: ResponseStatus | null;
}

export type GetHomepageThemeSettingApiRequest = object;

export interface GetHomepageThemeSettingApiResponse {
    isChangeAllowed: boolean;
    responseStatus?: ResponseStatus | null;
    theme: HomepageTheme;
}

export type GetMagicalSyiEnabledApiRequest = object;

export interface GetMagicalSyiEnabledApiResponse {
    enabled: boolean;
    isChangeAllowed: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface GetMemberBankIdVerificationApiResponse {
    bankIdFormattedPersonalNumber?: string | null;
    /** @format date-time */
    lastVerifiedDate: string;
    /** @format date-time */
    manualIdentityVerificationValidTo: string;
    needInitialVerification: boolean;
    needsVerification: boolean;
    responseStatus?: ResponseStatus | null;
    supportsVerification: boolean;
    verificationReason: VerificationReason;
}

export type GetMemberCompanyDac7StatusApiRequest = object;

export interface GetMemberCompanyDac7StatusApiResponse {
    isUnderDac7Requirements: boolean;
    hasRegisteredTaxIdentificationNumber: boolean;
    hasRegisteredVatNumber: boolean;
    responseStatus?: ResponseStatus | null;
    shouldProvideTaxBasis: boolean;
    /** @deprecated */
    shouldProvideVatNumber: boolean;
}

export type GetMemberCompanyMoneyTransferLicenseStatusApiRequest = object;

export interface GetMemberCompanyMoneyTransferLicenseStatusApiResponse {
    hasApprovedMoneyTransferLicenseAgreement: boolean;
    hasCompanyContactSsn: boolean;
    /** @deprecated */
    isCompanyContactSignatory: boolean;
    isEligibleToSignMoneyTransferLicenseAgreement: boolean;
    /** @deprecated */
    isUnderLicense: boolean;
    moneyTransferLicenseAgreementName?: string | null;
    responseStatus?: ResponseStatus | null;
}

export type GetMemberConsentApiRequest = object;

export interface GetMemberConsentApiResponse {
    hasTraderaPayConsent: boolean;
    isEligibleToSignMoneyTransferLicenseAgreement: boolean;
    /** @deprecated */
    isUnderLicense: boolean;
    needsConsentOfTraderaPayAgreement?: string | null;
    responseStatus?: ResponseStatus | null;
    signatoryVerificationRequired: boolean;
}

export type GetMemberEmailNotificationsSettingsViewModelApiRequest = object;

export interface GetMemberEmailNotificationsSettingsViewModelApiResponse {
    email?: MemberEmailNotificationsSettingsEmailApiDto | null;
    /** @format int32 */
    memberId: number;
    messaging?: MemberEmailNotificationsSettingsMessagingApiDto | null;
    responseStatus?: ResponseStatus | null;
    silentMessaging?: MemberSilentMessagingSettingApiDto | null;
}

export type GetMemberHasAutoShipmentCheckoutApiRequest = object;

export interface GetMemberHasAutoShipmentCheckoutApiResponse {
    autoShipmentCheckoutEnabled: boolean;
    responseStatus?: ResponseStatus | null;
}

export type GetMemberHasTaxIdentificationNumberApiRequest = object;

export interface GetMemberHasTaxIdentificationNumberApiResponse {
    hasTaxIdentificationNumber: boolean;
    responseStatus?: ResponseStatus | null;
}

export type GetMemberLocalizationApiRequest = object;

export interface GetMemberLocalizationApiResponse {
    languageCodeIso2?: string | null;
    automaticTranslationPreferred: boolean;
    currencyCode?: string | null;
    responseStatus?: ResponseStatus | null;
}

export type GetMemberProfileSettingsViewModelApiRequest = object;

export interface GetMemberProfileSettingsViewModelApiResponse {
    accountInformation?: MemberProfileSettingsAccountInformationApiDto | null;
    /** @format int32 */
    memberId: number;
    personalData?: MemberProfileSettingsPersonalDataApiDto | null;
    presentation?: MemberProfileSettingsPresentationApiDto | null;
    responseStatus?: ResponseStatus | null;
}

export type GetMemberSecuritySettingsViewModelApiRequest = object;

export interface GetMemberSecuritySettingsViewModelApiResponse {
    accountDeactivation?: MemberSecuritySettingsAccountDeactivationApiDto | null;
    memberBlocking?: MemberSecuritySettingsMemberBlockingApiDto | null;
    /** @format int32 */
    memberId: number;
    responseStatus?: ResponseStatus | null;
}

export type GetMemberSellerSettingsViewModelApiRequest = object;

export interface GetMemberSellerSettingsViewModelApiResponse {
    autoFeedback?: MemberSellerSettingsAutoFeedbackApiDto | null;
    autoShipmentCheckout?: MemberSellerSettingsAutoShipmentCheckoutApiDto | null;
    combinedShipping?: MemberSellerSettingsCombinedShippingApiDto | null;
    commitOnPayment?: MemberSellerSettingsCommitOnPaymentApiDto | null;
    itemLocalization?: MemberSellerSettingsItemLocalizationApiDto | null;
    magicalSyi?: MemberSellerSettingsMagicalSyiApiDto | null;
    makeAnOffer?: MemberSellerSettingsMakeAnOfferApiDto | null;
    /** @format int32 */
    memberId: number;
    responseStatus?: ResponseStatus | null;
    shippingFeeRule?: MemberSellerSettingsShippingFeeRuleApiDto | null;
}

export type GetMemberShouldCollectTaxIdentificationNumberApiRequest = object;

export interface GetMemberShouldCollectTaxIdentificationNumberApiResponse {
    responseStatus?: ResponseStatus | null;
    shouldCollectBirthDate: boolean;
    shouldCollectTaxIdentificationNumber: boolean;
}

export type GetMemberVerificationStatusApiRequest = object;

export interface GetMemberVerificationStatusApiResponse {
    hasAnyActiveTraderaPayConsent: boolean;
    hasCompanyContactSsn: boolean;
    hasSsn: boolean;
    isEligibleToSignMoneyTransferLicenseAgreement: boolean;
    /** @deprecated */
    isUnderLicense: boolean;
    needsBankIdVerification: boolean;
    needsConsentOfTraderaPayAgreement?: string | null;
    responseStatus?: ResponseStatus | null;
}

export interface GetMoneyTransferLicenseAgreementApiResponse {
    agreementName?: string | null;
    /** @format date-time */
    inEffectAsOf: string;
    responseStatus?: ResponseStatus | null;
}

export type GetPayoutFrequencyApiRequest = object;

export type GetPayoutFrequencyOptionsApiRequest = object;

export type GetShippingInvoiceMemberApiRequest = object;

export interface GetShippingInvoiceMemberApiResponse {
    enabled: boolean;
    responseStatus?: ResponseStatus | null;
}

export type GetTraderaPayOnboardingDetailsApiRequest = object;

export interface GetTraderaPayOnboardingDetailsApiResponse {
    isBankAccountDetailsSupplied: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface GetTranslatedMemberFeedbackApiResponse {
    isTranslationOnDemandDisabled: boolean;
    items?: TranslatedFeedbackItemApiDto[] | null;
    providerName?: string | null;
    responseStatus?: ResponseStatus | null;
}

export interface HeroImageUrlApiDto {
    max?: string | null;
    med?: string | null;
    min?: string | null;
}

export interface MemberEmailNotificationsSettingsEmailApiDto {
    acceptsNewsLetter: boolean;
    acceptsOfferEmails: boolean;
    acceptsUnknownDeviceOrIpEmails: boolean;
    acceptsUpiInfoEmails: boolean;
    notifyBeforeWishListItemEnds: boolean;
    notifyOnFirstWinningBid: boolean;
    notifyWhenItemBecomesUnsold: boolean;
}

export interface MemberEmailNotificationsSettingsMessagingApiDto {
    emailNotifyAll: boolean;
    isAllowedToSetParticipation: boolean;
    participates: boolean;
}

export type MemberHasCompanyContactSsnApiRequest = object;

export interface MemberHasCompanyContactSsnApiResponse {
    hasCompanyContactSsn: boolean;
    responseStatus?: ResponseStatus | null;
}

export type MemberHasSsnApiRequest = object;

export interface MemberHasSsnApiResponse {
    hasSsn: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface MemberProfileSettingsAccountInformationApiDto {
    alias?: string | null;
    emailAddress?: string | null;
}

export interface MemberProfileSettingsPersonalDataApiDto {
    countryCodeIso2?: string | null;
    city?: string | null;
    country?: string | null;
    firstName?: string | null;
    isNameChangeAllowed: boolean;
    lastName?: string | null;
    phoneNumber?: string | null;
    phoneNumberUnvalidated?: string | null;
    streetName?: string | null;
    zipCode?: string | null;
}

export interface MemberProfileSettingsPresentationApiDto {
    heroImageUrls?: HeroImageUrlApiDto | null;
    information?: string | null;
}

export interface MemberSecuritySettingsAccountDeactivationApiDto {
    isAccountDeactivationAllowed: boolean;
}

export interface MemberSecuritySettingsBlockedMemberApiDto {
    alias?: string | null;
    /** @format int32 */
    memberId: number;
}

export interface MemberSecuritySettingsMemberBlockingApiDto {
    blockedMembers?: MemberSecuritySettingsBlockedMemberApiDto[] | null;
}

export interface MemberSellerSettingsAutoFeedbackApiDto {
    enabled: boolean;
    message?: string | null;
    type: SellerAutoFeedbackType;
}

export interface MemberSellerSettingsAutoShipmentCheckoutApiDto {
    enabled: boolean;
    isChangeAllowed: boolean;
}

export interface MemberSellerSettingsCombinedShippingApiDto {
    /** @format int32 */
    days: number;
    enabled: boolean;
    isChangeAllowed: boolean;
}

export interface MemberSellerSettingsCommitOnPaymentApiDto {
    enabled: boolean;
    isChangeAllowed: boolean;
}

export interface MemberSellerSettingsItemLocalizationApiDto {
    selectedDescriptionLanguageCodeIso2?: string | null;
    shippingFromCountryCodeIso2?: string | null;
}

export interface MemberSellerSettingsMagicalSyiApiDto {
    enabled: boolean;
    isChangeAllowed: boolean;
}

export interface MemberSellerSettingsMakeAnOfferApiDto {
    enabled: boolean;
    isChangeAllowed: boolean;
}

export interface MemberSellerSettingsShippingFeeRuleApiDto {
    /** @format int32 */
    freeShippingCostLimit: number;
    shippingOption?: string | null;
    useFreeShippingCostLimit: boolean;
}

export interface MemberSilentMessagingSettingApiDto {
    enabled: boolean;
    isChangeAllowed: boolean;
}

export interface PayoutFrequencyApiResponse {
    payoutFrequencyOption?: string | null;
    responseStatus?: ResponseStatus | null;
}

export interface PayoutFrequencyOptionsApiResponse {
    options?: string[] | null;
    responseStatus?: ResponseStatus | null;
}

export interface ReactivateMemberAccountApiRequest {
    bankIdOrderRef?: string | null;
    /** @format int32 */
    memberId: number;
}

export interface ReactivateMemberAccountApiResponse {
    responseStatus?: ResponseStatus | null;
}

export enum RecoveryFlowType {
    Email = 'Email',
    Sms = 'Sms'
}

export interface RemoveMemberPublicProfileHeroImageApiRequest {
    userIpAddress?: string | null;
}

export interface RemoveMemberPublicProfileHeroImageApiResponse {
    responseStatus?: ResponseStatus | null;
}

export type RemoveTraderaPayPayoutDestinationApiRequest = object;

export interface RemoveTraderaPayPayoutDestinationApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SaveSellerAutoFeedbackSettingsApiRequest {
    criteria: SellerAutoFeedbackCriteria;
    message?: string | null;
}

export interface SaveSellerAutoFeedbackSettingsApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SaveSellerAutoPaymentReminderSettingsApiRequest {
    enabled: boolean;
}

export interface SaveSellerAutoPaymentReminderSettingsApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SaveWinnerEmailApiRequest {
    winnerEmail?: string | null;
}

export interface SaveWinnerEmailApiResponse {
    responseStatus?: ResponseStatus | null;
}

export enum SellerAutoFeedbackType {
    Manual = 'Manual',
    PositiveFeedback = 'PositiveFeedback',
    Paid = 'Paid'
}

export interface SendVerificationSmsApiRequest {
    cellphoneNumber?: string | null;
    countryCode?: string | null;
}

export interface SendVerificationSmsApiResponse {
    errorMessage?: string | null;
    responseStatus?: ResponseStatus | null;
    sentToCountryCode?: string | null;
    sentToTelephoneNumber?: string | null;
    smsSid?: string | null;
    successful: boolean;
}

export interface SendVerificationSmsWithIdApiRequest {
    cellphoneNumber?: string | null;
    countryCode?: string | null;
    /** @format int32 */
    memberId: number;
    recoveryFlowType: RecoveryFlowType;
}

export interface SetAutoShipmentCheckoutMemberEnabledApiRequest {
    enabled: boolean;
}

export interface SetAutoShipmentCheckoutMemberEnabledApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetCombinedShippingMemberDaysApiRequest {
    /** @format int32 */
    days: number;
}

export interface SetCombinedShippingMemberDaysApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetCombinedShippingMemberEnabledApiRequest {
    enabled: boolean;
}

export interface SetCombinedShippingMemberEnabledApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetCommitOnPaymentMemberEnabledApiRequest {
    enabled: boolean;
}

export interface SetCommitOnPaymentMemberEnabledApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetMagicalSyiEnabledApiRequest {
    enabled: boolean;
}

export interface SetMagicalSyiEnabledApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetMakeAnOfferMemberEnabledApiRequest {
    enabled: boolean;
}

export interface SetMakeAnOfferMemberEnabledApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetMemberConsentApiRequest {
    agreementName?: string | null;
}

export interface SetMemberConsentApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetPayoutFrequencyApiRequest {
    payoutFrequencyOption?: string | null;
}

export interface SetPayoutFrequencyApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetShippingFeeRuleFreeShippingCostLimitApiRequest {
    /** @format int32 */
    freeShippingCostLimit: number;
    useFreeShippingCostLimit: boolean;
}

export interface SetShippingFeeRuleFreeShippingCostLimitApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetShippingFeeRuleShippingOptionApiRequest {
    shippingOption?: string | null;
}

export interface SetShippingFeeRuleShippingOptionApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SmsCheckApiRequest {
    smsSid?: string | null;
}

export interface SmsCheckApiResponse {
    responseStatus?: ResponseStatus | null;
    successful: boolean;
}

export interface SmsVerificationDTO {
    action?: string | null;
    cellphoneNumber?: string | null;
    /** @format date-time */
    timestamp: string;
}

export interface SmsVerificationsApiResponse {
    responseStatus?: ResponseStatus | null;
    verifications?: SmsVerificationDTO[] | null;
}

export interface TaxIdentificationNumberImage {
    filename?: string | null;
    objectName?: string | null;
}

export interface TinkBankAccountDto {
    accountNumber?: string | null;
    icon?: string | null;
    name?: string | null;
}

export interface TinkGetBankAccountsApiResponse {
    accounts?: TinkBankAccountDto[] | null;
    responseStatus?: ResponseStatus | null;
}

export interface UpdateHomepageThemeApiRequest {
    theme: HomepageTheme;
}

export interface UpdateHomepageThemeApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberCompanyContactSsnApiRequest {
    companyContactSsn?: string | null;
}

export interface UpdateMemberCompanyContactSsnApiResponse {
    isValid: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberContactDetailsApiRequest {
    alias?: string | null;
    country?: string | null;
    email?: string | null;
    firstName?: string | null;
    fullName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    streetName?: string | null;
    town?: string | null;
    userIpAddress?: string | null;
    zipCode?: string | null;
}

export interface UpdateMemberContactDetailsApiResponse {
    creditSafeVerificationResult?: CreditSafeNameVerificationResult | null;
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberCurrencyApiRequest {
    currencyCode?: string | null;
}

export interface UpdateMemberCurrencyApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberEmailSettingsApiRequest {
    acceptsNewsLetter: boolean;
    acceptsOfferEmails: boolean;
    acceptsUnknownDeviceOrIpEmails?: boolean | null;
    acceptsUpiInfoEmails: boolean;
    notifyBeforeWishListItemEnds: boolean;
    notifyOnFirstWinningBid: boolean;
    notifyWhenItemBecomesUnsold: boolean;
}

export interface UpdateMemberEmailSettingsApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberLanguageApiRequest {
    languageCodeIso2?: string | null;
    userIpAddress?: string | null;
}

export interface UpdateMemberLanguageApiResponse {
    isValid: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberPublicProfileHeroImageApiRequest {
    heroImageUrl?: string | null;
    userIpAddress?: string | null;
}

export interface UpdateMemberPublicProfileHeroImageApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberPublicProfileInformationApiRequest {
    information?: string | null;
    userIpAddress?: string | null;
}

export interface UpdateMemberPublicProfileInformationApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberSelectedDescriptionLanguageApiRequest {
    languageCodeIso2?: string | null;
}

export interface UpdateMemberSelectedDescriptionLanguageApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberShippingFromCountryApiRequest {
    countryCodeIso2?: string | null;
}

export interface UpdateMemberShippingFromCountryApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberSsnApiRequest {
    ssn?: string | null;
}

export interface UpdateMemberSsnApiResponse {
    isValid: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberTaxIdentificationNumberApiRequest {
    birthDate?: string | null;
    images?: TaxIdentificationNumberImage[] | null;
    taxIdentificationNumber?: string | null;
}

export interface UpdateMemberTaxIdentificationNumberApiResponse {
    isValid: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberTranslationPreferredApiRequest {
    isAutomaticTranslationPreferred: boolean;
}

export interface UpdateMemberTranslationPreferredApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface UpdateMemberVatNumberApiRequest {
    vatNumber?: string | null;
}

export interface UpdateMemberVatNumberApiResponse {
    isValid: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface ValidateExistingPasswordForMemberApiRequest {
    existingPassword?: string | null;
}

export interface ValidateExistingPasswordForMemberApiResponse {
    isValid: boolean;
    responseStatus?: ResponseStatus | null;
}

export enum VerificationReason {
    VerificationNotNeeded = 'VerificationNotNeeded',
    NeedInitialVerification = 'NeedInitialVerification',
    NeedsVerificationFixedPriceLimitTwoMonthValidation = 'NeedsVerificationFixedPriceLimitTwoMonthValidation',
    NeedsVerificationFixedPriceLimitExpensive = 'NeedsVerificationFixedPriceLimitExpensive'
}

export interface VerifyNonMobilePincodeApiRequest {
    pincode?: string | null;
}

export interface VerifyNonMobilePincodeWithIdApiRequest {
    /** @format int32 */
    memberId: number;
    pincode?: string | null;
    recoveryFlowType: RecoveryFlowType;
}

export interface VerifyPincodeApiRequest {
    pincode?: string | null;
}

export interface VerifyPincodeApiResponse {
    hasActiveVerificationCode: boolean;
    responseStatus?: ResponseStatus | null;
    success: boolean;
    /** @format int32 */
    verificationAttempts: number;
}

export interface VerifyPincodeWithIdApiRequest {
    /** @format int32 */
    memberId: number;
    pincode?: string | null;
    recoveryFlowType: RecoveryFlowType;
}

export interface PublicProfileApiResponse {
    selectedDescriptionLanguageCodeIso2?: string | null;
    shippingFromCountryCodeIso2?: string | null;
    accountState: MemberAccountState;
    alias?: string | null;
    city?: string | null;
    /** @format int32 */
    combinedShippingDays: number;
    /** @format float */
    detailedSellerRatingAverage?: number | null;
    firstName?: string | null;
    hasShop: boolean;
    heroImage?: string | null;
    information?: string | null;
    isCompanyUser: boolean;
    isSwedishUser: boolean;
    /** @format int32 */
    memberId: number;
    /** @format date-time */
    memberSince: string;
    responseStatus?: ResponseStatus | null;
    shopLogoUrl?: string | null;
    /** @format int32 */
    totalRating: number;
    translatedInformation?: string | null;
}

export interface Row {
    /** @format date-time */
    createdDate: string;
    key?: string | null;
    /** @format date-time */
    updatedDate: string;
    value?: string | null;
}

export interface SaveFeedbackAsBuyerApiRequest {
    /** @format int32 */
    commResponsiveness?: number | null;
    comment?: string | null;
    isPositive: boolean;
    /** @format int32 */
    itemAsDescribed?: number | null;
    /**
     * @deprecated
     * @format int32
     */
    itemId: number;
    reportInr: boolean;
    /**
     * @deprecated
     * @format int32
     */
    sellerMemberId: number;
    /** @format int32 */
    shippingHandlingCharges?: number | null;
    /** @format int32 */
    shippingTime?: number | null;
    /** @format int32 */
    transactionId: number;
}

export interface SaveFeedbackAsBuyerApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SaveFeedbackAsSellerApiRequest {
    /**
     * @deprecated
     * @format int32
     */
    buyerMemberId: number;
    comment?: string | null;
    isPositive: boolean;
    /**
     * @deprecated
     * @format int32
     */
    itemId: number;
    /** @format int32 */
    paymentTime?: number | null;
    /** @format int32 */
    transactionId: number;
}

export interface SaveFeedbackAsSellerApiResponse {
    responseStatus?: ResponseStatus | null;
}

export type SellerAutoFeedbackApiRequest = object;

export interface SellerAutoFeedbackApiResponse {
    /** @format date-time */
    created: string;
    criteria: SellerAutoFeedbackCriteria;
    message?: string | null;
    responseStatus?: ResponseStatus | null;
}

export type SellerAutoPaymentReminderApiRequest = object;

export interface SellerAutoPaymentReminderApiResponse {
    enabled: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface SellerSetItemVisibilityApiRequest {
    hidden: boolean;
    itemIds?: number[] | null;
}

export interface SellerSetItemVisibilityApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface CampaignCodeItem {
    auctionId?: number[] | null;
    campaignCode?: string | null;
    /** @format int32 */
    campaignCodeId: number;
}

export interface CommissionFeeSummaryDto {
    /** @format double */
    maxFee: number;
    /** @format double */
    maxPercentage: number;
    /** @format double */
    minFee: number;
    /** @format double */
    minPercentage: number;
}

export interface FeatureSummaryByIdsApiResponse {
    campaignCodes?: CampaignCodeItem[] | null;
    featureSummaryList?: FeatureSummaryApiDto[] | null;
    isCompanyUser: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface FeatureSummaryDto {
    /** @format int32 */
    count: number;
    name?: string | null;
    /** @format int32 */
    price: number;
}

export interface ItemApiDto {
    /** @format int32 */
    id: number;
    mainImageUrl?: ItemImageUrls | null;
    mainImageUrlTemplate?: string | null;
    /** @format int32 */
    price: number;
    /** @deprecated */
    selectedShipping?: SelectedShipping | null;
    shortDescription?: string | null;
}

export interface MarkAsPaidBySellerApiRequest {
    paidConfirmed: boolean;
    /** @format uuid */
    purchaseOrderId: string;
}

export interface MarkAsPaidBySellerApiResponse {
    responseStatus?: ResponseStatus | null;
}

export type MemberActiveItemsSummaryApiRequest = object;

export interface MemberActiveItemsSummaryApiResponse {
    responseStatus?: ResponseStatus | null;
    summary?: MemberActiveItemsSummaryApiDto | null;
}

export type MemberLatestPublishedListingApiRequest = object;

export interface MemberLatestPublishedListingApiResponse {
    /** @format date-time */
    latestPublishedDate?: string | null;
    responseStatus?: ResponseStatus | null;
}

export type MemberListingFeeApiRequest = object;

export interface MemberListingFeeApiResponse {
    /** @format int32 */
    maxFreeListingsPerMonth: number;
    responseStatus?: ResponseStatus | null;
}

export interface MemberPickListApiResponse {
    /** @format int32 */
    hiddenCount: number;
    items?: PickListBuyerApiDto[] | null;
    responseStatus?: ResponseStatus | null;
    /** @format int64 */
    totalCount: number;
}

export interface MemberPickListV2ApiResponse {
    /** @format int64 */
    filterHitCount: number;
    /** @format int32 */
    hiddenCount: number;
    items?: PickListBuyerV2ApiDto[] | null;
    responseStatus?: ResponseStatus | null;
    /** @format int64 */
    totalCount: number;
}

export interface MemberSoldItemBuyerInfoApiResponse {
    buyerContactDetails?: ContactDetailsApiDto | null;
    buyerProfile?: PublicProfileApiDto | null;
    items?: MemberSoldItemApiDto[] | null;
    paidStatus?: string | null;
    paymentMethod?: string | null;
    paymentOptions?: PaymentOptionApiDto[] | null;
    responseStatus?: ResponseStatus | null;
    sellerContactDetails?: ContactDetailsApiDto | null;
    /** @format double */
    totalOrderPrice: number;
    /** @format double */
    totalOrderPriceWithoutShipping: number;
    /** @format double */
    vatPercent?: number | null;
}

export interface MemberSoldItemByTransactionIdApiResponse {
    item?: MemberSoldItemApiDto | null;
    responseStatus?: ResponseStatus | null;
}

export interface MemberSoldItemShippingInfoV2ApiResponse {
    buyerContactDetails?: ContactDetailsApiDto | null;
    isQuickFlowPossible: boolean;
    /** @deprecated */
    item?: ItemApiDto | null;
    items?: ItemApiDto[] | null;
    responseStatus?: ResponseStatus | null;
    selectedShipping?: SelectedShipping | null;
    /** @format uuid */
    shipmentCheckoutId: string;
}

export interface MemberSoldItemsApiResponse {
    /** @format int32 */
    hiddenCount: number;
    items?: MemberSoldItemApiDto[] | null;
    /** @format int32 */
    notPaidCount: number;
    /** @format int32 */
    paidCount: number;
    responseStatus?: ResponseStatus | null;
    /** @format int32 */
    shippedCount: number;
    /** @format int64 */
    totalCount: number;
}

export interface MemberSoldItemsByPurchaseOrderIdApiResponse {
    items?: MemberSoldItemApiDto[] | null;
    responseStatus?: ResponseStatus | null;
}

export type MemberSoldItemsSummaryApiRequest = object;

export interface MemberSoldItemsSummaryApiResponse {
    responseStatus?: ResponseStatus | null;
    summary?: MemberSoldItemsSummaryApiDto | null;
}

export type MemberStatsThisMonthApiRequest = object;

export interface MemberStatsThisMonthApiResponse {
    /** @format int32 */
    activeThisMonthCount: number;
    /** @format int32 */
    listedThisMonthCount: number;
    /** @format int32 */
    maxFreeListingsPerMonth: number;
    responseStatus?: ResponseStatus | null;
    /** @format int32 */
    soldThisMonthCount: number;
    /** @format int32 */
    unsoldThisMonthCount: number;
}

export interface RestartItemsSummaryApiResponse {
    commission?: CommissionFeeSummaryDto | null;
    features?: FeatureSummaryDto[] | null;
    isCompany: boolean;
    responseStatus?: ResponseStatus | null;
}

export interface SelectedShipping {
    /** @format int32 */
    productId?: number | null;
    providerId?: string | null;
}

export interface SendGiftCardPackMailApiRequest {
    /** @format uuid */
    purchaseOrderId: string;
}

export interface SendGiftCardPackMailApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetItemShippedApiRequest {
    /** @format uuid */
    purchaseOrderId: string;
    shipped: boolean;
}

export interface SetItemShippedApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetItemShippingBookedApiRequest {
    booked: boolean;
    /** @format uuid */
    purchaseOrderId: string;
}

export interface SetItemShippingBookedApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetTransactionItemShippedApiRequest {
    shipped: boolean;
    /** @format int32 */
    transactionId: number;
}

export interface SetTransactionItemShippedApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface ShopItemPurchaseHistoryApiResponse {
    /** @format int32 */
    categoryId: number;
    /** @format date-time */
    endDate: string;
    imageUrl?: string | null;
    imageUrlTemplate?: string | null;
    /** @format int32 */
    itemId: number;
    purchases?: ShopItemPurchase[] | null;
    responseStatus?: ResponseStatus | null;
    shortDescription?: string | null;
    userReference?: string | null;
}

export interface SetBuyerTransactionVisibilityApiRequest {
    hidden: boolean;
    transactionIdList?: number[] | null;
}

export interface SetBuyerTransactionVisibilityApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetItemVisibilityApiRequest {
    hidden: boolean;
    itemIds?: number[] | null;
}

export interface SetItemVisibilityApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SetTransactionVisibilityApiRequest {
    hidden: boolean;
    /** @format uuid */
    purchaseOrderId: string;
}

export interface SetTransactionVisibilityApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface SystemMessage {
    severity: SystemMessageSeverity;
    type: SystemMessageType;
}

export enum SystemMessageSeverity {
    Warning = 'Warning',
    Info = 'Info'
}

export enum SystemMessageType {
    InvoiceOverdue = 'InvoiceOverdue',
    InvoiceUnpaid = 'InvoiceUnpaid',
    PayoutFailed = 'PayoutFailed',
    PayoutReadyBankAccountMissing = 'PayoutReadyBankAccountMissing',
    BankAccountMissing = 'BankAccountMissing',
    HyperwalletPayeeVerificationRequired = 'HyperwalletPayeeVerificationRequired',
    HyperwalletPayeeVerificationUnderReview = 'HyperwalletPayeeVerificationUnderReview',
    PayoutProviderOnboardingFailed = 'PayoutProviderOnboardingFailed',
    KycQuestionnaire = 'KycQuestionnaire',
    PayoutReadyVerificationRequired = 'PayoutReadyVerificationRequired',
    OutstandingTraderaPayLicense = 'OutstandingTraderaPayLicense',
    PayoutReadyOutstandingTraderaPayLicense = 'PayoutReadyOutstandingTraderaPayLicense',
    CollectVatNumber = 'CollectVatNumber',
    CollectTin = 'CollectTin'
}

export interface TrackExecutedSearchApiRequest {
    searchUrlQueryPart?: string | null;
}

export interface TrackExecutedSearchApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface UnblockMemberApiRequest {
    /** @format int32 */
    memberIdToUnblock: number;
}

export interface UnblockMemberApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface NewDisputeItemSelectorPageApiResponse {
    items?: DisputeV2PurchaseOrderItemData[] | null;
    purchaseOrder?: DisputeV2PurchaseOrderData | null;
    responseStatus?: ResponseStatus | null;
}

export interface NewDisputePageApiResponse {
    isOngoingPayoutReview: boolean;
    items?: DisputeV2ItemData[] | null;
    purchaseOrder?: DisputeV2PurchaseOrderData | null;
    responseStatus?: ResponseStatus | null;
}

export interface ProfilePageApiResponse {
    accountState?: string | null;
    alias?: string | null;
    city?: string | null;
    cleanedAlias?: string | null;
    /** @format int32 */
    combinedShippingDays: number;
    /** @format float */
    detailedSellerRatingAverage?: number | null;
    existsInFavouriteSellers: boolean;
    hasShop: boolean;
    heroImageUrl?: string | null;
    inFavouriteSellers: boolean;
    intro?: string | null;
    isCompany: boolean;
    isCompanyUser: boolean;
    /** @format int32 */
    memberId: number;
    /** @format date-time */
    memberSince: string;
    /** @format int32 */
    profileMemberId: number;
    responseStatus?: ResponseStatus | null;
    /** @format int32 */
    totalRating?: number | null;
    translatedIntro?: string | null;
}

export interface WebMemberSoldItemBuyerInfoApiResponse {
    buyerContactDetails?: ContactDetailsApiDto | null;
    buyerProfile?: PublicProfileApiDto | null;
    discounts?: DiscountDto[] | null;
    items?: SoldItemSummaryDto[] | null;
    offeredShippingOptions?: ShippingApiDto[] | null;
    paidStatus?: string | null;
    paymentMethod?: string | null;
    paymentOptions?: PaymentOptionApiDto[] | null;
    responseStatus?: ResponseStatus | null;
    selectedShipping?: ShippingApiDto | null;
    sellerContactDetails?: ContactDetailsApiDto | null;
    /** @format double */
    totalOrderPrice: number;
    /** @format double */
    totalOrderPriceWithoutShipping: number;
    /** @format double */
    vatAmount?: number | null;
    /** @format double */
    vatPercent?: number | null;
}

export interface WebChangeEmailWithIdApiRequest {
    bankIdOrderRef?: string | null;
    email?: string | null;
    /** @format int32 */
    memberId: number;
}

export enum ItemState {
    BidderAuctionRemoved = 'BidderAuctionRemoved',
    BidderBidRemoved = 'BidderBidRemoved',
    BidderEndedWon = 'BidderEndedWon',
    BidderEndedReservedPriceNotReached = 'BidderEndedReservedPriceNotReached',
    BidderEndedNotWon = 'BidderEndedNotWon',
    BidderActiveLeading = 'BidderActiveLeading',
    BidderActiveReservedPriceNotReached = 'BidderActiveReservedPriceNotReached',
    BidderActiveOverbidden = 'BidderActiveOverbidden',
    GeneralActive = 'GeneralActive',
    GeneralEnded = 'GeneralEnded',
    PurchasedNotPaid = 'PurchasedNotPaid',
    PurchasedPaid = 'PurchasedPaid',
    PurchasedDone = 'PurchasedDone',
    ShopItemActive = 'ShopItemActive',
    ShopItemEnded = 'ShopItemEnded',
    ShopItemEndedWon = 'ShopItemEndedWon',
    BuyItNowEndedWon = 'BuyItNowEndedWon',
    BuyItNowEnded = 'BuyItNowEnded',
    BuyItNowActive = 'BuyItNowActive',
    SellerActiveBids = 'SellerActiveBids',
    SellerActiveNoBids = 'SellerActiveNoBids',
    SellerReservedPriceNotReached = 'SellerReservedPriceNotReached',
    SellerSoldNotPaid = 'SellerSoldNotPaid',
    SellerSoldDone = 'SellerSoldDone',
    SellerSoldShipped = 'SellerSoldShipped',
    SellerSoldPaid = 'SellerSoldPaid',
    SellerSoldShippingBooked = 'SellerSoldShippingBooked'
}

export interface WishListApiResponse {
    items: WishListItemCardViewModel[];
    responseStatus?: ResponseStatus | null;
    /** @format int32 */
    totalCount: number;
}

export interface WishListItemCardViewModel {
    /** @format int32 */
    buyNowPrice: number;
    displayHighlighted: boolean;
    /** @format date-time */
    endDate: string;
    hasFreeShipping: boolean;
    imageSecondaryUrl: string;
    imageUrl: string;
    isActive: boolean;
    isAvailableForOffers: boolean;
    isLeading: boolean;
    isOverbidden: boolean;
    isTakeawayOnly: boolean;
    isWinner: boolean;
    /** @format int32 */
    itemId: number;
    itemState: ItemState;
    itemType: string;
    itemUrl: string;
    /** @format int32 */
    maxBidAmount: number;
    preferredShipping: WishListItemShippingOptionViewModel;
    /** @format int32 */
    price: number;
    sellerAlias: string;
    /** @format int32 */
    sellerMemberId: number;
    shortDescription: string;
    /** @format int32 */
    totalBids: number;
}

export interface WishListItemShippingOptionViewModel {
    /** @format double */
    cost: number;
    type: string;
}

export interface AddItemToWishListApiRequest {
    /** @format int32 */
    itemId: number;
}

export interface AddItemToWishListApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface GetWishListApiResponse {
    responseStatus?: ResponseStatus | null;
    /** @format int32 */
    totalCount: number;
    wishListItems?: WishListItemDto[] | null;
}

export type GetWishListItemIdsApiRequest = object;

export interface GetWishListItemIdsApiResponse {
    responseStatus?: ResponseStatus | null;
    wishListItemsIds?: number[] | null;
}

export interface MemberBidInfo {
    auctionRemoved: boolean;
    bidRemoved: boolean;
    /** @format int32 */
    maxBidAmount: number;
    /** @format int32 */
    memberId: number;
    reservePriceReached: boolean;
}

export interface RemoveItemFromWishListApiResponse {
    responseStatus?: ResponseStatus | null;
}

export interface RemoveItemsFromWishListApiRequest {
    itemIds?: number[] | null;
}

export interface WishListItemDto {
    /** @format date-time */
    addedToWishList: string;
    /** @format int32 */
    bidCount: number;
    boughtByCurrentMember: boolean;
    /** @format int32 */
    categoryId: number;
    /** @format int32 */
    currentBidAmount: number;
    displayHighlighted: boolean;
    /** @format date-time */
    endDate: string;
    /** @format int32 */
    fixedPrice: number;
    hasCommitUpFront: boolean;
    hasEnded: boolean;
    hasReservePrice: boolean;
    isAuthenticated: boolean;
    isAvailableForOffers?: boolean | null;
    isBuyItNow: boolean;
    isPaid?: boolean | null;
    isShopItem: boolean;
    /** @format int32 */
    itemId: number;
    /** @format int32 */
    itemPrice?: number | null;
    /** @format int32 */
    leadingBidMemberId?: number | null;
    mainImage?: ItemImageUrls | null;
    mainImageTemplate?: string | null;
    memberBid?: MemberBidInfo | null;
    /** @format int32 */
    nextValidBidAmount?: number | null;
    /** @format int32 */
    openingBid?: number | null;
    preferredShipping?: ShippingApiDto | null;
    reservePriceReached: boolean;
    secondaryImage?: ItemImageUrls | null;
    secondaryImageTemplate?: string | null;
    sellerAlias?: string | null;
    /** @format int32 */
    sellerMemberId: number;
    /** @format int32 */
    sellerTotalRating: number;
    title?: string | null;
    /** @format date-time */
    transactionDate?: string | null;
    /** @format int32 */
    transactionType?: number | null;
    type: ItemTypeApiDto;
}

export interface Address {
    countryCodeIso2?: string | null;
    city?: string | null;
    country?: string | null;
    street?: string | null;
    zipCode?: string | null;
}

export interface ShippingDiscount {
    partnerProvider?: string | null;
    /** @format int32 */
    price: number;
    /** @format int32 */
    shippingProductId?: number | null;
    shippingProvider?: string | null;
}

export enum PickListFilterPaidStatus {
    All = 'All',
    Paid = 'Paid',
    Unpaid = 'Unpaid'
}

export enum PickListFilterShippedStatus {
    All = 'All',
    Shipped = 'Shipped',
    NotShipped = 'NotShipped'
}

export interface ShopItemPurchase {
    buyerMemberAlias?: string | null;
    /** @format int32 */
    buyerMemberId: number;
    /** @format int32 */
    buyerTotalRating: number;
    /** @format int32 */
    categoryId: number;
    /** @format int32 */
    price: number;
    /** @format date-time */
    transactionDate: string;
}

export interface TransactionFeedbackRemovalRequest {
    /** @format int32 */
    transactionId?: number;
}

export interface TransactionItemShippedCreateParams {
    request?: SetTransactionItemShippedApiRequest;
}

export interface TransactionVisibilityCreateParams {
    request?: SetTransactionVisibilityApiRequest;
}

export interface TransactionsRequest {
    transactionIds?: number[];
}

export interface TranslatedRequest {
    toLanguageCodeIso2?: string;
    feedbackIds?: number[];
}

export interface TranslationPreferredUpdateParams {
    request?: UpdateMemberTranslationPreferredApiRequest;
}

export interface UnreadNotificationsCountRequest {
    request?: GetUnreadNotificationsCountApiRequest;
}

export interface UpiStrikeRequest {
    /** @format int32 */
    transactionId?: number;
}

export interface UppoStrikeCreateParams {
    request?: AddUnpaidPurchaseOrderStrikeApiRequest;
}

export interface UppoStrikeRequest {
    /** @format uuid */
    purchaseOrderId?: string;
}

export interface V2EscalateCreateParams {
    request?: EscalateDisputeV2ApiRequest;
}

export interface V2FileUploadUrlCreateParams {
    request?: CreateDisputeFileUploadUrlApiRequest;
}

export interface V2GetDataRequest {
    /** @format int32 */
    disputeId?: number;
}

export interface V2MarkAsSolvedCreateParams {
    request?: MarkDisputeAsSolvedV2ApiRequest;
}

export interface V2SaveDataCreateParams {
    request?: SaveDisputeDataV2ApiRequest;
}

export interface ValidateExistingPasswordCreateParams {
    request?: ValidateExistingPasswordForMemberApiRequest;
}

export interface VatNumberUpdateParams {
    request?: UpdateMemberVatNumberApiRequest;
}

export interface VatdetailsRequest {
    request?: GetMemberVatDetailsApiRequest;
}

export interface VerificationStatusRequest {
    request?: GetMemberVerificationStatusApiRequest;
}

export interface VerifyCreateParams {
    request?: VerifyPayoutTransferMethodApiRequest;
}

export interface VerifyNonmobilePincodeCreateParams {
    request?: VerifyNonMobilePincodeApiRequest;
}

export interface VerifyNonmobilePincodeWithIdCreateParams {
    request?: VerifyNonMobilePincodeWithIdApiRequest;
}

export interface VerifyPincodeCreateParams {
    request?: VerifyPincodeApiRequest;
}

export interface VerifyPincodeWithIdCreateParams {
    request?: VerifyPincodeWithIdApiRequest;
}

export interface WinnerEmailUpdateParams {
    request?: SaveWinnerEmailApiRequest;
}

export interface WishListRequest {
    shippingCountryCodeIso2?: string;
    languageCodeIso2?: string;
    automaticTranslationPreferred?: boolean;
    statusFilter?: string;
    /** @format int32 */
    pageSize?: number;
    /** @format int32 */
    page?: number;
}

export interface WishlistCreateParams {
    request?: AddItemToWishListApiRequest;
}

export interface WishlistDeleteParams {
    request?: RemoveItemsFromWishListApiRequest;
}

export interface WishlistRequest {
    statusFilter?: WishListStatusFilter;
    /** @format int32 */
    take?: number;
    /** @format int32 */
    skip?: number;
    languageCodeIso2?: string;
}

export interface WithAliasRequest {
    alias?: string;
}
