import { webApiClient } from 'tradera/utils/http';

const timeDiffInMs = (start) => {
    return Date.now() - start;
};

const queryServerTime = async (retryCount) => {
    const latencyLimit = 2000;
    const maxRetries = 2;
    const requestStart = Date.now();
    const {
        data: { currentTime }
    } = await webApiClient.get('/discover/server-time');
    const latency = timeDiffInMs(requestStart);

    if (latency < latencyLimit) {
        return { currentTime: Date.parse(currentTime), latency };
    } else if (retryCount < maxRetries) {
        return queryServerTime(retryCount + 1);
    } else {
        return null;
    }
};

/**
 * Requests server time and returns
 * { currentTime, latency }
 */
export const getServerTime = async () => {
    return await queryServerTime(0);
};
