import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export type Currency = {
    code: string;
    rate: number;
    buyerRate: number;
    sellerRate: number;
    exchangeRateId?: number;
    valueDate: string;
    updatedDate: string;
    symbolSuffix: string;
    shortName: string;
    longName: string;
    decimals: number;
    providerRates: CurrencyProviderRates;
};

export type CurrencyProviderRates = {
    sellRate: number;
    buyRate: number;
    midRate: number;
};

export enum CurrencyRateType {
    Buyer = 'buyer',
    Seller = 'seller'
}

export const defaultCurrency: Currency = {
    code: 'SEK',
    rate: 1,
    buyerRate: 1,
    sellerRate: 1,
    exchangeRateId: undefined,
    valueDate: '0000-01-01',
    updatedDate: '0000-01-01T00:00:00.0000000Z',
    symbolSuffix: 'kr',
    shortName: 'SEK',
    longName: 'Svensk krona',
    decimals: 0,
    providerRates: {
        sellRate: 1,
        buyRate: 1,
        midRate: 1
    }
};

type MultiCurrencyState = {
    enabled: boolean;
    currencies: Currency[];
    preferredCurrency: Currency | null;
};

const initialState: MultiCurrencyState = {
    enabled: false,
    currencies: [],
    preferredCurrency: null
};

const resolveCurrency = (
    enabled: boolean,
    currencyCode: string | undefined,
    currencies: Currency[]
) => {
    if (!enabled || !currencyCode) {
        return null;
    }
    return (
        currencies.find((currency) => currency.code === currencyCode) || null
    );
};

const multiCurrencySlice = createSlice({
    name: 'multiCurrency',
    initialState,
    reducers: {
        initialize: (
            state,
            action: PayloadAction<{
                enabled: boolean;
                preferredCurrency: string;
                currencies?: Currency[];
            }>
        ) => {
            const {
                currencies = [],
                enabled,
                preferredCurrency
            } = action.payload;

            return {
                ...state,
                enabled,
                currencies,
                preferredCurrency: resolveCurrency(
                    enabled,
                    preferredCurrency,
                    currencies
                )
            };
        },
        setPreferredCurrency: (
            state,
            { payload }: PayloadAction<{ currencyCode: string }>
        ) => {
            const { enabled, currencies } = state;

            return {
                ...state,
                preferredCurrency: resolveCurrency(
                    enabled,
                    payload.currencyCode,
                    currencies
                )
            };
        }
    }
});

export const { initialize, setPreferredCurrency } = multiCurrencySlice.actions;
export default multiCurrencySlice.reducer;
