export const isSwedenCountryCodeOrUndefined = (countryCodeIso2) =>
    countryCodeIso2 === undefined ||
    countryCodeIso2 === null ||
    countryCodeIso2.toLowerCase() === 'se';

export const isSwedenCountryNameOrUndefined = (countryName) =>
    countryName === undefined ||
    countryName === null ||
    countryName.toLowerCase() === 'sweden';

export const isDenmarkCountryCode = (countryCodeIso2) =>
    !isSwedenCountryCodeOrUndefined(countryCodeIso2) &&
    countryCodeIso2.toLowerCase() === 'dk';

export const isNetherlandsCountryCode = (countryCodeIso2) =>
    !isSwedenCountryCodeOrUndefined(countryCodeIso2) &&
    countryCodeIso2.toLowerCase() === 'nl';

export const areCountryCodesIso2Equal = (first, second) =>
    (first || 'SE').toUpperCase() === (second || 'SE').toUpperCase();
